import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { MartReportService } from "../../services/MartReportService";

const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};
const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: "flex",
    width: `100%`,
    justifyContent: "center",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  marginTop: {
    marginTop: "20px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const MartCategorySaleGraph = () => {
  const [selectedDate, setSelectedDate] = React.useState(date);
  const classes = useStyles();
  const [month, setMonth] = useState(currentDate);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [categorySalesSeries, setCategorySalesSeries] = useState([]);
  const [categorySalesOptions, setCategorySalesOptions] = useState({
    chart: {
      width: 380,
      height: 350,
      type: "pie",
    },
    noData: {
      text: "No reports available",
      style: {
        color: theme.palette.secondary.main,
        fontSize: "20px",
      },
    },
    title: {
      text: `Category Sales in ${moment(new Date()).format("MMMM YYYY")}`,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    MartReportService.getMartsMonthlySalesInCategoryByYear(
      month.id,
      month.year
    ).then((res) => {
      setCategorySalesSeries(
        res.response.map((report) => parseInt(report.totalItemSale))
      );
      setCategorySalesOptions({
        ...categorySalesOptions,
        labels: res.response.map(
          (report) => `${report.title} (${report.totalItemSale})`
        ),
      });
    });
  }, [month]);

  useEffect(() => {
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  return (
    <Card>
      <CardHeader
        action={
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <DatePicker
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Year and Month"
                helperText="Start from month selection"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        }
      />
      <CardContent>
        <Chart
          options={categorySalesOptions}
          series={categorySalesSeries}
          type="pie"
          height={400}
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default MartCategorySaleGraph;
