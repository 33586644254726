import { Badge, Card, CardActions, CardContent, CardHeader, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
import { proxyPath } from '../../../services/ApiPath';
import njPlaceholderImage from './../../../nj_placeholder.png'

const useStyle = makeStyles(theme => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
      priceBg :{
            marginTop : 5,
            backgroundColor :theme.palette.success.main,
            padding : 5,
            borderRadius : '5px'
      },
      price: {
        marginTop :'5px',
        background : theme.palette.secondary.main,
        borderRadius : 5,
        color: '#fff',
        padding : '4px 10px 4px 10px'
    }
}))

const Drug = ({drug , onRemove, ...rest}) => {
    const classes = useStyle()
    
    return <Card className={classes.root}>
    <CardHeader
        action={
        <IconButton aria-label="cancel" onClick={() => onRemove(drug)}>
            <CancelIcon color="primary"/>
        </IconButton>
        }
        title={`${drug?.name} ${drug?.DrugType?.title} ${drug?.measurement}`}
    />
    {/* <CardMedia
        className={classes.media}
        image={`${proxyPath}${drug?.images[0]?.path}`}
        title="Paella dish"
    /> */}
    <CardContent>
    <Badge color="secondary" badgeContent={`${Math.round(drug.discount)}%`} invisible={drug.discount > 0 ? false : true} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}>
        <img width="50" height="60" src={drug.images!==null && drug.images.length> 0  ? `${proxyPath}${drug.images[0]?.path}` : njPlaceholderImage  }/>
        </Badge>
        <Typography variant="body2" color="textSecondary" component="p">
            {drug?.description}
        </Typography>
    
       
    </CardContent>
    <CardActions>
        <Grid container>
            <Grid item xs={12}>
            <span className={classes.price}>Rs {drug.DrugSales[0].price} / {drug.DrugSales[0].title}   </span>
            
            </Grid>
        </Grid>
    </CardActions>
    </Card>
}

export default Drug
