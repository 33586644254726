import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Card, CardHeader, CardContent, TextField, CardActionArea, Button } from '@material-ui/core';
import { Auth } from '../../auth/Auth';
import MAlert from '../elements/MAlert';
import { LoginService } from '../../services/LoginService';

const StoreFormSchema = yup.object().shape({
	phone: yup.string().required('Phone number is required'),
	storeName: yup.string().required("Store Name can't be empty"),
	address: yup.string().required('Store address is required.'),
	area: yup.string().required('Store area is required.'),
	city: yup.string().required('City of store is required'),
	latitude: yup.number().required('Store latitude point is required'),
	longitude: yup.number().required('Store longitude point is required')
});

const StoreInfo = () => {
    let user =JSON.parse(localStorage.getItem('store'))
    if(user===null){
        console.log('userFrom store')
        user=Auth.User();
    }

    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(StoreFormSchema),
		defaultValues: {
			phone: user.phone,
			storeName: user.storeName,
			address: user.address,
			area: user.area,
			city: user.city,
			latitude: user.latitude,
			longitude: user.longitude
		}
    });
    
    const onSubmit = (data) => {
        data.id = user.id
        LoginService.edit(data).then(res=> {
            localStorage.setItem("store" , JSON.stringify(data))
            setAlert({
                show:true,
                message: res.message,
                type:"success"
            })
        }).catch(error=> {
            setAlert({
                show:true,
                message: "Can't Edit store info try again.",
                type:"error"
            })
        })
        
    }
	return (
		<form  onSubmit={handleSubmit(onSubmit)} noValidate>
			<Card>
                <MAlert alert={alert} setAlert={setAlert}/>
				<CardHeader title="Store Info" />
				<CardContent>
                    <TextField 
                    inputRef={register}
                    error={errors.phone ? true : false}
                    helperText={errors.phone?.message}
                    required fullWidth variant="outlined" label="Phone" name="phone" />
					<TextField
                    inputRef={register}
                    error={errors.storeName ? true : false}
                    helperText={errors.storeName?.message}
						required
						fullWidth
						variant="outlined"
						label="Store Name"
						name="storeName"
						margin="normal"
					/>
					<TextField
                    inputRef={register}
                    error={errors.address ? true : false}
                    helperText={errors.address?.message}
						margin="normal"
						required
						fullWidth
						variant="outlined"
						label="Address"
						name="address"
						multiline
						rows={3}
					/>
                    <TextField
                    inputRef={register}
                    error={errors.area ? true : false}
                    helperText={errors.area?.message}
                    required fullWidth variant="outlined" label="Area" name="area" margin="normal" />
                    <TextField 
                    inputRef={register}
                    error={errors.city ? true : false}
                    helperText={errors.city?.message}
                    required fullWidth variant="outlined" label="City" name="city" margin="normal" />
                    <TextField 
                    inputRef={register}
                    error={errors.latitude ? true : false}
                    helperText={errors.latitude?.message}
                    required fullWidth variant="outlined" label="Latitude" name="latitude" margin="normal" />
					<TextField
                    inputRef={register}
                    error={errors.longitude ? true : false}
                    helperText={errors.longitude?.message}
						required
						fullWidth
						variant="outlined"
						label="Longitude"
						name="longitude"
						margin="normal"
					/>
					<Button type="submit" variant="contained" color="primary">
						Edit
					</Button>
				</CardContent>
			</Card>
		</form>
	);
};

export default StoreInfo;
