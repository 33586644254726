import React, { useEffect, useState } from "react";
import { DrugOrderService } from "../../../services/DrugOrderService";
import DrugOrdersList from "./DrugOrdersList";
import moment from "moment";

const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};

const AllDrugOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = React.useState(date);
  const [month, setMonth] = useState(currentDate);

  useEffect(() => {
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  useEffect(() => {
    DrugOrderService.findByMonthAndAdminId(month.id, month.year).then((res) => {
      setLoading(false);
      setOrders(res.response);
    });
  }, [month]);
  return (
    <DrugOrdersList
      orders={orders}
      loading={loading}
      isShowMonthlyFilter={true}
      setSelectedDate={setSelectedDate}
      selectedDate={selectedDate}
    />
  );
};

export default AllDrugOrders;
