import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardHeader,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { proxyPath } from "../../../services/ApiPath";
import MAlert from "../../elements/MAlert";
import DrugInvoice from "../order/DrugInvoice";
import DrugOrderItem from "../order/DrugOrderItem";
import Frame from "react-frame-component";
import DrugListItems from "../order/DrugListItems";
import { DrugOrderService } from "../../../services/DrugOrderService";
import { PrescriptionService } from "../../../services/PrescriptionService";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../auth/Auth";
import { Actions } from "../../../reducers/actions";

const useStyle = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
    // 	width: 700
    // }
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    // [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
    // 	marginTop: theme.spacing(6),
    // 	marginBottom: theme.spacing(6),
    // 	padding: theme.spacing(3)
    // }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));
const steps = ["Order Items", "Invoice Detail", "Review order"];
const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);
const PrescriptionDetail = () => {
  const [cancelDialog, setCancelDialog] = useState(false);
  const history = useHistory();
  const classes = useStyle();
  const params = useParams();
  const location = useLocation();
  const [loader, setLoader] = React.useState(false);
  const [prescription, setPrescription] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [orderItems, setOrderItems] = useState([]);
  const [order, setOrder] = useState(null);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });
  const socket = useSelector(socketSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on(
      `CANCEL_PHARMACY_PRESCRIPTION_EVENT_${Auth.User().id}`,
      (data) => {
        setCancelDialog(true);
        dispatch(Actions.removeTodayPrescriptions(data));
      }
    );
  }, []);

  const getTotal = (orderItems) => {
    var totalPrice = 0.0;
    orderItems.map((orderItem) => {
      totalPrice += orderItem.quantity * orderItem.price;
    });
    return totalPrice;
  };

  const handleNext = () => {
    if (activeStep == 1) {
      printIframe("receipt");
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframe.onload = function () {
      var mediaQueryList = iframe.contentWindow.matchMedia("print");
      mediaQueryList.addListener(function (mql) {
        console.log("print event", mql);
      });
    };
    iframeWindow.print();

    return false;
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <DrugOrderItem
              order={order}
              onOrderItemRemoved={onOrderItemRemoved}
              orderItems={orderItems}
            />
            {prescription?.content && (
              <div>
                <p style={{ fontWeight: "bold", color: "red" }}>
                  Note : {prescription?.content}
                </p>
              </div>
            )}
          </div>
        );
      case 1:
        return <DrugInvoice order={order} isCenter={true} />;
      case 2:
        return (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Review Order
            </Typography>
            <Typography variant="subtitle1">
              Order invoice is printed must set order in progress to notify user
              about his order.
            </Typography>
          </React.Fragment>
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onOrderItemAdd = (quantity, saleUnit, drug) => {
    if (order === null) {
      createOrder(quantity, saleUnit, drug);
    } else {
      //let saleType = drug.DrugUnits[0].title
      let orderItem = {
        id: -1,
        price: saleUnit.price,
        discount: drug.discount,
        quantity: quantity,
        DrugId: drug.id,
        Drug: drug,
        saleType: saleUnit.title,
        DrugOrderId: order.id,
      };
      let items = [orderItem, ...order.DrugOrderItems];
      order.DrugOrderItems = items;
      let total = getTotal(order.DrugOrderItems);
      order.total = total;
      order.grandTotal = total;
      order.subTotal = total;
      DrugOrderService.addOrderItem({
        orderItem,
        order,
      })
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
          setOrderItems([res.response, ...orderItems]);
          order.DrugOrderItems = order.DrugOrderItems.map((item) =>
            item.id == -1 ? res.response : item
          );

          setOrder(order);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    }
  };

  const createOrder = (quantity, saleUnit, drug) => {
    let order = {};
    let orderItem = {
      price: saleUnit.price,
      discount: drug.discount,
      quantity: quantity,
      DrugId: drug.id,
      Drug: drug,
      saleType: saleUnit.title,
    };
    order.DrugOrderItems = [];
    let items = [orderItem, ...order.DrugOrderItems];
    order.DrugOrderItems = items;
    let total = getTotal(order.DrugOrderItems);
    order.total = total;
    order.grandTotal = total;
    order.subTotal = total;
    order.name = prescription.name;
    order.phone = prescription.phone;
    order.address = prescription.address;
    order.city = prescription.city;
    order.apartment = prescription.apartment;
    order.latitude = prescription.latitude;
    order.longitude = prescription.longitude;
    order.prescriptionId = prescription.id;
    order.shipping = 0;
    order.tax = 0;
    order.UserId = prescription.UserId;
    order.AdminId = prescription.AdminId;
    order.discount = 0;
    if (order === null) {
      order.orderStatus = "New";
    }
    DrugOrderService.createOrder(order)
      .then((res) => {
        setOrder(res.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onOrderItemRemoved = (orderItem) => {
    let items = order.DrugOrderItems.filter((item) => orderItem.id != item.id);
    order.DrugOrderItems = items;
    let total = getTotal(order.DrugOrderItems);
    order.total = total;
    order.grandTotal = total;
    order.subTotal = total;
    DrugOrderService.removeOrderItemById({
      order: order,
      orderItem: orderItem,
    })
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
        setOrder(order);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };
  useEffect(() => {
    const id = "receipt";
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);

    iframe.onload = function () {
      var mediaQueryList = iframe.contentWindow.matchMedia("print");
      // var beforePrint = function() {
      // };

      var afterPrint = function () {
        console.log("After print");
        setActiveStep(2);
      };
      mediaQueryList.addListener(function (mql) {
        console.log("print event", mql);
      });

      //iframe.contentWindow.onbeforeprint = beforePrint;
      iframe.contentWindow.onafterprint = afterPrint;
    };
  }, []);

  const setOrderInProgress = () => {
    setLoader(true);
    order.orderStatus = "In-Progress";
    prescription.status = "In-Progress";
    DrugOrderService.edit(order)
      .then((res) => {
        PrescriptionService.edit(prescription)
          .then((res) => {
            if (
              prescription.orderStatus === "Delivered" ||
              prescription.orderStatus === "Declined"
            ) {
              dispatch(Actions.removeTodayPrescriptions(prescription));
            } else {
              dispatch(Actions.editTodayPrescriptions(prescription));
            }

            setLoader(false);
            handleNext();
          })
          .catch((error) => {
            setAlert({
              show: false,
              message: "Can't change order status",
              type: "error",
            });
          });
      })
      .catch((error) => {
        setAlert({
          show: false,
          message: "Can't change order status",
          type: "error",
        });
      });
  };
  useEffect(() => {
    console.log("params", params.id);
    PrescriptionService.findById(params.id).then((res) => {
      console.log("Prescription", res.response);
      if (res.response !== null) setPrescription(res.response);
      else {
        setCancelDialog(true);
      }
    });
  }, [params]);

  useEffect(() => {
    DrugOrderService.findByPrescriptionId(params.id).then((res) => {
      setOrder(res.response);
    });
  }, [params]);
  return (
    <main className={classes.layout}>
      {" "}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Paper className={classes.paper} id="non-printable">
            <MAlert alert={alert} setAlert={setAlert} />
            <Typography component="h1" variant="h4" align="center">
              Order Detail
            </Typography>
            <DrugListItems onOrderItemAdd={onOrderItemAdd} id="non-printable" />
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Compelete.
                  </Typography>
                  <Typography variant="subtitle1">{`Order by ${order.User.name} with INVOICE-#${order.id} is in progress now.`}</Typography>
                  <div className={classes.buttons}>
                    <Button
                      onClick={() => history.push("/")}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Ok
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {activeStep === steps.length - 1 ? (
                      loader ? (
                        <CircularProgress className={classes.button} />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={setOrderInProgress}
                          className={classes.button}
                        >
                          {"Set Order In Progress"}
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep == 1 ? "Print" : "Next"}
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card>
            <CardHeader title="Prescription Detail" />
            <CardContent>
              <img
                className={classes.img}
                src={`${proxyPath}${prescription?.image?.path}`}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Frame
        id="receipt"
        css={""}
        style={{ display: "none" }}
        initialContent={
          "<!DOCTYPE html><html><link rel='stylesheet' href='/assets/css/thermal.css' type='text/css'><head></head><body><div id='mountHere'></div></body></html>"
        }
        mountTarget="#mountHere"
      >
        <DrugInvoice order={order} isCenter={false} printedDiv="invoice" />
      </Frame>
      <Dialog
        disableBackdropClick
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancel Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This prescription INVOICE-#${params.id} has been canceled .You can't proceed this prescription go back to dashboard.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelDialog(false);
              history.push("/");
            }}
            color="secondary"
            autoFocus
          >
            Go To Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default PrescriptionDetail;
