import { Card, CardActionArea, CardContent, CardHeader, CircularProgress, IconButton, ListItem, Switch, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { FeatureDrugService } from '../../../services/FeatureDrugService'
import MAlert from '../../elements/MAlert'
import DataTableExtensions from 'react-data-table-component-extensions';
import SortIcon from '@material-ui/icons/Sort';
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useHistory } from 'react-router-dom'

const FeatureDrugList = (props) => {
    const theme= useTheme()
    const history = useHistory()
    const [items , setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const columns = [
        {
            name:"#ID",
            selector:"id",
            sortable:true
            
        },
        {
            name: "Title",
            selector: 'title',
            sortable: true
        },
        {
            name: "Status",
            selector: 'status',
            cell: (item ) => <Switch
                checked={item.status}
                onChange={(e)=> onStatusChange(e,item)}
                name="status"
                inputProps={{ 'aria-label': 'staus checkbox' }}
          />
            
        },{
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (item) => <>
            <IconButton onClick={() => props.setEditItemObj({item:item, isEdit:true})} size="small" component="span">
            <EditIcon />
        </IconButton>
        <IconButton onClick={() => history.push({
            pathname: `/pharmacy/featuredrugs/${item.title}/${item.id}`,
        })} size="small" component="span">
            <PostAddIcon />
        </IconButton>
            </>,
            right: true
        }
    ]

    const onStatusChange = (event , item) => {
        item.status=!item.status
        FeatureDrugService.edit(item).then(res => {
            // if(res.success){
            //     onItemUpdate(res.response)
            // }
            setAlert({
                show:true,
                message:res.message,
                type : res.success ? 'success': 'error'
            })
        }).catch(error => {
            setAlert({
                show:true,
                message:error.message,
                type : 'error'
            })
        })
    }


    useEffect(() => {
        const {item , isNew} =props.itemObj
        if(isNew){
            onItemInsert(item)
        }else{
           onItemUpdate(item)
        }
    }, [props.itemObj])

    const onItemInsert = (item) =>{
        setItems([item, ...items])
    }

    const onItemUpdate = (item) => {
        setItems(items.map(cat => cat.id==item.id ? item : cat))
    }

    useEffect(() => {
        FeatureDrugService.findAll().then(res => {
            setLoading(false)
            setItems(res.response)
        }).catch(error => setLoading(false))
    } , [])

    const tableData= {
        columns : columns,
        data : items
    }



    return <>
         <Card>
            <MAlert alert={alert} setAlert={setAlert}/>
            <CardHeader title="Feature Drugs Categories"/>
                <CardContent>
                    <DataTableExtensions
                    {...tableData} filterPlaceholder="Filter Category"
                    >
                        <DataTable
                            noHeader
                            keyField="id"
                            theme={theme.palette.type}
                            title="Categories"
                            columns={columns}
                            data={items}
                            progressPending={loading}
                            progressComponent={<CircularProgress color="secondary"/>}
                            defaultSortField="title"
                            sortIcon={<SortIcon />}
                            pagination
                            highlightOnHover
                            />
                    </DataTableExtensions>
                   
                </CardContent>
           
        </Card>
    </>
}

export default FeatureDrugList
