import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { memo, useState } from "react";
import MAlert from "../../elements/MAlert";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { ReportsNotificationService } from "../../../services/ReportNotificationService";

const ReportNotificationDialog = ({
  isDialogOpen,
  onCloseDialog,
  userIds = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [alertNotification, setAlertNotification] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [notificationData, setNotificationData] = useState({
    title: "",
    description: "",
    date: new Date(),
    noOfOrders: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotificationData((prevNotificationData) => ({
      ...prevNotificationData,
      [name]: value,
    }));
  };

  const sendNotifications = async () => {
    const { date, title, description } = notificationData;
    if (title === "") {
      showError("Title is required");
    } else if (description === "") {
      showError("Message detail is required");
    } else {
      resetNotificationAlert();
      const formatedDate = moment(date).format("YYYY-MM-d");

      const notification = {
        date: formatedDate,
        no_of_orders: 0,
        type: userIds.length > 0 ? "selected_users" : "by_month_order_count",
        userIds: userIds,
        title: title,
        description: description,
      };

      try {
        setLoading(true);
        const result = await ReportsNotificationService.sendReportNotification(
          notification
        );
        setAlertNotification({
          type: "success",
          show: true,
          message: result.response,
        });
      } catch (err) {
        console.log(err);
        setAlertNotification({
          show: true,
          type: "error",
          mesasge:
            err.message ?? "Can't send notification, something wen wrong.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const resetNotificationAlert = () => {
    setAlertNotification({
      show: false,
      type: null,
      message: null,
    });
  };

  const showError = (message) => {
    setAlertNotification({
      show: true,
      type: "error",
      message,
    });
  };
  return (
    <Dialog
      open={isDialogOpen}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Send Notification To</DialogTitle>

      <DialogContent>
        <MAlert alert={alertNotification} setAlert={setAlertNotification} />
        <DialogContentText id="alert-dialog-description">
          {userIds.length > 0
            ? "Send notification to all the selected users"
            : "Send notification to all users with 0 orders in the selected month"}
        </DialogContentText>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            style={{ width: "100%" }}
            openTo="month"
            views={["year", "month"]}
            label="Year and Month"
            helperText="Select month"
            value={notificationData.date}
            onChange={(date) =>
              setNotificationData({
                ...notificationData,
                date: date,
              })
            }
          />
        </MuiPickersUtilsProvider>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={notificationData.title}
          onChange={(event) => handleInputChange(event)}
          id="title"
          label="Title"
          name="title"
          autoComplete="title"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={notificationData.description}
          onChange={(event) => handleInputChange(event)}
          id="description"
          label="Message Content"
          name="description"
          autoComplete="description"
          autoFocus
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={sendNotifications}
          color="secondary"
          autoFocus
          variant="contained"
          disabled={loading}
        >
          Send Notification
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ReportNotificationDialog);
