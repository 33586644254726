import React, { useState } from 'react';
import { ListItem, ListItemAvatar, ListItemText, Typography, Avatar, ListItemSecondaryAction, Button, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { proxyPath } from '../../services/ApiPath';
const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: '36ch',
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
    },
    span : {
        marginLeft : theme.spacing(2),
        marginRight : theme.spacing(2)
    },
    button : {
        marginLeft : theme.spacing(2)
    },

   

    price: {
        marginTop :'5px',
        background : theme.palette.secondary.main,
        borderRadius : 5,
        color: '#fff',
        padding : '4px 10px 4px 10px'
    }
}));
const ProductItem = ({ product , onOrderItemAdd }) => {
    const classes = useStyles();
    const [quantity , setQuantity ] = useState(1)
    
    const calcItemDiscountPrice = (item) => {
        return item.price * item.discount / 100;
    };
	return (
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
                <Badge color="secondary" badgeContent={`${Math.round(product.discount)}%`}  invisible={product.discount > 0 ? false : true}anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                <img width="50" height="60" src={`${proxyPath}${product?.images[0]?.path}`}/>
                </Badge>
			</ListItemAvatar>
			<ListItemText
				primary={`${product.title} ${product.qtyPerUnit}`}
				secondary={
					<React.Fragment>
						<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                         {product.ProductCategory.title}
						</Typography>
                        <br />
                        <span className={classes.price}>Rs {Math.round(product.price - calcItemDiscountPrice(product))}</span>
                        &nbsp;&nbsp;
                        {product.discount> 0  ? <del> Rs {product.price} </del>: ''}
                        
                        
						
					</React.Fragment>
				}
			/>
            <ListItemSecondaryAction>
                <Button color="primary" variant="outlined" size="small" disabled={quantity==1} onClick={() => setQuantity(quantity-1)}>-</Button>
                <Typography component="span" className={classes.span}>{quantity}</Typography>
                <Button color="primary" variant="outlined" size="small" onClick={() => setQuantity(quantity+1)}>+</Button>
                <Button color="primary" variant="contained" className={classes.button} onClick={() => onOrderItemAdd(quantity,product)}>Add Item</Button>
               
            </ListItemSecondaryAction>
		</ListItem>
	);
};

export default ProductItem;
