import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const send = async (notification) => {
  const response = await axios.post(
    `${Urls.newNotification.send}`,
    notification,
    { headers: headers }
  );
  return response.data;
};

const sendOrderNotification = async (order, module = "mart" | "pharmacy") => {
  const notification = {
    userId: order.UserId,
    // "deviceTokens": ["cv0Pk2rhtES2oRtITt0uyZ:APA91bE42VfRAjctYJ7uoeQR51_ngc2c16Q0lppfaH4cFE4OGzPgzlMQyzWYN-a4E8V_ehnQ8rlMO4X2u-11lQtfYuaAAFtv4NW_7P8lpluXHsDNAXuTK4yhFY5kYQdiNnelKyYwOug1","dlQMdssIQayWG6mdZ3g7rk:APA91bF9wBNvjE-zbSWGNsAYsaaPHj-it7YdsCxJaR6-QtwoaiYt9CSLjQCTwyoeIdOi2YebAzbhVJ7LrHqd77a0ZYVvyFdeKP8Yfvaz_msRAktRrmOF4B4yA-A1-vR9VORL3-nkoc1q"],
    payload: {
      data: {
        navigation: `${module}/order-detail/${order.id}`,
      },
      notification: {
        ...generateOrderNotificationTitleAndBody(order),
        // imageUrl:
        //   "https://api.najeebmart.com/uploads/2020-10-12T06:58:50.389Z293.png",
      },
    },
  };

  return await send(notification);
};

const generateOrderNotificationTitleAndBody = (order) => {
  const orderId = order.id;
  const customerName = order.User.name;

  if (order.orderStatus === "In-Progress") {
    //generate body for order in progress including user info

    return {
      title: `Order In-Progress ID:#${orderId}`,
      body: `Hello ${customerName}, your order ID:#${orderId} is currently being processed. We will notify you once it is ready for delivery. Thank you for shopping with us!`,
    };
  }

  if (order.orderStatus === "Out For Delivery") {
    return {
      title: `Order Out For Delivery ID:#${order.id}`,
      body: `Hello ${customerName}, your order ID:#${orderId} is out for delivery and will reach you soon. Please be ready to receive it. Thank you for your patience!`,
    };
  }

  if (order.orderStatus === "Delivered") {
    return {
      title: `Order Delivered ID:#${order.id}`,
      body: `Hello ${customerName}, your order ID:#${orderId} has been delivered successfully. We hope you enjoy your purchase! Thank you for shopping with us!`,
    };
  }

  if (order.orderStatus === "Declined") {
    return {
      title: `Order Declined ID:#${order.id}`,
      body:
        order.declinedReason ??
        `Hello ${customerName}, we regret to inform you that your order ID:#${orderId} has been declined. Please contact our support team for more information. Thank you for understanding.`,
    };
  }
};

export const NewNotificationService = {
  send,
  sendOrderNotification,
};
