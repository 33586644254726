import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Card, CardContent, Collapse, IconButton } from '@material-ui/core';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { Auth } from '../../auth/Auth';
import { NotificationService } from '../../services/NotificationService';
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { createSelector } from 'reselect';
import { OrdersService } from '../../services/OrdersService';


//console.log(category)
const OfferSchema = yup.object().shape({
	title : yup.string().required(),
	description : yup.string().required(),
})



const TopCustomer = () => {
	const theme = useTheme();
	const [topCustomers , setTopCustomers] = useState([]);

	useEffect(() => {
		OrdersService.topMonthCustomers().then((data) => {
			setTopCustomers(data.response)
		});
	} , [])

	const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
	})
	

	const [ offerDialog, setOfferDialog ] = React.useState({
		open: false,
		customer: null
	});

	const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(OfferSchema)
        
	  });

	const onSubmit= (data) => {
		let notificationData = {
			notifier: offerDialog.customer.User,
			actor: Auth.User(),
			title: data.title,
			description: data.description
		};

		setOfferDialog({
			open:false,
			customer:null
		})

		NotificationService.sendTopCustomerNotification(notificationData)
			.then((data) => {
				setAlert({
					show:true,
					message:data.message,
					type:"success"
				})
				
			})
			.catch((error) => {
				setAlert({
					show:true,
					message:"Can't send offer at the moment.try later",
					type:"error"
				})
			});
	}

	  
	const columns = [
		{
			name: 'Customer Name',
			selector: 'User.name'
		},
		{
			name: 'Phone',
			selector: 'User.phone'
		},
		{
			name: 'Orders',
			selector: 'totalOrders',
			sortable: true
		},
		{
			name: 'Total Amount',
			selector: 'totalAmount',
			sortable: true
		},
		{
			name: 'Action',
			selector: 'action',
			cell: (customer) => (
				<Button variant="contained" color="primary" size="small" onClick={() => setOfferDialog({
					open:true,
					customer:customer
				})}>
					Send Offer
				</Button>
			)
		}
	];




	const tableData = {
		columns: columns,
		data: topCustomers
	};
	return (
		<React.Fragment>
			<Card>
			<Collapse in={alert.show}>
                            <Alert
                                severity={`${alert.type}`}
                                action={
                                    <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlert({
                                            show:false,
                                            message:null,
                                            type:null
                                    
                                        })
                                    }}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                >
                                {alert.message}
                                </Alert>
                            </Collapse>
				<CardContent>
					<DataTableExtensions {...tableData} filterPlaceholder="Filter customers">
						<DataTable
							theme={theme.palette.type}
							title={'Top Monthly Customers'}
							columns={columns}
							data={topCustomers}
							defaultSortAsc={false}
							defaultSortField="totalAmount"
							sortIcon={<SortIcon />}
							pagination
							highlightOnHover
						/>
					</DataTableExtensions>
			</CardContent>
		</Card>
		<Dialog
            open={offerDialog.open}
            onClose={()=> setOfferDialog({
                open:false,
                customer:null
            })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{`Send Offer to ${offerDialog.customer?.User?.name}`}</DialogTitle>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					<TextField
                                error={errors.title ? true : false}
                                variant="outlined"
                                margin="normal"
                                inputRef={register}
                                required
                                fullWidth
                                id="title"
                                label="Title"
                                name="title"
                                autoComplete="title"
                                helperText={errors.title?.message}
                                autoFocus
                            />
                            
                            <TextField
                            
                                multiline
                                rows={4}
                                error={errors.description ? true : false}
                                variant="outlined"
                                margin="normal"
                                inputRef={register}
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                autoComplete="description"
                                helperText={errors.description?.message}
                                
                            />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=> setOfferDialog({
						open:false,
						customer:null
					})} color="secondary">
						Close
					</Button>
					<Button type="submit" color="secondary" autoFocus>
						Send Offer
					</Button>
				</DialogActions>
			</form>
        </Dialog>
		</React.Fragment>
	);
};

export default TopCustomer;
