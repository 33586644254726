import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
  headers: { "x-access-token": Auth.token() },
};

const getMonthlyReportsByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.martReports.v3.monthlyReportByYear}/${monthId}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthylProductReportByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.monthylProductReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllMonthTotalSalesByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.allMonthTotalSalesByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthProductSalesCountByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.monthProductSalesCountByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthlySalesInCategoryByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.monthlySalesInCategoryByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getUsersAndOrdersByYear = (year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.martReports.v3.usersAndOrdersByYear}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsMonthlyReportsByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.martsMonthlyReportByYear}/${monthId}/${year}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsMonthylProductReportByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.martsMonthylProductReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsAllMonthTotalSalesByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.martsAllMonthTotalSalesByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsMonthProductSalesCountByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.martsMonthProductSalesCountByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsMonthlySalesInCategoryByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.martReports.v3.martsMonthlySalesInCategoryByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMartsUsersAndOrdersByYear = (year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.martReports.v3.martsUsersAndOrdersByYear}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MartReportService = {
  getMonthlyReportsByYear,
  getMonthylProductReportByYear,
  getAllMonthTotalSalesByYear,
  getMonthProductSalesCountByYear,
  getMonthlySalesInCategoryByYear,
  getUsersAndOrdersByYear,

  getMartsMonthlyReportsByYear,
  getMartsMonthylProductReportByYear,
  getMartsAllMonthTotalSalesByYear,
  getMartsMonthProductSalesCountByYear,
  getMartsMonthlySalesInCategoryByYear,
  getMartsUsersAndOrdersByYear,
};
