import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingButton = ({loading,children, ...rest}) => {
    return <>
          {loading ? <CircularProgress color="primary"/> : <Button {...rest}>{children}</Button>}  
    </>
}

export default LoadingButton
