import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
	headers: {'x-access-token': Auth.token()}
  };
const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.ageGroups.v3.findAll}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const save = (data ) => {
	return new Promise((resolve, reject) => {
		axios.post(Urls.ageGroups.v3.save, data, { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
	});
};

const edit = (data ) => {
	return new Promise((resolve, reject) => {
		axios.put(Urls.ageGroups.v3.edit, data, { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
	});
};

export const AgeGroupService = {
	findAll: findAll,
	save: save,
	edit
}