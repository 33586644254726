import React, { Component, useState } from 'react';
import { Grid } from '@material-ui/core';
import NewCategory from './NewCategory';
import CategoryList from './CategoryList';

const Category = () => {
	const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={12} md={4}>
				<NewCategory setItemObj={setItemObj} editItemObj={editItemObj}/>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<CategoryList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
			</Grid>
		</Grid>
	);
};

export default Category;
