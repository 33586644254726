import { Avatar, Card, CardContent, CardHeader, CircularProgress, FormControl , IconButton, Typography, MenuItem,InputLabel,Select,Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Button, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { PrescriptionService } from '../../../services/PrescriptionService'
import {useTheme , makeStyles}  from '@material-ui/core/styles'
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import PrintIcon from '@material-ui/icons/Print';
import ForwardIcon from '@material-ui/icons/Forward';
import SortIcon from '@material-ui/icons/Sort';
import EditIcon from '@material-ui/icons/Edit';
import MAlert from '../../elements/MAlert'
import PrescriptionListTable from './PrescriptionListTable'


const socketSelector= createSelector(state=> state.socket , socket => socket)
const PrescriptionList = () => {
    

    const socket= useSelector(socketSelector)
    const [prescriptions , setPrescriptions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        PrescriptionService.findAll()
        .then(res => {
            setLoading(false)
            setPrescriptions(res.response)
            console.log(res.response)
        })

    } , [])

   


   
    

    return  <Card>
            <CardHeader title="Prescriptions"/>
            <CardContent>
                <PrescriptionListTable data={prescriptions} loading={loading} />
            </CardContent>
    </Card>
}

export default PrescriptionList
