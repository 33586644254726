import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import NewProductCategory from './NewProductCategory';
import ProductCategoryList from './ProductCategoryList';

const ProductCategory = () => {
	const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={12} md={4}>
				<NewProductCategory setItemObj={setItemObj} editItemObj={editItemObj}/>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<ProductCategoryList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
			</Grid>
		</Grid>
	);
};

export default ProductCategory;
