import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findAll = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.notification.v3.findAll}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendTopCustomerNotification = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.notification.v3.topCustomer}`, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (id, entityTypeId, entityId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${Urls.notification.v3.edit}`,
        { id, entityTypeId, entityId },
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findOne = (id, entityTypeId, entityId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.notification.v3.findOne}/${id}/${entityTypeId}/${entityId}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generalNotification = (data) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("description", data.description);
  formData.append("image", data.image);
  formData.append("deviceIds", data.deviceIds);
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.notification.v3.general}`, formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const listGeneralNotification = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.notification.v3.general}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const reSend = (notification) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.notification.v3.resend}`, notification)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendNotification = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.notification.v3.product}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendUpdateNotification = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.notification.v3.update}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const NotificationService = {
  findAll,
  findOne,
  sendTopCustomerNotification,
  edit,
  generalNotification,
  listGeneralNotification,
  reSend,
  sendNotification,
  sendUpdateNotification,
};
