import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import NewBanners from './NewBanners';
import BannersList from './BannersList';

const Banners = () => {
	const [banner , setBanner]= useState(null)
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={12} md={4}>
				<NewBanners setBanner={setBanner}/>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<BannersList banner={banner}/>
			</Grid>
		</Grid>
	);
};

export default Banners;
