import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";
import { DrugService } from "../../../services/DrugService";
import MAlert from "../../elements/MAlert";

const useStyle = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const DrugSalesUploads = () => {
  const [fileInputKey, setFileInputKey] = useState(0);
  const [fileError, setFileError] = useState();
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });
  const [isUploading, setUploading] = useState(false);
  const classes = useStyle();

  const onFileHandleChange = (event) => {
    let files = event.target.files;
    console.log(files);
    if (files && files.length == 0) {
      setFileError("Product images is required");
      return;
    }
    setFileError(null);
    const userConfirmed = window.confirm("Are you sure you want to proceed?");
    if (userConfirmed) {
      setUploading(true);
      DrugService.updateDrugSalesXlsx(files, onUploadProgress)
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });

          resetFileInput();
          setUploading(false);
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Can't update pharmacy items",
            type: "error",
          });
          setUploading(false);
          resetFileInput();
        });
    }
  };

  const resetFileInput = () => {
    // Increment the key to force re-rendering of the file input
    setFileInputKey((prevKey) => prevKey + 1);
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => {
        setProgress(0);
        setShowProgress(false);
      }, 1000);
    }
  };
  return (
    <Card>
      <CardHeader title={"Upload Pharmacy prices File"}></CardHeader>
      <CardContent>
        <MAlert alert={alert} setAlert={setAlert} />

        <Box width="100%" className={classes.box} mr={1} style={{}}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <input
          accept=".xlsx"
          className={classes.input}
          id="contained-button-file"
          key={fileInputKey} // Use key to force re-rendering
          onChange={onFileHandleChange}
          type="file"
        />
        <br />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={isUploading}
          >
            Upload
          </Button>
        </label>
      </CardContent>
    </Card>
  );
};

export default DrugSalesUploads;
