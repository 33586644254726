import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button,Card, CardHeader, CardContent, TextField,CircularProgress, ListItem, ListItemAvatar, Badge, ListItemText, Typography, ListItemSecondaryAction } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { DrugOrderService } from '../../../services/DrugOrderService';
import { DrugService } from '../../../services/DrugService'
import { proxyPath } from '../../../services/ApiPath';
import placeHolderImage from './../../../nj_placeholder.png'

const useStyles = makeStyles((theme) => ({
	card: {
		overflow: 'auto'
	},
	textField: {
		width: 300
	},
	prices: {
        marginTop :'5px',
        background : theme.palette.secondary.main,
        borderRadius : 5,
        color: '#fff',
        padding : '4px 10px 4px 10px'
    }
}));

const DrugItemSelector = ({onAddItem,...rest}) => {
    const classes = useStyles();
	const [loading , setLoading] = useState(false)
	const [drugs, setDrugs] =useState([]) 
    const [ drug, setDrug ] = React.useState(null);
    
    const onChange = (event, value, reason) => {
		setDrug(value);
    };
    
    const  onLoadDrugs = (event) => {
		if(drugs.length==0){
            setLoading(true)
            
			DrugService.findAll().then((data) => {
				setLoading(false)
				setDrugs(data.response)
			}).catch(error=> setLoading(false));
		}
	}

    return (
		<div id="non-printable">
			<Card className={classes.card}>
				<CardHeader
					title="Product"
					action={
						<Autocomplete
							loading={loading}
							onOpen={(event) =>onLoadDrugs()}
							className={classes.textField}
							id="highlights-demo"
							style={{ width: 300 }}
							options={drugs}
							getOptionLabel={(option) => option.name + ' '+ option.DrugType.title+' ' + option.measurement}
							onChange={onChange}
							renderInput={(params) => (
								<TextField {...params} 
								label="Search Products" 
								variant="outlined" 
								margin="normal" 
								InputProps={{
									...params.InputProps,
									endAdornment: (
									  <React.Fragment>
										{loading ? (
										  <CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									  </React.Fragment>
									)
								  }}/>
							)}
							renderOption={(option, { inputValue }) => {
								const matches = match(option.name + ' '+ option.DrugType.title+' ' + option.measurement, inputValue);
								const parts = parse(option.name + ' '+ option.DrugType.title+' ' + option.measurement, matches);
								

								return  (
									<div>
										{parts.map((part, index) => (
											<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
												{part.text}
											</span>
										))}
									</div>
								)
							}}
						/>
					}
				/>
				<CardContent>
					{drug && <ListItem alignItems="flex-start">
						<ListItemAvatar>
							<Badge color="secondary" badgeContent={`${Math.round(drug.discount)}%`}  invisible={drug.discount > 0 ? false : true}anchorOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}>
							<img width="50" height="60" src={drug.images!==null && drug.images.length> 0  ? `${proxyPath}${drug.images[0]?.path}` : placeHolderImage  }/>
							</Badge>
						</ListItemAvatar>
						<ListItemText
							primary={`${drug.name} ${drug.DrugType.title} ${drug.measurement}`}
							secondary={
								<React.Fragment>
									<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
									{drug.DrugBrands[0].title}
									</Typography>
									<br />
									<br />
									<span className={classes.prices}>Rs {drug.DrugSales[0].price}/  {drug.DrugSales[0].title}</span>
									
									
									
								</React.Fragment>
							}
						/>
						<ListItemSecondaryAction>
							<Button color="primary" variant="contained" className={classes.button} onClick={() => onAddItem(drug)}>Add Item</Button>
						</ListItemSecondaryAction>
					</ListItem>}
				</CardContent>
			</Card>
		</div>
	);
}

export default DrugItemSelector
