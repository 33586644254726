import { Card, CardActionArea, CardContent, CardHeader, TextField,Checkbox, FormControlLabel, CardActions, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import React, { useEffect, useState } from 'react'
import LoadingButton from '../../elements/LoadingButton'
import MAlert from '../../elements/MAlert'
import { DrugCategoryService } from '../../../services/DrugCategoryService'

const NewDrugCategory = ({editItemObj , setItemObj, ...rest}) => {
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const Schema = yup.object().shape({
        title : yup.string().required("Title is required"),
        status : yup.boolean(), 
    })

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(Schema),
        defaultValues : {status : true}
        
      });


    const resetForm =() => {
        setLoading(false)
        editItemObj.isEdit= false
        reset({
            title:null,
            status:true
        },{
            errors: false,
            dirtyFields: false, 
            isDirty: false, 
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          })
    }

    const onSubmit = (data)=> {
       data.staus= status
       if(editItemObj.isEdit){
            editItemObj.item.title=data.title
            editItemObj.item.status= status
           DrugCategoryService.edit(editItemObj.item).then(res => {
            setItemObj({item:res.response , isNew:false})
            resetForm()
            setAlert({
                show:true,
                message: res.message,
                type:'success'
            })
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setAlert({
                show:true,
                message: error.message || "Can't save new drug category. Try again.!",
                type:'error'
            })
        })

       }else{
            setLoading(true)
            DrugCategoryService.save(data).then(res => {
                setItemObj({item:res.response , isNew:true})
                resetForm()
                setAlert({
                    show:true,
                    message: res.message,
                    type:'success'
                })
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setAlert({
                    show:true,
                    message: error.message || "Can't save new drug category. Try again.!",
                    type:'error'
                })
            })
       }
    }

    useEffect(() => {
        if(editItemObj.isEdit){
            setValue('title',editItemObj.item?.title, {shouldValidate:true})
            setStatus(editItemObj.item?.status)
        }
    } , [editItemObj])


    
    return <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
        <CardHeader title={editItemObj.isEdit? `Edit ${editItemObj.item?.title} Drug Category` : `New Drug Category`}/>
        <CardActionArea>
            <CardContent>
            <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                helperText={errors.title?.message}
                autoFocus/>

            <FormControlLabel
                control={<Checkbox checked={status} onChange={(event)=> setStatus(!status)} name="status" id="status" />}
                label="Status"
                />
            </CardContent>
        </CardActionArea>
        
        <CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => resetForm()}>
                Cancel
            </Button>
            <LoadingButton type="submit" loading={loading} size="small" variant="contained" color="primary">
                {editItemObj.isEdit ? `Edit` : `Save`}
            </LoadingButton>
        </CardActions>
       
    </Card>
    </form>
}

export default NewDrugCategory
