import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useRef, useEffect } from "react";
import CancelIcon from "@material-ui/icons/Cancel";

const DrugSale = ({ drugSale, onRemove, onChange, ...rest }) => {
  const titleRef = useRef(null);
  const priceRef = useRef(null);
  const { index, title, price, discount } = drugSale;

  useEffect(() => {
    if (titleRef != null) titleRef.current.value = title;
  }, [titleRef]);

  useEffect(() => {
    if (priceRef != null) priceRef.current.value = price;
  }, [priceRef]);
  return (
    <Card>
      <CardHeader
        action={
          <IconButton
            onClick={(event) => onRemove(index)}
            aria-controls="filter-menu"
            aria-haspopup="true"
          >
            <CancelIcon />
          </IconButton>
        }
      />
      <CardContent>
        <TextField
          onChange={(event) => onChange(index, event)}
          inputRef={titleRef}
          variant="outlined"
          margin="normal"
          fullWidth
          id={`title`}
          label="Title"
          placeholder="Sale Type"
          name={`title`}
          autoComplete={`title`}
        />
        <TextField
          onChange={(event) => onChange(index, event)}
          inputRef={priceRef}
          variant="outlined"
          margin="normal"
          fullWidth
          placeholder="Price"
          id={`price`}
          label="Price"
          name={`price`}
          autoComplete={`Price`}
        />
        <TextField
          onChange={(event) => onChange(index, event)}
          inputRef={priceRef}
          variant="outlined"
          margin="normal"
          fullWidth
          placeholder="Price"
          id={`price`}
          label="Price"
          name={`price`}
          autoComplete={`Price`}
        />
      </CardContent>
    </Card>
  );
};

export default DrugSale;
