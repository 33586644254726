import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  ListItemIcon,
  Typography,
  Button,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  TextField,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DataTable from "react-data-table-component";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import InboxIcon from "@material-ui/icons/Inbox";
import MailIcon from "@material-ui/icons/Mail";
import CategoryIcon from "@material-ui/icons/Category";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import ListIcon from "@material-ui/icons/List";
import PieChartIcon from "@material-ui/icons/PieChart";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Actions } from "../../reducers/actions";
import ViewListIcon from "@material-ui/icons/ViewList";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import EventIcon from "@material-ui/icons/Event";
import MAlert from "../elements/MAlert";
import { createSelector } from "reselect";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import { NotificationService } from "../../services/NotificationService";
import { proxyPath } from "../../services/ApiPath";
import { CheckBox } from "@material-ui/icons";
import { UserService } from "../../services/UserService";
let deviceIds = [];
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },

  menuButton: {
    marginRight: theme.spacing(4),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: (props) => props.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.up("xs")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
const AppDrawer = (props) => {
  const [users, setUsers] = useState([]);
  const [userDialog, setUserDialog] = useState({
    open: false,
  });
  const classes = useStyles(props);
  const { closeDrawer, open } = props;
  const [nestedState, setNestedState] = React.useState(false);
  const [isMartsReports, setMartsReports] = React.useState(false);
  const [phNestedState, setPhNestedState] = React.useState(false);
  const [isAllPharmacy, setAllPharmacy] = React.useState(false);
  const [isAllReports, setIsAllReports] = React.useState(false);
  const [nestedNotification, setNestedNotification] = React.useState(false);
  const [nestedGrocery, setNestedGrocery] = useState(false);
  const [nestedPharmacy, setNestedPharmacy] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [message, setMessage] = useState({
    title: "",
    description: "",
  });
  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setNestedState(!nestedState);
  };
  const onMenuClick = (to) => {
    props.history.push(to);
  };
  const theme = useTheme();

  const sendNotifications = () => {
    let data = {
      ...message,
      entityTypeId: 10,
      deviceIds: [], //JSON.stringify(users.map(u => u.deviceId))
    };
    setLoading(true);
    NotificationService.sendUpdateNotification(data)
      .then((res) => {
        setAlert({
          show: true,
          type: "success",
          message: res.message,
        });
        setLoading(false);
      })
      .catch((error) => {
        setAlert({
          show: true,
          type: "error",
          message: "Can't send notification at the moment.try later.",
        });
        setLoading(false);
      });
  };

  // useEffect(() => {
  //         UserService.findAll().then(res=> {
  //             setUsers(res.response.filter(u=> u.deviceId!==null))

  //         }).catch(error =>{})

  // },[])
  return (
    <div id="non-printable" style={props.style}>
      <Drawer
        variant="permanent"
        open={open}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={closeDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={() => onMenuClick("/")}>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => onMenuClick("/contacts")}>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
          <ListItem button onClick={() => onMenuClick("/app-config")}>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="App Config" />
          </ListItem>
          <ListItem button onClick={() => setNestedGrocery(!nestedGrocery)}>
            <ListItemIcon>
              <LocalGroceryStoreIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Grocery</ListItemText>
            {nestedGrocery ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={nestedGrocery} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/products/upload")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Update Prices" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/category")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Category" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/subcategory")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Sub Category" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/productcategory")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Product Category" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/products")}
            >
              <ListItemIcon>
                <ListIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/banners")}
            >
              <ListItemIcon>
                <ListIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Banners" />
            </ListItem>
          </Collapse>
          <ListItem button onClick={() => setNestedPharmacy(!nestedPharmacy)}>
            <ListItemIcon>
              <LocalPharmacyIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Pharmacy</ListItemText>
            {nestedPharmacy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={nestedPharmacy} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/uploads")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Update Prices" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/banners")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drug Banners" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/category")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drug Category" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/subcategory")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drug Sub Category" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/featuredrugs")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Feature Drugs" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/types")}
            >
              <ListItemIcon>
                <CategoryIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drug Types" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/brands")}
            >
              <ListItemIcon>
                <ListIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drug Brands" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/drugs")}
            >
              <ListItemIcon>
                <ListIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Drugs" />
            </ListItem>

            <ListItem
              className={classes.nested}
              button
              onClick={() => onMenuClick("/pharmacy/employees")}
            >
              <ListItemIcon>
                <ListIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Employees" />
            </ListItem>
          </Collapse>
          <ListItem button onClick={() => onMenuClick("/users")}>
            <ListItemIcon>
              <GroupIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button onClick={() => onMenuClick("/orders")}>
            <ListItemIcon>
              <ViewListIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Mart Orders" />
          </ListItem>
          <ListItem
            button
            onClick={() => onMenuClick("/pharmacy/prescriptions")}
          >
            <ListItemIcon>
              <ListIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Prescriptions" />
          </ListItem>
          <ListItem button onClick={() => onMenuClick("/pharmacy/orders")}>
            <ListItemIcon>
              <ListIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Drug Orders" />
          </ListItem>
          <ListItem button onClick={() => onMenuClick("/notifications")}>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
          <ListItem button onClick={() => handleClick()}>
            <ListItemIcon>
              <InboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Marts Reports</ListItemText>
            {nestedState ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={nestedState} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/customer/top")}
            >
              <ListItemIcon>
                <StarBorder color="primary" />
              </ListItemIcon>
              <ListItemText>Top Customer</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/graphs")}
            >
              <ListItemIcon>
                <PieChartIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Graph</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/sales")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Daily Sales</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/products/sales")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Product Sales</ListItemText>
            </ListItem>
          </Collapse>
          <ListItem button onClick={() => setMartsReports(!isMartsReports)}>
            <ListItemIcon>
              <InboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText>All Marts Reports</ListItemText>
            {isMartsReports ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={isMartsReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/marts/graphs")}
            >
              <ListItemIcon>
                <PieChartIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Graph</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/marts/sales")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Daily Sales</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/reports/marts/products/sales")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Product Sales</ListItemText>
            </ListItem>
          </Collapse>
          <ListItem button onClick={() => setPhNestedState(!phNestedState)}>
            <ListItemIcon>
              <InboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Pharmacy Report</ListItemText>
            {nestedState ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={phNestedState} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/graphs")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Graphs</ListItemText>
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/monthly")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Daily Sales</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/monthly/drugs")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Drug Sales</ListItemText>
            </ListItem>
          </Collapse>

          <ListItem button onClick={() => setAllPharmacy(!isAllPharmacy)}>
            <ListItemIcon>
              <InboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText>All Pharmacy Report</ListItemText>
            {isAllPharmacy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isAllPharmacy} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/all/graphs")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Graphs</ListItemText>
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/all/monthly")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Daily Sales</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => onMenuClick("/pharmacy/reports/all/monthly/drugs")}
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Drug Sales</ListItemText>
            </ListItem>
          </Collapse>

          <ListItem button onClick={() => setIsAllReports(!isAllReports)}>
            <ListItemIcon>
              <InboxIcon color="primary" />
            </ListItemIcon>
            <ListItemText>All Reports</ListItemText>
            {isAllPharmacy ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isAllReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding />
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                onMenuClick("/all/reports/mart-users-orders-by-months")
              }
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Mart User Order Reports</ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                onMenuClick("/all/reports/pharmacy-users-orders-by-months")
              }
            >
              <ListItemIcon>
                <AttachMoneyIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Pharmacy User Order Reports</ListItemText>
            </ListItem>
          </Collapse>

          <ListItem button onClick={() => onMenuClick("/feedbacks")}>
            <ListItemIcon>
              <ListIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Feedbacks" />
          </ListItem>
        </List>
        <Divider />

        {/* <List>
					{[ 'All mail', 'Trash', 'Spam' ].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					))}
				</List> */}
      </Drawer>

      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"App Update Notification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MAlert alert={alert} setAlert={setAlert} />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={message.title}
              onChange={(event) =>
                setMessage({
                  ...message,
                  title: event.target.value,
                })
              }
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <TextField
              multiline
              rows={5}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={message.description}
              onChange={(event) =>
                setMessage({
                  ...message,
                  description: event.target.value,
                })
              }
              id="description"
              label="Description"
              name="description"
              autoComplete="description"
              autoFocus
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => sendNotifications()}
            color="secondary"
            autoFocus
            disabled={loading}
          >
            Send Notification
          </Button>
        </DialogActions>
        <LinearProgress style={{ display: loading ? "block" : "none" }} />
      </Dialog>
    </div>
  );
};

export default withRouter(AppDrawer);
