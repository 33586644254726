import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@material-ui/core";
import QrDiscount from "../qrdiscounts/QrDiscount";
import DeliveryCharges from "./DeliveryCharges";
const AppConfig = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <DeliveryCharges />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <QrDiscount />
      </Grid>
    </Grid>
  );
};

export default AppConfig;
