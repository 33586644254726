import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  Card,
  Checkbox,
  Switch,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  Typography,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Radio,
  CardContent,
  CardHeader,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import { proxyPath } from "../../services/ApiPath";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import SortIcon from "@material-ui/icons/Sort";
import { Actions } from "../../reducers/actions";
import Moment from "react-moment";
import { OrdersService } from "../../services/OrdersService";
import PrintIcon from "@material-ui/icons/Print";
import ForwardIcon from "@material-ui/icons/Forward";
import { useHistory } from "react-router-dom";
import ForwardOrder from "./ForwardOrder";
import MAlert from "../elements/MAlert";
import { createSelector } from "reselect";
import _, { unescape } from "underscore";
import NumberTextField from "../elements/NumberTextField";
import { NewNotificationService } from "../../services/NewNotificationService";
import NotificationsIcon from "@material-ui/icons/Notifications";
import UserOrderNotificationDialog from "../../shared-components/dialogs/user-order-notification.dialog";
const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);
const useStyles = makeStyles((theme) => ({
  new: {
    backgroundColor: theme.palette.success.main,
    padding: "5px",
    borderRadius: "5px",
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
    padding: "5px",
    borderRadius: "5px",
    color: "#FFF",
  },
  inProgress: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px",
    borderRadius: "5px",
  },
  declined: {
    backgroundColor: theme.palette.error.main,
    padding: "5px",
    borderRadius: "5px",
  },
  delivered: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px",
    borderRadius: "5px",
  },
}));

const todayOrdersSelector = createSelector(
  (state) => state.todayOrders,
  (todayOrders) => todayOrders
);
const notificationSelector = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

const OrdersByDate = (props) => {
  const [openUserOrderNotificationDialog, setOpenUserOrderNotificationDialog] =
    useState({
      open: false,
      order: undefined,
    });
  const socket = useSelector(socketSelector);

  const history = useHistory();
  const [snakbar, setSnakbar] = useState({
    open: false,
    message: "",
  });

  const [forwardOrderDialog, setForwardOrderDialog] = React.useState({
    open: false,
    order: null,
  });
  const [declinedReason, setDeclinedReason] = React.useState({
    status: "Declined",
    reason: null,
    error: null,
  });
  const [declinedDialog, setDeclinedDialog] = React.useState({
    open: false,
    order: null,
  });
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });
  const [invoiceNo, setInvoiceNo] = React.useState("");

  const [todayOrders, setTodayOrders] = React.useState([]);
  const orders = useSelector(todayOrdersSelector);
  const notifications = useSelector(notificationSelector);

  useEffect(() => {
    setTodayOrders(
      invoiceNo === null || invoiceNo === ""
        ? orders
        : orders.filter((order) => order.id == invoiceNo)
    );
  }, [orders, invoiceNo]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const getStatusClass = (orderStatus) => {
    if (orderStatus === "New") {
      return classes.new;
    } else if (orderStatus === "Pending") {
      return classes.pending;
    } else if (
      orderStatus === "In-Progress" ||
      orderStatus === "Out For Delivery"
    ) {
      return classes.inProgress;
    } else if (orderStatus === "Delivered") {
      return classes.delivered;
    } else {
      return classes.declined;
    }
  };

  const columns = [
    {
      name: "Invoice No",
      selector: "id",
      sortable: true,
      cell: (order) => (
        <Typography variant="span"> INVOICE-#{order.id}</Typography>
      ),
    },
    {
      name: "Image",
      selector: "image.path",
      cell: ({ User }) => (
        <>
          <Avatar src={`${proxyPath}${User?.picUrl?.path}`} />
          <Typography variant="span"> {User?.name}</Typography>{" "}
        </>
      ),
    },
    {
      name: "Total",
      selector: "total",
      cell: (order) => (
        <Typography variant="span"> {order.total} PKR</Typography>
      ),
    },
    {
      name: "Grand Total",
      selector: "total",
      cell: (order) => (
        <Typography variant="span"> {order.grandTotal} PKR</Typography>
      ),
    },

    {
      name: "Status",
      selector: "orderStatus",
      cell: (order) => (
        <Typography
          component="span"
          variant="span"
          className={getStatusClass(order.orderStatus)}
        >
          {" "}
          {order.orderStatus}
        </Typography>
      ),
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (order) => (
        <Moment format="D MMM YYYY hh:mm a" withTitle>
          {order.createdAt}
        </Moment>
      ),
    },
    {
      name: "Actions",
      selector: "action",
      ignoreRowClick: true,
      cell: (order) =>
        order.orderStatus !== "Delivered" ? (
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="order-status">Order Status</InputLabel>
            <Select
              fullWidth
              labelId="order-status"
              id="orderStatus"
              value={order.orderStatus}
              onChange={(event) => handleStatusChange(event, order)}
            >
              <MenuItem value={"New"}>New</MenuItem>
              {/* <MenuItem value={'Pending'}>Pending</MenuItem> */}
              <MenuItem value={"Declined"}>Declined</MenuItem>
              <MenuItem value={"In-Progress"}>In-Progress</MenuItem>
              <MenuItem value={"Out For Delivery"}>Out For Delivery</MenuItem>
              <MenuItem value={"Delivered"}>Delivered</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography
            component="span"
            variant="span"
            className={getStatusClass(order.orderStatus)}
          >
            {" "}
            Order Closed
          </Typography>
        ),
    },

    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (order) => (
        <React.Fragment>
          <IconButton
            onClick={() =>
              setOpenUserOrderNotificationDialog({
                open: true,
                order: order,
              })
            }
            size="small"
            component="span"
          >
            <NotificationsIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              history.push({
                pathname: `/orders/detail/${order.id}`,
                state: order,
              })
            }
            size="small"
            component="span"
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              order.orderStatus === "New"
                ? setForwardOrderDialog({
                    open: true,
                    order: order,
                  })
                : setSnakbar({
                    open: true,
                    message: `The order is in ${order.orderStatus} you can't forward this order.`,
                  })
            }
            size="small"
            component="span"
          >
            <ForwardIcon />
          </IconButton>
        </React.Fragment>
      ),
      right: true,
    },
  ];

  const handleStatusChange = (event, order) => {
    const { name, value } = event.target;
    if (value === "Declined") {
      setDeclinedDialog({
        open: true,
        order: order,
      });
      return;
    }

    order.orderStatus = event.target.value;
    changeOrderStatus(order);
  };

  const onDeclinedOrder = () => {
    if (declinedReason.reason === null || declinedReason.reason === "") {
      setDeclinedReason({
        ...declinedReason,
        error: "Declined reason is required.",
      });
      return;
    }

    let order = declinedDialog.order;
    order.orderStatus = declinedReason.status;
    order.declinedReason = declinedReason.reason;

    changeOrderStatus(order);
  };

  const changeOrderStatus = async (order) => {
    setDeclinedDialog({
      open: false,
      order: null,
    });

    try {
      const res = await OrdersService.edit(order);
      socket.emit("EVENT_ORDER", order);
      socket.emit("MART_CHANGE_ORDER", JSON.stringify(order));
      props.setStatusChange(true);
      if (
        order.orderStatus === "Delivered" ||
        order.orderStatus === "Declined"
      ) {
        dispatch(Actions.removeTodayOrder(order));
      } else {
        dispatch(Actions.editTodayOrder(order));
      }

      const notiResponse = await NewNotificationService.sendOrderNotification(
        order,
        "mart"
      );
      setAlert({
        show: true,
        message: res.message,
        type: "success",
      });
    } catch (err) {
      setAlert({
        show: false,
        message: "Can't change order status",
        type: "error",
      });
    }
  };

  const onDeclinedReasonChange = (event) => {
    let value = event.target.value;
    if (value === "") {
      setDeclinedReason({
        ...declinedReason,
        reason: value,
        error: "Declined reason is required",
      });
      return;
    }
    setDeclinedReason({
      ...declinedReason,
      reason: value,
      error: null,
    });
  };

  return (
    <React.Fragment>
      <MAlert alert={alert} setAlert={setAlert} />
      <Card>
        <CardHeader
          title="Recent Orders"
          action={
            <NumberTextField
              value={invoiceNo}
              label="Invoice No"
              onChange={(event) => setInvoiceNo(event.target.value)}
            />
          }
        />
        <CardContent>
          <DataTable
            // noDataComponent="No Recent orders"
            dense
            pointerOnHover
            highlightOnHover
            striped
            noDataComponent={<span>No new orders yet.</span>}
            defaultSortAsc={false}
            keyField="id"
            noHeader
            theme={theme.palette.type}
            title={props.title}
            columns={columns}
            data={todayOrders}
            defaultSortField="id"
            sortIcon={<SortIcon />}
            pagination
          />
        </CardContent>
      </Card>

      <Dialog
        open={declinedDialog.open}
        onClose={() =>
          setDeclinedDialog({
            open: false,
            order: null,
          })
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Declined Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              multiline
              error={declinedReason.error ? true : false}
              helperText={declinedReason.error}
              rows={4}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="declinedReason"
              label="Declined Reason"
              name="declinedReason"
              autoComplete="declinedReason"
              onChange={onDeclinedReasonChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeclinedDialog({
                open: false,
                order: null,
              })
            }
            color="secondary"
          >
            Not Sure
          </Button>
          <Button onClick={() => onDeclinedOrder()} color="secondary" autoFocus>
            Yes, Decline
          </Button>
        </DialogActions>
      </Dialog>

      <ForwardOrder
        setAlert={setAlert}
        order={forwardOrderDialog.order}
        open={forwardOrderDialog.open}
        setForwardOrderDialog={setForwardOrderDialog}
      />

      <UserOrderNotificationDialog
        open={openUserOrderNotificationDialog.open}
        onClose={() =>
          setOpenUserOrderNotificationDialog({
            open: false,
            order: undefined,
          })
        }
        order={openUserOrderNotificationDialog.order}
        module="mart"
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snakbar.open}
        onClose={() => setSnakbar({ open: false, message: "" })}
        message={snakbar.message}
        key={"bottom" + "right"}
      />
    </React.Fragment>
  );
};

export default React.memo(OrdersByDate);
