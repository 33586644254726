import { Grid } from '@material-ui/core'
import React , {useState} from 'react'
import DrugBannerList from './DrugBannerList'
import NewDrugBanner from './NewDrugBanner'

const DrugBanners = () => {
    const [itemObj, setItemObj] = useState({item:null,isNew: false})
    return <Grid container spacing={4}>
    <Grid item xs={12} sm={12} md={4}>
        <NewDrugBanner setItemObj={setItemObj}/>
    </Grid>
    <Grid item xs={12} sm={12} md={8}>
        <DrugBannerList itemObj={itemObj}/>
    </Grid>
</Grid>
}

export default DrugBanners
