import { Card, CardContent, CardHeader ,Grid, useTheme} from '@material-ui/core'
import React , {useEffect , useState} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import { MartReportService } from '../../services/MartReportService'


const date=  new Date()
const currentDate = {
    name: moment(date).format("MMMM"), 
    id: date.getMonth()+1,
    year : date.getFullYear()
}




  const MartSalesGraph = () => {
    const [selectedDate, setSelectedDate] = React.useState(date);
    const [month , setMonth] = useState(currentDate)
    const theme = useTheme()
    const [loading ,setLoading] = useState(false)
    const [productSalesSeries , setProductSalesSeries] = useState([])
  const [productSalesOptions , setProudctSalesOptions] =useState({
    chart: {
      height: 350,
      type: 'bar',
      zoom: {
        enabled: true
      },
      toolbar: {
        show: true
      }
    },
    noData: {
      text: "No reports available",
      style: {
        color: theme.palette.secondary.main,
        fontSize: '20px'
      }
    },
    title: {
      text: 'Sales Product/Month',
      align: 'left',
      style: {
        fontSize: "16px",
        color: '#666'
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        colors: ["#000"]
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
      },
      offsetX: 0,
      dropShadow: {
        enabled: false
      }
    },
    
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [ theme.palette.primary.main],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    xaxis: {
      labels : {
        style: {
          fontSize: '13px',
          fontWeight: 500,
        }
      },
      categories: [],
    },
    
    
    tooltip : {
      y: {
        formatter: function(val  ,opt){
          return Math.round(val)
        },
        title: {
            formatter: (seriesName) => seriesName,
        },
    },
    }
  })

  useEffect(() => {
    MartReportService.getMonthProductSalesCountByYear(month.id, month.year).then(res=> {
        let categories = res.response.map(item => `${item.title}`)
        let productOptions = {
        ...productSalesOptions,
        xaxis : {
            ...productSalesOptions.xaxis,
            categories: categories
        }
        }
        setProudctSalesOptions(productOptions)
        let seriesData= [{
        name : "Sales" ,
        data: res.response.map(item=> item.totalProducts)
        }]
        setProductSalesSeries(seriesData)
    })
  } , [month])

  useEffect(() => {
    setLoading(true)
    setMonth({
        name: moment(selectedDate).format("MMMM"), 
        id: selectedDate.getMonth()+1,
        year : selectedDate.getFullYear()
    })
} , [selectedDate])


    return <Card>
    <CardHeader action={
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
            <DatePicker
        variant="inline"
        openTo="month"
        views={["year", "month"]}
        label="Year and Month"
        helperText="Start from month selection"
        value={selectedDate}
        onChange={(date) =>setSelectedDate(date)}
      />
            </Grid>
          </MuiPickersUtilsProvider>}/>
    <CardContent>
    <Chart options={productSalesOptions} series={productSalesSeries} type="bar" height="600" />
    </CardContent>
</Card>
}

export default MartSalesGraph
