import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import DrugItem from './DrugItem';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, TextField,CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { DrugOrderService } from '../../../services/DrugOrderService';
import { DrugService } from '../../../services/DrugService'

const useStyles = makeStyles((theme) => ({
	card: {
		overflow: 'auto'
	},
	textField: {
		width: 300
	}
}));

const DrugListItems = ({onOrderItemAdd , id , ...rest}) => {
    const classes = useStyles();
	const [loading , setLoading] = useState(false)
	const [drugs, setDrugs] =useState([]) 
    const [ filterItem, setFilterItem ] = React.useState(null);
    
    const onChange = (event, value, reason) => {
		setFilterItem(value);
    };
    
    const  onLoadDrugs = (event) => {
		if(drugs.length==0){
            setLoading(true)
            
			DrugService.findAll().then((data) => {
				setLoading(false)
				setDrugs(data.response)
			}).catch(error=> setLoading(false));
		}
	}

    return (
		<div id="non-printable">
			<Card className={classes.card}>
				<CardHeader
					title="Products"
					action={
						<Autocomplete
							loading={loading}
							onOpen={(event) =>onLoadDrugs()}
							className={classes.textField}
							id="highlights-demo"
							style={{ width: 300 }}
							options={drugs}
							getOptionLabel={(option) => option.name + ' '+ option.DrugType.title+' ' + option.measurement}
							onChange={onChange}
							renderInput={(params) => (
								<TextField {...params} 
								label="Search Products" 
								variant="outlined" 
								margin="normal" 
								InputProps={{
									...params.InputProps,
									endAdornment: (
									  <React.Fragment>
										{loading ? (
										  <CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									  </React.Fragment>
									)
								  }}/>
							)}
							renderOption={(option, { inputValue }) => {
								const matches = match(option.name + ' '+ option.DrugType.title+' ' + option.measurement, inputValue);
								const parts = parse(option.name + ' '+ option.DrugType.title+' ' + option.measurement, matches);
								

								return  (
									<div>
										{parts.map((part, index) => (
											<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
												{part.text}
											</span>
										))}
									</div>
								)
							}}
						/>
					}
				/>
				<CardContent>
					{filterItem && <DrugItem drug={filterItem} onOrderItemAdd={onOrderItemAdd} />}
				</CardContent>
			</Card>
		</div>
	);
}

export default DrugListItems
