/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "../../orders/thermal.css";
import Moment from "react-moment";
import { getDiscountPrice, roundTo1Digit } from "../../../utils/Utils";

const DrugInvoice = (props) => {
  const { order, isCenter } = props;
  const getTotal = (orderItems) => {
    var totalPrice = 0.0;
    orderItems.map((orderItem) => {
      let drug = orderItem.Drug;
      totalPrice +=
        orderItem.quantity *
        getDiscountPrice(orderItem.price, orderItem.discount);
    });
    return totalPrice;
  };

  const getQuantity = (item) => {
    let qty = 0;
    if (item.saleType === "Pack") {
      qty = item.quantity * item.Drug.stripSize;
    } else {
      qty = item.quantity * item.Drug.stripSize;
    }
    return qty;
  };

  const ciEquals = (a, b) => {
    return typeof a === "string" && typeof b === "string"
      ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
      : a === b;
  };

  const getPrice = (item) => {
    return getDiscountPrice(item.price, item.discount) * item.quantity; //getQuantity(item)
  };

  const roundTo = (price) => {
    return Math.round(price * 100) / 100;
  };

  const getPackOrStrip = (item) => {
    return `${item.quantity} ${item.saleType}`;
    // if(ciEquals(item.saleType, "Pack") || ciEquals(item.saleType , "Box"))
    //     return `${item.quantity} ${item.saleType}`
    // else
    //     return packStripText(calculatePackStrip(item.quantity , item.Drug))
  };

  const countOrderItems = (orderItems) => {
    let items = 0;
    orderItems.map((item) => {
      items += item.quantity;
    });
    return items;
  };

  const getReceipent = (order) => {
    let data = {
      name: order.name,
      phone: order.phone,
    };
    if (order.name === null || order.name === "") {
      data = {
        ...data,
        name: order.User.name,
      };
    }

    if (order.phone === null || order.phone === "") {
      data = {
        ...data,
        phone: order.User.phone,
      };
    }

    return data;
  };

  const packStripText = (data) => {
    let text = "";
    if (data[0] > 0) {
      text += `${data[0]} Pck`;
    }

    if (data[0] > 0 && data[1] > 0) {
      text += " & ";
    }

    if (data[1] > 0) {
      text += `${data[1]} Strp`;
    }

    return text;
  };

  const calculatePackStrip = (quantity, drug) => {
    let numberOfPacks = parseInt(quantity / drug.packSize);
    let numberStrips = quantity - numberOfPacks * drug.packSize;
    return [numberOfPacks, numberStrips];
  };

  return (
    order && (
      <div className="card-body" id={props.id}>
        <div
          className={`invoice-container ${
            isCenter ? "center-invoice" : "margin-top"
          }`}
        >
          <div className="invoice">
            <div className="inovice-header">
              <div className="invoice-brand">
                <img
                  src="/assets/img/brand/invoicelogo.png"
                  className="brand"
                />
              </div>
              <div className="header-content">
                <p>{order?.Admin?.address}</p>
                <p className="stPhone">
                  <strong>Phone</strong> : {order?.Admin?.phone}
                </p>
              </div>
            </div>
            <div className="inovice-body">
              <div class="invoice-no">
                <p>
                  <strong>Invoice : #{order.id}</strong>
                </p>
                <p className="text-right">
                  <strong>Date :</strong>
                  <Moment format="DD/MM/YYYY hh:mm a">{new Date()}</Moment>
                </p>
              </div>
              <div className="divider" />
              <table width="100%">
                <thead>
                  <tr>
                    <th width="60%" className="text-left">
                      Item
                    </th>
                    <th width="5%">Qty</th>
                    <th width="15%" className="text-center">
                      Price
                    </th>
                    <th width="10%" className="text-center">
                      Disc
                    </th>
                    <th width="15%" className="text-right">
                      Amt
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  {order.DrugOrderItems.map((item) => (
                    <tr>
                      <td width="60%" className="width-35">
                        <p
                          className="text"
                          style={{ textTransform: "capitalize" }}
                        >
                          {(item?.Drug?.name || "").toLocaleLowerCase()} <br />{" "}
                          {(
                            item?.Drug.DrugType?.title || ""
                          ).toLocaleLowerCase()}{" "}
                          {(item?.Drug?.measurement || "").toLocaleLowerCase()}
                        </p>
                      </td>
                      <td width="15%" className="text-center">
                        <p>{getPackOrStrip(item)}</p>
                      </td>
                      <td width="10%" className="text-center">
                        <p>{roundTo1Digit(item.price)}</p>
                      </td>
                      <td width="10%" className="text-center">
                        <p>
                          {item.discount > 0 && `${Math.round(item.discount)}%`}
                        </p>
                      </td>
                      <td width="15%" className="text-right">
                        <p>{roundTo1Digit(getPrice(item))}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="text-14 text-left">SubTotal</p>
                    </th>
                    <td className="text-center">
                      <p className="text-14"></p>
                    </td>
                    <td colSpan="3" className="text-right">
                      <p className="text-14">
                        Rs {roundTo1Digit(getTotal(order.DrugOrderItems))}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <p className="b-text  text-14">Flat Disc : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right text-14">
                      <p className="b-text  text-14">Delivery : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">
                        {order.deliveryCharges > 0
                          ? order.deliveryCharges
                          : "Free"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <p className="b-text  text-14">Tax : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="3" className="text-right">
                      Total
                    </th>

                    <td colSpan="2" className="text-right">
                      <p className="b-text  text-14 text-right">
                        Rs{" "}
                        {roundTo1Digit(
                          getTotal(order.DrugOrderItems) + order.deliveryCharges
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>

                  <tr>
                    <th colSpan="0">Delivery Detail</th>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="b-text">{getReceipent(order).name}</p>
                    </td>
                    <td colSpan="2">
                      <p className="b-text text-right">
                        {getReceipent(order).phone}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="address">
                        House/Apartment : {order.apartment}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <p className="address">{order.address}</p>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="3">
                      <img
                        className="text-right"
                        style={{ width: "100px", float: "right" }}
                        src="/assets/img/brand/playstore.png"
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>

              {/* <div className="thead">
                            <ul>
                                <li>Item</li>
                                <li>Qty</li>
                                <li>Price</li>
                                <li>Amt</li>
                            </ul>
                        </div> */}
            </div>

            <div className="invoice-footer" />
          </div>
        </div>
      </div>
    )
  );
};

export default DrugInvoice;
