import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
  headers: { "x-access-token": Auth.token() },
};

const getMonthlyReportsByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.monthlyReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthylDrugReportByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.monthylDrugReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllMonthTotalSalesByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.allMonthTotalSalesByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthDrugSalesCountByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.monthDrugSalesCountByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMonthlySalesInCategoryByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.monthlySalesInCategoryByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmaciesMonthlyReportsByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.pharmaciesMonthlyReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmaciesMonthylDrugReportByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.pharmaciesMonthylDrugReportByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmaciesAllMonthTotalSalesByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.pharmaciesAllMonthTotalSalesByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmaciesMonthDrugSalesCountByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.pharmaciesMonthDrugSalesCountByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmaciesMonthlySalesInCategoryByYear = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.pharmacyReports.v3.pharmaciesMonthlySalesInCategoryByYear}/${monthId}/${year}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const PharmacyReportService = {
  getMonthlyReportsByYear,
  getMonthylDrugReportByYear,
  getAllMonthTotalSalesByYear,
  getMonthDrugSalesCountByYear,
  getMonthlySalesInCategoryByYear,

  getPharmaciesMonthlyReportsByYear,
  getPharmaciesMonthylDrugReportByYear,
  getPharmaciesAllMonthTotalSalesByYear,
  getPharmaciesMonthDrugSalesCountByYear,
  getPharmaciesMonthlySalesInCategoryByYear,
};
