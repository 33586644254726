import { Card, CardActionArea, CardContent, CardHeader, TextField,Checkbox, FormControlLabel, CardActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, LinearProgress } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import {makeStyles , useTheme}  from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import LoadingButton from '../../elements/LoadingButton'
import MAlert from '../../elements/MAlert'
import { DrugCategoryService } from '../../../services/DrugCategoryService'
import { DrugSubCategoryService } from '../../../services/DrugSubCategoryService'
const useStyle=  makeStyles((theme) => ({
    red : {
        color:'red'
    },
    box : {
        marginTop : theme.spacing(4)
    }
}))
const NewDrugSubCategory = ({editItemObj , setItemObj, ...rest}) => {
    const classes = useStyle()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(null)
    const [uploading, setUploading] = useState({
        isDisplay: false,
        progress: 0
    })
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const Schema = yup.object().shape({
        title : yup.string().required("Title is required"),
        description : yup.string(),
        status : yup.boolean(), 
        
    })

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(Schema),
        defaultValues : {status : true}
        
      });

    useEffect(() => {
        if(image!==null)
            setFile(URL.createObjectURL(image))
        else setFile(null)
    } , [image])


    const resetForm =() => {
        setLoading(false)
        setStatus(true)
        editItemObj.isEdit= false
        setCategoryId(null)
        reset({
            title:null,
            status:true
        },{
            errors: false,
            dirtyFields: false, 
            isDirty: false, 
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          })
    }

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        setUploading({
            ...uploading,
            progress :percent
        })
        if(percent===100){
            setTimeout(()=> {
                setUploading({
                    ...uploading,
                    isDisplay:false,
                    progress :0
                })
            }, 1000)
        }
        
    }

    const onSubmit = (data)=> {
        data.status= status
        data.DrugCategoryId = categoryId
        data.image= image

        
        
        if(editItemObj.isEdit){
            setLoading(true)
            setUploading({
                ...uploading,
                isDisplay:true
            })
            let item = {
                ...editItemObj.item,
                DrugCategoryId: categoryId,
                status:status,
                title:data.title,
                image : data.image,
                description:data.description
            }
            DrugSubCategoryService.edit(item, item.image===null , item.id , onUploadProgress).then(res=> {
                setLoading(false)
                resetForm()
                setAlert({
                    show:true,
                    message:res.message,
                    type:'success'
                })
                setItemObj({item:{...res.response,
                id: item.id}, isNew:false})
            }).catch(error => {
                setLoading(false)
                setUploading({
                    ...uploading,
                    percent:0,
                    isDisplay:false
                })
                setAlert({
                    show:true,
                    message:error.message,
                    type:'error'
                })
            })
        }else{
           
                setLoading(true)
                setUploading({
                    ...uploading,
                    isDisplay:true
                })
                DrugSubCategoryService.save(data , data.image===null , onUploadProgress).then(res=> {
                    setLoading(false)
                    resetForm()
                    setAlert({
                        show:true,
                        message:res.message,
                        type:'success'
                    })
                    setItemObj({item:res.response, isNew:true})
                }).catch(error => {
                    setLoading(false)
                    setUploading({
                        ...uploading,
                        percent:0,
                        isDisplay:false
                    })
                    setAlert({
                        show:true,
                        message:error.message,
                        type:'error'
                    })
                })
            
        }
       
    }

    useEffect(() => {
        if(editItemObj.isEdit){
            setValue('title', editItemObj.item?.title, { shouldValidate: true })
            setValue('description',editItemObj.item?.description)
            setCategoryId(editItemObj.item?.DrugCategoryId || null)
            setStatus(editItemObj.item?.status)
        }
    }, [editItemObj])

    useEffect(() => {
        DrugCategoryService.findAll().then(res => setCategories(res.response))
    },[])
    return <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
        <CardHeader title={editItemObj.isEdit? `Edit ${editItemObj.item?.title} Drug Sub Category` : `New Drug Sub Category`}/>
        <CardActionArea>
            <CardContent>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="category" >Select Drug Category</InputLabel>
                    <Select error={categoryId===null} name="category" value={categoryId} onChange={(event) => setCategoryId(event.target.value)}

                        required
                        labelId="demo-simple-select-outlined-label"
                        id="category"
                        name="category"
                        label="categories"
                    >
                        {categories.map(category=> <MenuItem value={category.id}>{category.title}</MenuItem>)}
                    </Select>
            <FormHelperText className={classes.red}>{categoryId===null ? `Category required` : ``}</FormHelperText>
            </FormControl>
            <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                helperText={errors.title?.message}
                autoFocus/>

                <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="description"/>

            <TextField
                type="file"
                variant="outlined"
                margin="normal"
                onChange={(event) => setImage(event.target.files[0])}
                fullWidth
                id="image"
                name="image"
                autoComplete="image"
               />
                            
            <FormControl>
                {file !==null ?  <img src={file} with="150" height="150"/> : ``}
            </FormControl>
            <br />
            <FormControlLabel
                control={<Checkbox checked={status} onChange={(event)=> setStatus(!status)} name="status" id="status" />}
                label="Status"
                />
            </CardContent>
        </CardActionArea>
        
        <CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => resetForm()}>
                Cancel
            </Button>
            <LoadingButton type="submit" loading={loading} size="small" variant="contained" color="primary">
                {editItemObj.isEdit ? `Edit` : `Save`}
            </LoadingButton>
        </CardActions>
            <LinearProgress style={{display : uploading.isDisplay ? 'block' :'none'}} variant="determinate" value={uploading.progress} />
        
    </Card>
    </form>
}

export default NewDrugSubCategory
