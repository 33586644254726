import { Card, CardContent, CardHeader ,Grid, useTheme} from '@material-ui/core'
import React , {useEffect , useState} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import { MartReportService } from '../../services/MartReportService'

const date=  new Date()
const currentDate = {
    name: moment(date).format("MMMM"), 
    id: date.getMonth()+1,
    year : date.getFullYear()
}
const UsersOrdersGraph = () => {
    const [selectedDate, setSelectedDate] = React.useState(date);
    const [month , setMonth] = useState(currentDate)
    const [loading ,setLoading] = useState(false)
    const [usersOrdersSeries , setUserOrderSeries] = useState([])
  const [userOrderOptions , setUserOrderOptions] = useState({
    chart: {
      type: 'bar',
      height: 350
    },
    title: {
      text: `Registered user & placed order in ${new Date().getFullYear()}` ,
      align: 'left',
      style: {
        fontSize: "16px",
        color: '#666'
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    yaxis: {
      title: {
        text: 'Total number of users/orders'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        }
      }
    }
  }) 

  useEffect(() => {
    MartReportService.getUsersAndOrdersByYear(month.year).then(res=> {
      setLoading(false)  
      let {orderReport , userReport}= res.response
      setUserOrderSeries([{
        name: 'Users',
        data: userReport.map(i => i.count)
      }, {
        name: 'Orders',
        data: orderReport.map(i => i.count)
      }])
  
      setUserOrderOptions({
        ...userOrderOptions,
        xaxis: {
          categories : orderReport.map(i=> i.monthName)
        }
      })
       
    })
}, [month])

useEffect(() => {
    setLoading(true)
    setMonth({
        name: moment(selectedDate).format("MMMM"), 
        id: selectedDate.getMonth()+1,
        year : selectedDate.getFullYear()
    })
} , [selectedDate])
  return <Card>
  <CardHeader action={
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
          <DatePicker
      variant="inline"
      openTo="year"
      views={["year",]}
      label="Year"
      value={selectedDate}
      onChange={(date) =>setSelectedDate(date)}
    />
          </Grid>
        </MuiPickersUtilsProvider>}/>
  <CardContent>
  <Chart options={userOrderOptions} series={usersOrdersSeries} type="bar" height={350} />
  </CardContent>
</Card>
}

export default UsersOrdersGraph
