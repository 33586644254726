import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';

const generateFromData = (category , id , cb) => {
	let formData = new FormData();
	id > -1 && formData.append('id' , id)
	formData.append('title', category.title);
	formData.append('categoryImage', category.categoryImage);
	formData.append('CategoryId', category.CategoryId);
	formData.append('status' , category.status)
	cb(formData)
}

const save = (category, onUploadProgress) => {
	return new Promise((resolve, reject) => {
	generateFromData(category , -1 , (formData) => {
		axios
			.post(Urls.subCategories.v3.save, formData, { headers: headers, onUploadProgress })
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error);
			});
	})


	});
};

const edit = (category , id , onUploadProgress) => {
	return new Promise((resolve, reject) => {
		generateFromData(category, id, (formData) => {
			axios
				.put(Urls.subCategories.v3.edit, formData, {headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	})
};

const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(Urls.subCategories.v3.findAll, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const findByCategoryId = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.subCategories.v3.findById}/${id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const deleteOne = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${Urls.subCategories.v3.delete}/${category.id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const changeStatus = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.subCategories.v3.changeStatus, category, { headers: headers })
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error);
			});


	});

}

const restoreItem = (item) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${Urls.subCategories.v3.restoreItem}`, item,{ headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

const findAllDeleted = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.subCategories.v3.allDeleted}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const SubCategoryService = {
	findAll: findAll,
	save: save,
	deleteOne: deleteOne,
	edit: edit,
	findByCategoryId: findByCategoryId,
	changeStatus : changeStatus,
	findAllDeleted,
	restoreItem

};
