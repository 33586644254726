import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Card, CardHeader, CardContent, TextField, CardActionArea, Button } from '@material-ui/core';
import { Auth } from '../../auth/Auth';
import MAlert from '../elements/MAlert';
import { LoginService } from '../../services/LoginService';

const StoreFormSchema = yup.object().shape({
	oldPassword: yup.string().required('Old password is required'),
	newPassword: yup.string().required('New password is required'),
	confirmPassword: yup.string().required('Confirm password number is required'),
	
});

const ChangePassword = () => {
    

    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(StoreFormSchema)
    });
    
    const onSubmit = (password) => {
        if(password.newPassword===password.confirmPassword){
            LoginService.changePassword({
                oldPassword: password.oldPassword ,
                newPassword: password.newPassword }).then(data => {
                    if(data.oldPasswordValid){
                        setAlert({
                            show:true,
                            message :data.message,
                            type:"error"
                        })
                    }else{
                        setAlert({
                            show:true,
                            message :"Password change successfully",
                            type:"success"
                        })
                       
                    }
            }).catch(error => {
                setAlert({
                    show:true,
                    message :"Can't change password. try again later.",
                    type:"error"
                })
            })
        }else{
            setAlert({
                show:true,
                message :"Confirm password does not match.",
                type:"error"
            })
        }
        
        
    }
	return (
		<form  onSubmit={handleSubmit(onSubmit)} noValidate>
			<Card>
                <MAlert alert={alert} setAlert={setAlert}/>
				<CardHeader title="Change Password" />
				<CardContent>
                    <TextField 
                    inputRef={register}
                    error={errors.oldPassword ? true : false}
                    helperText={errors.oldPassword?.message}
                    required fullWidth variant="outlined" label="Old Password" name="oldPassword" />
                    <TextField 
                    margin="normal"
                    inputRef={register}
                    error={errors.newPassword ? true : false}
                    helperText={errors.newPassword?.message}
                    required fullWidth variant="outlined" label="New Password" name="newPassword" />
                    <TextField 
                    margin="normal"
                    inputRef={register}
                    error={errors.confirmPassword ? true : false}
                    helperText={errors.confirmPassword?.message}
                    required fullWidth variant="outlined" label="Confirm Password" name="confirmPassword" />
                    
					<Button type="submit" variant="contained" color="primary">
						Change Password
					</Button>
				</CardContent>
			</Card>
		</form>
	);
};

export default ChangePassword;
