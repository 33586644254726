import { Avatar, Card, CardContent, CardHeader, CircularProgress, FormControl, IconButton, MenuItem, Typography,InputLabel,Select,Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,Button , TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { PrescriptionService } from '../../../services/PrescriptionService'
import {useTheme , makeStyles}  from '@material-ui/core/styles'
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import PrintIcon from '@material-ui/icons/Print';
import ForwardIcon from '@material-ui/icons/Forward';
import SortIcon from '@material-ui/icons/Sort';
import EditIcon from '@material-ui/icons/Edit';
import MAlert from '../../elements/MAlert'
import DeclinedDialog from '../../elements/DeclinedDialog'

const useStyles = makeStyles(theme => ({
    ordered : {
        backgroundColor : theme.palette.success.main,
        padding: '5px',
        borderRadius : '5px'
    },
    uploaded : {
        backgroundColor:theme.palette.secondary.main,
        padding: '5px',
        borderRadius : '5px'
    },
}))

const PrescriptionListTable = ({data, loading}) => {
    const classes= useStyles()
    const theme = useTheme()
    const history = useHistory();
    const [ alert, setAlert ] = React.useState({
		show: false,
		message: null,
		type: null
    });
    const [declinedDialog ,setDeclinedDialog]= React.useState({
        open: false,
        item: null
    })
   

    const handleStatusChange = (event , item) =>{
        const { name, value } = event.target;
        if(value==="Declined"){
            setDeclinedDialog({
                open:true,
                item : item
            })
            return
        }

        item.status = value
        onUpdatePrescription(item)
    }

    const onUpdatePrescription =(item) => {
        PrescriptionService.edit(item).then(res => {
            setAlert({
                show: true,
                message: res.message,
                type: 'success'
            });
        }).catch(error => {
            setAlert({
                show: true,
                message: error.message,
                type: 'error'
            });
        })
    }

  
    const onChangeOrderStatus = (data) => {
        let item = declinedDialog.item
        item.status = "Declined"
        item.declinedReason= data.reason
        onCloseDialog()
        onUpdatePrescription(item)
    }

    const onCloseDialog = () => {
        setDeclinedDialog({
            open: false,
            item :null
        })
    }


    const columns = [
        {
            name: 'Invoice No',
            selector: 'id',
            sortable:true,
            cell: (item) => <Typography variant="span"> INVOICE-#{item.id}</Typography> 
        },
        {
            name: "Recipient",
            sortable: true,
            cell : (item) => <>
                <span>{item.name}</span>
            </>
        },
        {
            name: "Recipient phone",
            sortable: true,
            cell : (item) => <>
                <span>{item.phone}</span>
            </>
		},
        {
            name: 'Status',
            selector: 'orderStatus',
            cell : (item) =>item.status!=="Ordered" ? <FormControl style={{width:'100%'}}>
            <InputLabel id="order-status">Prescription Status</InputLabel>
            <Select
            fullWidth
              labelId="order-status"
              id="orderStatus"
              value={item.status}
              onChange={(event)=> handleStatusChange(event, item)}
            >
             	<MenuItem value={'Uploaded'}>Uploaded</MenuItem>
				{/* <MenuItem value={'Pending'}>Pending</MenuItem> */}
				<MenuItem value={'Accepted'}>Accepted</MenuItem>
				<MenuItem value={'In-Progress'}>In-Progress</MenuItem>
				<MenuItem value={'Ordered'}>Ordered</MenuItem>
				<MenuItem value={'Declined'}>Declined</MenuItem>
            </Select>
          </FormControl>: <Typography  component="span" variant="span" className={getStatusClass(item.status)}> Order Closed</Typography>
          },
        {
            name: 'Date',
            selector: 'createdAt',
            cell : (item) => <Moment format="D MMM YYYY hh:mm a" withTitle>
                    {item.createdAt}
                </Moment>
        },
        {
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (item) => (
                <React.Fragment>
                    <IconButton onClick={() => 
                    history.push({ 
                        pathname: `/pharmacy/prescriptions/${item.id}`,
                        state: item
                    })
                  } size="small" component="span">
                        <PrintIcon />
                    </IconButton>
                   
                </React.Fragment>
            ),
            right: true
        }
    ]

    const getStatusClass = (orderStatus) =>{
        if(orderStatus==="Ordered" )
            return classes.ordered
        else
            return classes.uploaded
    }
    return <>
    <MAlert alert={alert} setAlert={setAlert} />
    <DataTable
    noHeader
    // progressPending 
    // progressComponent={<CircularProgress />}
    defaultSortAsc={false}
    keyField="id"
    theme={theme.palette.type}
    columns={columns}
    data={data}
    defaultSortField="id"
    sortIcon={<SortIcon />}
    progressPending={loading}
    progressComponent={<CircularProgress color="secondary"/>}
    pagination
    highlightOnHover
/>
<DeclinedDialog open = {declinedDialog.open} onClose= {onCloseDialog} onConfirm={onChangeOrderStatus} />
</>
}

export default PrescriptionListTable
