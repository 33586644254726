import React, { useEffect } from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Typography,
    Divider,
    ListItemSecondaryAction,
	Button,
	Snackbar
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { proxyPath } from '../../services/ApiPath';
import Moment from 'react-moment';
import { withRouter, useHistory } from 'react-router-dom';
import { Actions } from '../../reducers/actions';
import { NotificationService } from '../../services/NotificationService';
import { OrdersService } from '../../services/OrdersService';
import Alert from '@material-ui/lab/Alert';
import { createSelector } from 'reselect';
const useStyles = makeStyles((theme) => ({
	root: {
        width: 400,
        overflow: 'auto',
        maxHeight: 600,
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
	}
}));

const socketSelector = createSelector(state=> state.socket , socket =>socket)
const NotificationPanel = () => {
	const socket= useSelector(socketSelector)
	const history =useHistory()
    const classes = useStyles();
    const dispatch = useDispatch()
	const notifications = useSelector((state) => state.notifications);
	const orders = useSelector(state=> state.todayOrders)
	const [open, setOpen] = React.useState(false);

		const handleClick = () => {
			setOpen(true);
		};

		const handleClose = (event, reason) => {
			if (reason === 'clickaway') {
			return;
			}

			setOpen(false);
		};

	const changeOrderStatus =(order , notification) => {
		const { entityTypeId, id, entityId } = notification.notificationObject;
		order.orderStatus="Delivered"
		OrdersService.edit(order).then(res => {
			socket.emit('EVENT_ORDER' , order)
			dispatch(Actions.removeNotification(notification))
			NotificationService.edit(id,entityTypeId, entityId).then(data=> {
				console.log(data)
			})
            if(order.orderStatus==="Delivered" || order.orderStatus==="Declined"){
                dispatch(Actions.removeTodayOrder(order))
            }else{
                dispatch(Actions.editOrder(order))
                dispatch(Actions.editTodayOrder(order))
            }

            
        }).catch(error => {
           console.log(error)
        })
	}
    
	return (
		<React.Fragment>
			<Card>
			<CardHeader title="Recent Notification" />
			<CardContent>
				<List className={classes.root}>
					{notifications.map((notify) =>{
						const { entityTypeId, id, entityId } = notify.notificationObject;
						
                        return <React.Fragment key={notify.notificationObject.id}>
                            <ListItem alignItems="flex-start" button onClick={() => {
								if(entityTypeId>=1 && entityTypeId<=5){
									dispatch(Actions.removeNotification(notify))
									NotificationService.edit(id,entityTypeId, entityId).then(data=> {
										console.log(data)
									})
									
									history.push({
										pathname: `/orders/detail/${entityId}`,
										state: null
									})
									handleClick()
									
								}else if(entityTypeId>=10 && entityTypeId<=14){
									dispatch(Actions.removeNotification(notify))
									NotificationService.edit(id,entityTypeId, entityId).then(data=> {
										console.log(data)
									})
									
									history.push({
										pathname: `/pharmacy/orders/detail/${entityId}`,
										state: null
									})
									handleClick()
									
								}else if(entityTypeId===16){
									dispatch(Actions.removeNotification(notify))
									NotificationService.edit(id,entityTypeId, entityId).then(data=> {
										console.log(data)
									})
									
									history.push({
										pathname: `/pharmacy/prescriptions/${entityId}`,
										state: null
									})
									handleClick()
								}
                                
                            }}>
							<ListItemAvatar>
								<Avatar src={`${proxyPath}${notify.actor?.picUrl?.path}`} />
							</ListItemAvatar>
							<ListItemText
								primary={notify.actor?.name}
								secondary={
									<React.Fragment>
										
										{notify.message}
										{entityTypeId==7 ? <Button variant="outlined" color="primary" size="small" onClick={()=> {
											let order = orders.find(order=> order.id==entityId)
											if(order!==undefined)
												changeOrderStatus(order , notify)
										}}>Close Order</Button> : ''}
									</React.Fragment>
								}
							/>
                            <ListItemSecondaryAction><Moment fromNow ago>
									{notify.notificationObject.createdAt}
								</Moment></ListItemSecondaryAction>
						</ListItem>
                        <Divider/>
                      
                        </React.Fragment>
                    } )}
				</List>
			</CardContent>
		</Card>
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}>
			<Alert onClose={handleClose} severity="success">
				This order is cancel by user.
			</Alert>
      	</Snackbar>
		</React.Fragment>
	);
};

export default NotificationPanel;
