import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
	headers: {'x-access-token': Auth.token()}
  };
const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.employee.v3.findAll}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const save = (data , onUploadProgress) => {
	return new Promise((resolve, reject) => {
		generateFormData(data ,false, -1 , (formData) => {
			axios
				.post(Urls.employee.v3.save, formData, { headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	});
};

const edit = (data , isStatus,id , onUploadProgress ) => {
	return new Promise((resolve, reject) => {
		generateFormData(data,isStatus ,id , (formData) => {
			axios
				.put(Urls.employee.v3.edit, formData, { headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	});
};


const generateFormData = (formData ,isStatus ,id , cb) => {
	const data = new FormData();
	id>-1 && data.append('id', id);
	if(!isStatus){
		data.append('image', formData.image);
	}
	data.append('name', formData.name);
	data.append('designation', formData.designation);
	data.append('experience', formData.experience);
	data.append('about', formData.about)
	data.append('phone', formData.phone);
	data.append('email', formData.email);
	data.append('status', formData.status);
	data.append('timing', formData.timing);
	data.append('gender', formData.gender);
	data.append('AdminId', formData.AdminId);
	cb(data)
}

const deleteById = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${Urls.employee.v3.deleteById}/${id}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
export const EmployeeService = {
	findAll: findAll,
	save: save,
	edit,
	deleteById
}