import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from '../auth/Auth';

const PubilcRoutes = ({ component: Component, restricted, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (Auth.isLogin() && restricted ? <Redirect to="/" /> : <Component {...props} />)}
		/>
	);
};

export default PubilcRoutes;
