import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@material-ui/core";
import { QrDiscountService } from "../../services/QrDiscountService";
import MAlert from "../elements/MAlert";

const QrDiscount = () => {
  const [item, setItem] = useState({ id: 1, discount: 0 });
  const [isValid, setValid] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });

  useEffect(() => {
    QrDiscountService.findOne()
      .then((res) => {
        setItem(res.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onHandleChange = (e) => {
    const { name, value } = e.target;

    setItem({
      ...item,
      discount: value,
    });
  };

  useEffect(() => {
    setValid(isNumeric(item.discount));
  }, [item]);

  const isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  const edit = () => {
    item.discount = parseFloat(item.discount);
    QrDiscountService.edit(item)
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: "Can't edit qr discount amount.",
          type: "error",
        });
      });
  };

  return (
    <Card>
      <MAlert alert={alert} setAlert={setAlert} />
      <CardHeader title="Qr Discount percentage" />
      <CardContent>
        <TextField
          value={item.discount}
          onChange={onHandleChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Discount in Percentage"
          id="discount"
          name="discount"
          autoComplete="discount"
        />
        <Button
          onClick={() => edit()}
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isValid}
        >
          Edit
        </Button>
      </CardContent>
    </Card>
  );
};

export default QrDiscount;
