/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  makeStyles,
  Avatar,
  IconButton,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Badge,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { proxyPath } from "../../../services/ApiPath";
import CancelIcon from "@material-ui/icons/Cancel";
import MDialog from "../../elements/MDialog";
import placeHolderImage from "./../../../nj_placeholder.png";
import { getDiscountPrice } from "../../../utils/Utils";

const useStyle = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  priceBg: {
    marginTop: 5,
    backgroundColor: theme.palette.success.main,
    padding: 5,
    borderRadius: "5px",
  },
  price: {
    marginTop: "5px",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    color: "#fff",
    padding: "4px 10px 4px 10px",
  },
}));

const calcItemDiscountPrice = (item) => {
  return (item.price * item.discount) / 100;
};

const DrugOrderItem = ({ order, onOrderItemRemoved, ...rest }) => {
  const classes = useStyle();
  const [dialog, setDialog] = React.useState({ open: false, payload: null });

  const onRemove = (payload) => {
    onOrderItemRemoved(payload);
    onClose();
  };

  const onClose = () => {
    setDialog({
      open: false,
      payload: null,
    });
  };

  const packStripText = (data) => {
    let text = "";
    if (data[0] > 0) {
      text += `${data[0]} Packs`;
    }

    if (data[0] > 0 && data[1] > 0) {
      text += " & ";
    }

    if (data[1] > 0) {
      text += `${data[1]} Strips`;
    }

    return text;
  };

  const calculatePackStrip = (quantity, drug) => {
    let numberOfPacks = parseInt(quantity / drug.packSize);
    let numberStrips = quantity - numberOfPacks * drug.packSize;
    return [numberOfPacks, numberStrips];
  };

  const getQuantity = (item) => {
    let qty = 0;
    if (item.saleType === "Pack") {
      qty = item.quantity * item.Drug.stripSize;
    } else {
      qty = item.quantity * item.Drug.stripSize;
    }
    return qty;
  };

  const ciEquals = (a, b) => {
    return typeof a === "string" && typeof b === "string"
      ? a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
      : a === b;
  };

  const getPrice = (item) => {
    return item.price * item.quantity; //getQuantity(item)
  };

  const getPackOrStrip = (item) => {
    return `${item.quantity} ${item.saleType}`;
    // if(ciEquals(item.saleType, "Pack") || ciEquals(item.saleType , "Box"))
    //     return `${item.quantity} ${item.saleType}`
    // else
    //     return packStripText(calculatePackStrip(item.quantity , item.Drug))
  };

  const roundTo = (price) => {
    return Math.round(price * 100) / 100;
  };

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {order &&
          order?.DrugOrderItems?.map((item) => {
            return (
              <Grid item xs={12} sm={4}>
                <Card className={classes.root}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="cancel"
                        onClick={() =>
                          setDialog({
                            open: true,
                            payload: item,
                          })
                        }
                      >
                        <CancelIcon color="primary" />
                      </IconButton>
                    }
                    title={`${item?.Drug?.name} ${item?.Drug?.DrugType?.title} ${item?.Drug?.measurement}`}
                  />
                  {/* <CardMedia
                className={classes.media}
                image={`${proxyPath}${item?.Drug?.images[0]?.path}`}
                title="Paella dish"
            /> */}
                  <CardContent>
                    <Badge
                      color="secondary"
                      badgeContent={`${Math.round(item.Drug.discount)}%`}
                      invisible={item.Drug.discount > 0 ? false : true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <img
                        width="50"
                        height="60"
                        src={
                          item?.Drug?.images !== null &&
                          item?.Drug?.images.length > 0
                            ? `${proxyPath}${item?.Drug?.images[0]?.path}`
                            : placeHolderImage
                        }
                      />
                    </Badge>

                    {item?.content?.length > 0 ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ color: "red" }}
                      >
                        Note : {item?.content}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </CardContent>
                  <CardActions>
                    <Grid container>
                      <Grid item xs={12}>
                        <span className={classes.price}>
                          Rs{" "}
                          {roundTo(getDiscountPrice(item.price, item.discount))}{" "}
                          &nbsp;&nbsp;
                          {item.discount > 0 && (
                            <del> Rs {roundTo(item.price)} </del>
                          )}
                        </span>
                        <br />
                        <br /> {getPackOrStrip(item)}
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <MDialog
        open={dialog.open}
        payload={dialog.payload}
        onYes={onRemove}
        onClose={onClose}
      />
    </React.Fragment>
  );
};

export default DrugOrderItem;
