import React, { useEffect, useRef, useState } from "react";
import LoadingButton from "../../elements/LoadingButton";
import MAlert from "../../elements/MAlert";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  TextField,
  Checkbox,
  FormControlLabel,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  LinearProgress,
  Grid,
  FormLabel,
  RadioGroup,
  Radio,
  Badge,
  IconButton,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { DrugSubCategoryService } from "../../../services/DrugSubCategoryService";
import { DrugTypeService } from "../../../services/DrugTypeService";
import { DrugBrandService } from "../../../services/DrugBrandService";
import { AgeGroupService } from "../../../services/AgeGroupService";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberTextField from "./../../elements/NumberTextField";
import { DrugService } from "../../../services/DrugService";
import { useParams } from "react-router-dom";
import { proxyPath } from "../../../services/ApiPath";
import DeleteIcon from "@material-ui/icons/Delete";
import { DrugUnitService } from "../../../services/DrugUnitService";
import DrugSpecification from "./DrugSpecification";
import DrugSale from "./DrugSale";
import { Formik } from "formik";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyle = makeStyles((theme) => ({
  red: {
    color: "red",
  },
  box: {
    marginTop: theme.spacing(4),
  },
}));

let dataSpecifications = [];
let dataDrugSales = [];

const EditDrug = () => {
  const formik = useRef();
  const theme = useTheme();
  const classes = useStyle();
  const params = useParams();
  const [specifications, setSpecifications] = useState([]);
  const [drugSales, setDrugSales] = useState([]);
  const [item, setItem] = useState(null);
  const [types, setTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [gender, setGender] = useState("Male");
  const [selectedAgeGroups, setSelectedAgeGroups] = useState([]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [fixedSubCategory, setFixedSubCategory] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [isPrescription, setIsPrescription] = useState(false);
  const [status, setStatus] = useState(true);
  const [type, setType] = useState(null);
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });
  const [uploading, setUploading] = useState({
    isDisplay: false,
    progress: 0,
  });
  const [loading, setLoading] = useState(false);

  const Schema = yup.object().shape({
    name: yup.string().required("Medicine name is required"),
    formula: yup.string().required("Medicine formula is required"),
    description: yup.string().required("Medicine description is required"),
    measurement: yup.string(),
    price: yup
      .number()
      .positive("price must be greater than zero")
      .required("quantity is required"),
    salePrice: yup.number(),
    code: yup.number(),
    packSize: yup
      .number()
      .positive("Pack size must be greater than zero")
      .required("Pack size is required"),
    stripSize: yup
      .number()
      .positive("Strip size must be greater than zero")
      .required("Strip size is required"),
    manufactureDate: yup.date(),
    expiryDate: yup.date(),
    drugSales: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        title: yup.string().required("Drug sale type title is required"),
        price: yup.number().required("Drug price is required"),
        salePrice: yup
          .number()
          .positive("Sale price must be greater than zero"),
      })
    ),
    dataSpecifications: yup.array().of(
      yup.object().shape({
        id: yup.number(),
        title: yup.string().required("Title is required"),
        description: yup.string().required("Description is required"),
      })
    ),
  });

  const initialValues = {
    name: "",
    formula: "",
    description: "",
    measurement: "",
    price: 0,
    salePrice: 0,
    code: 0,
    packSize: 0,
    stripSize: 0,
    manufactureDate: new Date(),
    expiryDate: new Date(),
    drugSales: [],
    specifications: [],
  };

  useEffect(() => {
    DrugSubCategoryService.findAll().then((res) => {
      setFixedSubCategory([res.response[0], ...fixedSubCategory]);
      setSelectedSubCategories([
        ...[res.response[0]],
        ...selectedSubCategories,
      ]);
      setSubCategories(res.response);
    });
  }, []);

  console.log("Formik error", formik?.current?.errors);

  useEffect(() => {
    DrugService.findById(params.id).then((res) => {
      let drug = res.response;
      if (drug && drug !== null) {
        formik.current.setValues(
          {
            code: drug.code,
            measurement: drug.measurement,
            name: drug.name,
            formula: drug.formula,
            packSize: drug.packSize,
            stripSize: drug.stripSize,
            price: drug.price,
            description: drug.description,
            drugSales: drug.DrugSales.map((sale) => ({
              id: sale.id,
              price: sale.price,
              salePrice: sale.price - (sale.price * sale.discount) / 100,
              title: sale.title,
            })),
            specifications: drug.DrugSpecifications,
          },
          true
        );
        setGender(drug.gender);
        setIsPrescription(drug.isPrescription);
        setStatus(drug.status);
        setType(drug.DrugType.title);
        setSelectedBrands(drug.DrugBrands);
        setSelectedAgeGroups(drug.AgeGroups);
        setFixedSubCategory([drug.DrugSubCategories[0], ...fixedSubCategory]);
        setSelectedSubCategories([
          ...drug.DrugSubCategories,
          ...selectedSubCategories,
        ]);
        setItem(drug);
      }
      // if (drug !== null && drug !== undefined) {
      //   setValue("measurement", drug.measurement, { shouldValidate: true });
      //   setValue("name", drug.name, { shouldValidate: true });
      //   setValue("formula", drug.formula, { shouldValidate: true });
      //   setValue("price", drug.price, { shouldValidate: true });
      //   setValue("description", drug.description, { shouldValidate: true });
      //   setValue("code", drug.code);
      //   setValue("stripSize", drug.stripSize, { shouldValidate: true });
      //   setValue("packSize", drug.packSize, { shouldValidate: true });
      //   setValue("salePrice", getSalePrice(drug.price, drug.discount), {
      //     shouldValidate: true,
      //   });

      //   dataSpecifications = drug.DrugSpecifications.map((d, index) => ({
      //     ...d,
      //     index: index,
      //   }));

      //   dataDrugSales = drug.DrugSales.map((d, index) => ({
      //     ...d,
      //     index: index,
      //   }));
      //   setSpecifications(dataSpecifications);
      //   setDrugSales(dataDrugSales);
      //   setFixedSubCategory([drug.DrugSubCategories[0], ...fixedSubCategory]);
      //   setSelectedSubCategories([
      //     ...drug.DrugSubCategories,
      //     ...selectedSubCategories,
      //   ]);
      //   setItem(drug);
      // }
    });
  }, [params.id]);
  useEffect(() => {
    DrugTypeService.findAll().then((res) => setTypes(res.response));
  }, []);
  useEffect(() => {
    DrugBrandService.findAll().then((res) => setBrands(res.response));
  }, []);
  useEffect(() => {
    AgeGroupService.findAll().then((res) => setAgeGroups(res.response));
  }, []);

  const getSalePrice = (price, discount) => {
    return price - (price * discount) / 100;
  };

  useEffect(() => {
    let mFiles = [];
    for (var i = 0; i < images.length; i++)
      mFiles.push(URL.createObjectURL(images[i]));
    console.log(images);
    setFiles(mFiles);
    //setFiles(images.map(image=> URL.createObjectURL(image)))
  }, [images]);

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setUploading({
      ...uploading,
      progress: percent,
    });
    if (percent === 100) {
      setTimeout(() => {
        setUploading({
          ...uploading,
          isDisplay: false,
          progress: 0,
        });
      }, 1000);
    }
  };

  const onSubmit = (data) => {
    var discount = 0.0;
    data.drugSales = data.drugSales.map((sale) =>
      sale.hasOwnProperty("id")
        ? {
            id: sale.id,
            title: sale.title,
            price: sale.price,
            discount: ((sale.price - sale.salePrice) / sale.price) * 100,
          }
        : {
            title: sale.title,
            price: sale.price,
            discount: ((sale.price - sale.salePrice) / sale.price) * 100,
          }
    );
    // if (data.salePrice > 0 && data.salePrice <= data.price) {
    //   discount = ((data.price - data.salePrice) / data.price) * 100;
    // }

    let drug = {
      DrugTypeId: types.find(
        (t) => t.title.toLowerCase() === (type || "").toLowerCase()
      ).id,
      brands: selectedBrands,
      subCategories: selectedSubCategories,
      ageGroups: selectedAgeGroups,
      status: status,
      isPrescription: isPrescription,
      measurement: data.measurement,
      price: data.price,
      discount: discount,
      name: data.name,
      formula: data.formula,
      description: data.description,
      code: data.code,
      qtyPerUnit: data.qtyPerUnit,
      images: images,
      gender: gender,
      DrugSpecifications: data.specifications,
      DrugSales: data.drugSales,
      packSize: data.packSize,
      stripSize: data.stripSize,
      manufactureDate: data.manufactureDate,
      expiryDate: data.expiryDate,
    };

    DrugService.edit(drug, item.id, onUploadProgress)
      .then((res) => {
        setLoading(false);
        resetForm();
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        setUploading({
          ...uploading,
          percent: 0,
          isDisplay: false,
        });
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };

  const onRemoveDrugSaleLocal = (values, setValues, index) => {
    const drugSales = [...values.drugSales];
    const newDrugSales = drugSales
      .slice(0, index)
      .concat(drugSales.slice(index + 1, drugSales.length));
    setValues({ ...values, drugSales: newDrugSales }, false);
  };
  const addNewDrugSale = (values, setValues) => {
    setValues(
      {
        ...values,
        drugSales: [...values.drugSales, { title: "", price: 0, salePrice: 0 }],
      },
      false
    );
  };

  const onRemoveDrugSpecification = (values, setValues, index) => {
    const specifications = [...values.specifications];
    const newSpecifications = specifications
      .slice(0, index)
      .concat(specifications.slice(index + 1, specifications.length));
    setValues({ ...values, specifications: newSpecifications }, false);
  };
  const addNewDrugSpecification = (values, setValues) => {
    setValues(
      {
        ...values,
        specifications: [
          ...values.specifications,
          { title: "", description: "" },
        ],
      },
      false
    );
  };

  const deleteImage = (image) => {
    DrugService.removeImage({ id: item.id, images: [image] })
      .then((res) => {
        if (res.success) {
          item.images = item.images.filter((im) => im.path !== image.path);
          setItem(item);
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        } else {
          setAlert({
            show: true,
            message: res.message,
            type: "error",
          });
        }
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };

  const resetForm = () => {};

  const onRemoveBrand = (text) => {
    let brand = brands.find((brand) => brand.title === text);
    if (brand !== null && brand !== undefined) {
      let data = { drugId: item.id, brandId: brand.id };
      DrugService.removeBrand(data)
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    }
  };

  const onRemoveAgeGroup = (text) => {
    let ageGroup = ageGroups.find((age) => age.groupName === text);
    if (ageGroup !== null && ageGroup !== undefined) {
      let data = { drugId: item.id, ageGroupId: ageGroup.id };
      DrugService.removeAgeGroup(data)
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    }
  };

  const onRemoveCategory = (text) => {
    let category = subCategories.find((cat) => cat.title === text);
    if (category !== null && category !== undefined) {
      let data = { drugId: item.id, subCategoryId: category.id };
      DrugService.removeCategory(data)
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    }
  };

  const onRemoveSpecification = (values, setValues, index) => {
    let specification = formik.current.values.specifications[index];
    if (
      specification !== null &&
      specification !== undefined &&
      specification.hasOwnProperty("id")
    ) {
      DrugService.removeSpecification(specification.id)
        .then((res) => {
          onRemoveDrugSpecification(values, setValues, index);
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    } else {
      onRemoveDrugSpecification(values, setValues, index);
    }
  };

  const onRemoveDrugSale = (values, setValues, index) => {
    let drugSale = formik.current.values.drugSales[index];
    if (
      drugSale !== null &&
      drugSale !== undefined &&
      drugSale.hasOwnProperty("id")
    ) {
      DrugService.removeDrugSale(drugSale.id)
        .then((res) => {
          onRemoveDrugSaleLocal(values, setValues, index);
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
        });
    } else {
      onRemoveDrugSaleLocal(values, setValues, index);
    }
  };

  const onDrugSaleChange = (index, event) => {
    const { name, value } = event.target;
    dataDrugSales = dataDrugSales.map((sp) =>
      sp.index == index
        ? {
            ...sp,
            [name]: value,
          }
        : sp
    );
  };

  return (
    <Formik
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {(props) => {
        const {
          errors,
          values,
          touched,
          setValues,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
        } = props;

        console.log(errors);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Card>
              <MAlert alert={alert} setAlert={setAlert} />
              <CardHeader title={`Edit ${item?.name}`} />
              <CardContent>
                <Grid spacing={4} container>
                  <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          value={values.code}
                          onChange={handleChange}
                          variant="outlined"
                          margin="normal"
                          required
                          id="code"
                          label="Code"
                          name="code"
                          autoComplete="code"
                          focused
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          value={values.measurement}
                          onChange={handleChange}
                          helperText={errors.measurement?.message}
                          error={errors.measurement ? true : false}
                          variant="outlined"
                          margin="normal"
                          required
                          id="measurement"
                          label="Measurement"
                          name="measurement"
                          autoComplete="measurement"
                          focused
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      error={errors.name && touched.name ? true : false}
                      variant="outlined"
                      margin="dense"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      id="name"
                      label="Medicine Name"
                      name="name"
                      autoComplete="name"
                      helperText={errors.name}
                      focused
                    />

                    <TextField
                      error={errors.formula && touched.formula ? true : false}
                      variant="outlined"
                      margin="normal"
                      value={values.formula}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      id="formula"
                      label="Medicine formula"
                      name="formula"
                      autoComplete="formula"
                      helperText={errors.formula}
                      focused
                    />

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          helperText={errors.packSize}
                          error={
                            errors.packSize && touched.packSize ? true : false
                          }
                          fullWidth
                          value={values.packSize}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          margin="normal"
                          required
                          id="packSize"
                          label="Pack Size"
                          name="packSize"
                          autoComplete="packSize"
                          focused
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          value={values.stripSize}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={errors.stripSize}
                          error={
                            errors.stripSize && touched.stripSize ? true : false
                          }
                          variant="outlined"
                          margin="normal"
                          required
                          id="stripSize"
                          label="Strip Size"
                          name="stripSize"
                          autoComplete="stripSize"
                          focused
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      focused
                      error={errors.price && touched.price ? true : false}
                      helperText={errors.price}
                      variant="outlined"
                      margin="normal"
                      value={values.price}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      label="Price/Tablet/Syrup"
                      id="price"
                      name="price"
                      autoComplete="price"
                    />
                    {/* <TextField
                      focused
                      error={errors.salePrice ? true : false}
                      helperText={errors.salePrice?.message}
                      variant="outlined"
                      margin="normal"
                      inputRef={register}
                      required
                      fullWidth
                      label="Sale Price"
                      id="salePrice"
                      name="salePrice"
                      autoComplete="salePrice"
                    /> */}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={status}
                          onChange={(event) => setStatus(!status)}
                          name="status"
                          id="status"
                        />
                      }
                      label="Status"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPrescription}
                          onChange={(event) =>
                            setIsPrescription(!isPrescription)
                          }
                          name="isPrescription"
                          id="isPrescription"
                        />
                      }
                      label="Medicine requires prescription "
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="type">Drug Type</InputLabel>
                      <Select
                        error={type === null}
                        name="type"
                        value={type}
                        onChange={(event) => setType(event.target.value)}
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="type"
                        name="type"
                        label="type"
                      >
                        {types.map((type) => (
                          <MenuItem value={type.title}>{type.title}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText className={classes.red}>
                        {type === null ? `Select drug type required` : ``}
                      </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth margin="normal" variant="outlined">
                      <Autocomplete
                        multiple
                        id="fixed-tags-demo"
                        value={selectedBrands}
                        onChange={(event, newValue, reason) => {
                          if (reason === "remove-option") {
                            let text =
                              event.currentTarget.parentElement.innerText;
                            onRemoveBrand(text);
                          }
                          setSelectedBrands([
                            ...newValue.filter((option) => option),
                          ]);
                        }}
                        options={brands}
                        getOptionLabel={(option) => option.title}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.title}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select brands"
                            variant="outlined"
                            placeholder="Brands"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth margin="normal" variant="outlined">
                      <Autocomplete
                        multiple
                        id="fixed-tags-demo"
                        value={selectedSubCategories}
                        onChange={(event, newValue, reason) => {
                          if (reason === "remove-option") {
                            let text =
                              event.currentTarget.parentElement.innerText;
                            onRemoveCategory(text);
                          }
                          setSelectedSubCategories([
                            ...fixedSubCategory,
                            ...newValue.filter(
                              (option) =>
                                fixedSubCategory.indexOf(option) === -1
                            ),
                          ]);
                        }}
                        options={subCategories}
                        getOptionLabel={(option) => option.title}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.title}
                              {...getTagProps({ index })}
                              disabled={fixedSubCategory.indexOf(option) !== -1}
                            />
                          ))
                        }
                        style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add in categories"
                            variant="outlined"
                            placeholder="Category"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl fullWidth margin="normal" variant="outlined">
                      <Autocomplete
                        multiple
                        id="fixed-tags-demo"
                        value={selectedAgeGroups}
                        onChange={(event, newValue, reason) => {
                          if (reason === "remove-option") {
                            let text =
                              event.currentTarget.parentElement.innerText;
                            onRemoveAgeGroup(text);
                          }
                          setSelectedAgeGroups([
                            ...newValue.filter((option) => option),
                          ]);
                        }}
                        options={ageGroups}
                        getOptionLabel={(option) => option.groupName}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.groupName}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select age groups"
                            variant="outlined"
                            placeholder="Age Group"
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gender</FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                      >
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="Both"
                          control={<Radio />}
                          label="Both"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      focused
                      multiline
                      rows={4}
                      error={
                        errors.description && touched.description ? true : false
                      }
                      variant="outlined"
                      margin="normal"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete="description"
                      helperText={errors.description}
                    />

                    <input
                      type="file"
                      name="images"
                      id="images"
                      onChange={(event) => setImages(event.target.files)}
                      multiple
                    />
                    <br />
                    <br />
                    <Grid container>
                      {files.map((file) => (
                        <Grid item xs={12} sm={12} md={4}>
                          <img src={file} width="100" />
                        </Grid>
                      ))}

                      {item !== null
                        ? item.images.map((image) => (
                            <Grid item xs={12} sm={12} md={4}>
                              <Badge
                                onClick={() => deleteImage(image)}
                                color="primary"
                                badgeContent={<DeleteIcon />}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <img
                                  width="100"
                                  src={`${proxyPath}${image?.path}`}
                                />
                              </Badge>
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => addNewDrugSale(values, setValues)}
                  >
                    Add Drug Sale Unit
                  </Button>
                  <Grid container spacing={2}>
                    {values.drugSales.map((drugSale, index) => {
                      const err =
                        (errors.drugSales?.length && errors.drugSales[index]) ||
                        {};
                      const touch =
                        (touched.drugSales?.length &&
                          touched.drugSales[index]) ||
                        {};

                      const name = `drugSales.${index}`;
                      return (
                        <Grid item xs={12} sm={12} md={3}>
                          <Card>
                            <CardHeader
                              action={
                                <IconButton
                                  onClick={(event) =>
                                    onRemoveDrugSale(values, setValues, index)
                                  }
                                  aria-controls="filter-menu"
                                  aria-haspopup="true"
                                >
                                  <CancelIcon />
                                </IconButton>
                              }
                            />
                            <CardContent>
                              <TextField
                                onChange={handleChange(`${name}.title`)}
                                value={drugSale.title}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={`title`}
                                label="Title"
                                placeholder="Sale Type"
                                name={`title`}
                                autoComplete={`title`}
                                error={err.title && touch.title ? true : false}
                                helperText={err.title}
                              />
                              <TextField
                                onChange={handleChange(`${name}.price`)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                placeholder="Price"
                                id={`price`}
                                label="Price"
                                name={`price`}
                                autoComplete={`Price`}
                                value={drugSale.price}
                                error={err.price && touch.price ? true : false}
                                helperText={err.price}
                              />
                              <TextField
                                onChange={handleChange(`${name}.salePrice`)}
                                value={drugSale.salePrice}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                placeholder="Sale Price"
                                id={`salePrice`}
                                label="Sale Price"
                                name={`salePrice`}
                                autoComplete={`salePrice`}
                                helperText={err.salePrice}
                                error={
                                  err.salePrice && touch.salePrice
                                    ? true
                                    : false
                                }
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <br />
                  <br />

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => addNewDrugSpecification(values, setValues)}
                  >
                    Add Drug Specification
                  </Button>
                  <Grid container spacing={2}>
                    {values.specifications.map((specification, index) => {
                      const err =
                        (errors.specifications?.length &&
                          errors.specifications[index]) ||
                        {};
                      const touch =
                        (touched.specifications?.length &&
                          touched.specifications[index]) ||
                        {};

                      const name = `specifications.${index}`;
                      return (
                        <Grid item xs={12} sm={12} md={3}>
                          <Card>
                            <CardHeader
                              action={
                                <IconButton
                                  onClick={(event) =>
                                    onRemoveSpecification(
                                      values,
                                      setValues,
                                      index
                                    )
                                  }
                                  aria-controls="filter-menu"
                                  aria-haspopup="true"
                                >
                                  <CancelIcon />
                                </IconButton>
                              }
                            />
                            <CardContent>
                              <TextField
                                onChange={handleChange(`${name}.title`)}
                                value={specification.title}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id={`title`}
                                label="Title"
                                placeholder="Title"
                                name={`title`}
                                autoComplete={`title`}
                                error={err.title && touch.title ? true : false}
                                helperText={err.title}
                              />
                              <TextField
                                multiline
                                rows={2}
                                onChange={handleChange(`${name}.description`)}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                placeholder="Description"
                                id={`description`}
                                label="Description"
                                name={`description`}
                                autoComplete={`description`}
                                value={specification.description}
                                error={
                                  err.description && touch.description
                                    ? true
                                    : false
                                }
                                helperText={err.description}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="reset"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  Edit
                </Button>
              </CardActions>
              <LinearProgress
                style={{ display: uploading.isDisplay ? "block" : "none" }}
                variant="determinate"
                value={uploading.progress}
              />
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditDrug;
