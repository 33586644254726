import { Card, CardActionArea, CardContent, CardHeader, TextField,Checkbox, FormControlLabel, CardActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, LinearProgress } from '@material-ui/core'

import {makeStyles , useTheme}  from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import LoadingButton from '../../elements/LoadingButton'
import MAlert from '../../elements/MAlert'
import { SignalCellularNull } from '@material-ui/icons'
import { DrugBannerService } from '../../../services/DrugBannerService'
const useStyle=  makeStyles((theme) => ({
    red : {
        color:'red'
    },
    box : {
        marginTop : theme.spacing(4)
    }
}))
const bannerTypes = ["Small" , "Large"]
const NewDrugBanner = ({setItemObj, ...rest}) => {
    const classes = useStyle()
    const [loading, setLoading] = useState(false)
    const [type , setType] = useState(bannerTypes[0])
    const [image , setImage] = useState(null)
    const [uploading, setUploading] = useState({
        isDisplay: false,
        progress: 0
    })
    const [file, setFile] = useState(null)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })




    const resetForm =() => {
        setLoading(false)
        setFile(null)
        setImage(null)
    }

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        setUploading({
            ...uploading,
            progress :percent
        })
        if(percent===100){
            setTimeout(()=> {
                setUploading({
                    ...uploading,
                    isDisplay:false,
                    progress :0
                })
            }, 1000)
        }
        
    }

    const onSubmit = (event)=> {
        event.preventDefault()
        let data = {}
        data.type=type
        data.image= image
        if(file!==null){
            setLoading(true)
            setUploading({
                ...uploading,
                isDisplay:true
            })
            DrugBannerService.save(data, onUploadProgress).then(res=> {
                setLoading(false)
                resetForm()
                setAlert({
                    show:true,
                    message:res.message,
                    type:'success'
                })
                setItemObj({item:res.response, isNew:true})
            }).catch(error => {
                setLoading(false)
                setUploading({
                    ...uploading,
                    percent:0,
                    isDisplay:false
                })
                setAlert({
                    show:true,
                    message:error.message,
                    type:'error'
                })
            })
        }
       
    }


    return <form noValidate onSubmit={onSubmit}>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
        <CardHeader title={`New Drug Banner`}/>
        <CardActionArea>
            <CardContent>
           
            <FormControl fullWidth variant="outlined">
                <InputLabel id="type" >Banner Type</InputLabel>
                    <Select name="type" value={type} onChange={(event) => setType(event.target.value)}

                        required
                        labelId="demo-simple-select-outlined-label"
                        id="type"
                        name="type"
                        label="type"
                    >
                        {bannerTypes.map(type=> <MenuItem value={type}>{type}</MenuItem>)}
                </Select>
            </FormControl>

            <TextField

                type="file"
                error={file===null ? true : false}
                variant="outlined"
                margin="normal"
                onChange={(event) => {
                    let files = event.target.files
                    if(files!==null && files.length > 0){
                        setImage(files[0])
                        setFile(URL.createObjectURL(files[0]))
                    }
                     else setFile(null)
                }}
                required
                fullWidth
                id="image"
                name="image"
                autoComplete="image"
                helperText={file===null ? `Drug banner image is required.` : ``}/>
                            
            <FormControl>
                {file !==null ?  <img src={file} with="150" height="150"/> : ``}
            </FormControl>
           
           
            </CardContent>
        </CardActionArea>
        
        <CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => resetForm()}>
                Cancel
            </Button>
            <LoadingButton type="submit" loading={loading} size="small" variant="contained" color="primary" disabled={file===null}>
               Upload
            </LoadingButton>
        </CardActions>
            <LinearProgress style={{display : uploading.isDisplay ? 'block' :'none'}} variant="determinate" value={uploading.progress} />
        
    </Card>
    </form>
}

export default NewDrugBanner
