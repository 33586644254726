import axios from 'axios';
import { Urls } from './ApiPath';
import { headers } from './TokenHeader';
import { Auth } from '../auth/Auth';
import { useSelector } from 'react-redux';

const adminLogin = (admin) => {
	console.log(headers)
	return new Promise((resolve, reject) => {
		axios
			.post(Urls.Auth.v3.login, admin)
			.then((res) => {
				const { success, response, accessToken } = res.data;
				if (success) {
					Auth.login({
						login: true,
						token: accessToken
					});
					//subscribeUser()
				}
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

const findById = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(Urls.Auth.v3.findById, { headers: headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(Urls.Auth.v3.findAll, { headers: headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

const findAllStores = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(Urls.Auth.v3.allStores, { headers: headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

const edit = (storeInfo) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.Auth.v3.edit, storeInfo, { headers: headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

const changePassword = (password) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.Auth.v3.changePassword, password, { headers: headers })
			.then((res) => {
				resolve(res.data);
			})
			.catch((error) => reject(error));
	});
};

export const LoginService = {
	adminLogin: adminLogin,
	findById: findById,
	edit: edit,
	changePassword: changePassword,
	findAll,
	findAllStores
};
