import React from 'react';
import { Grid } from '@material-ui/core';
import StoreInfo from './StoreInfo';
import ChangePassword from './ChangePassword';

const Profile = () => {
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={6}>
				<StoreInfo />
			</Grid>
			<Grid item xs={12} sm={6}>
				<ChangePassword />
			</Grid>
		</Grid>
	);
};

export default Profile;
