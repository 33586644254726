import React, { useEffect } from 'react'
import { Card, CardHeader, TextField, Switch, CardContent, Button, Grid, Collapse, IconButton, FormControlLabel, LinearProgress, Box } from '@material-ui/core'
import {makeStyles}  from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { CategoryService } from '../../services/CategoryService'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import {useSelector , useDispatch} from 'react-redux'
import { Actions } from '../../reducers/actions';
import MAlert from '../elements/MAlert'
import { createSelector } from 'reselect'

const useStyle = makeStyles(theme => ({
    box : {
        marginTop : theme.spacing(4)
    }
}))

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png"
];
const socketSelector = createSelector(state=> state.socket , socket=> socket)
const NewCategory = ({editItemObj , setItemObj ,...rest}) => {
    const socket = useSelector(socketSelector)
    const classes = useStyle()
    const [progress, setProgress] = React.useState(0);
    const [showProgress , setShowProgress]= React.useState(false)
    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        setProgress(percent)
        if(percent===100){
            setTimeout(()=> {
                setProgress(0)
                setShowProgress(false)
            }, 1000)
        }
        
    }
    
    const CategorySchema = yup.object().shape({
        title : yup.string().trim("The category title cannot include leading and trailing spaces ").required(),
        description : yup.string().required(),
        status : yup.boolean(),
        categoryImage:yup
        .mixed()
        .required("Category image is required")
        
    })

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(CategorySchema),
        defaultValues : {status : true}
        
      });

   

    //const [status , setStatus] = React.useState(true)
    

   useEffect(() => {
    if(editItemObj.isEdit){

        setValue('title', editItemObj.item?.title, { shouldValidate: true })
        setValue('description', editItemObj.item?.description, { shouldValidate: true })
    }
   } , [editItemObj])
   
    const resetForm = () => {
        editItemObj.isEdit = false
        setShowProgress(false)
        reset({
            title: null,
            description: null,
            categoryImage : null
          }, {
            errors: true, // errors will not be reset 
            dirtyFields: true, // dirtyFields will not be reset
            isDirty: true, // dirty will not be reset
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          });
    }

    const onSubmit = (data) => {
        data.categoryImage = data.categoryImage[0]
        setShowProgress(true)
        //data.status= status
        if(editItemObj.isEdit){
            CategoryService.edit(data,editItemObj.item.id).then(res=>{
                socket.emit('EVENT_CATEGORY', res.response)
                setItemObj({item : res.response, isNew:false})
        
                resetForm()
                setAlert({
                    show:true,
                    message : res.message,
                    type:'success'
                })
                
            }).catch(error=> {
                setAlert({
                    show:true,
                    message : "Can't edit category.",
                    type:'error'
                })
            })
            return
        }else{
            CategoryService.save(data , onUploadProgress).then(res=> {
               setItemObj({item : res.response, isNew:true})
                resetForm()
                setAlert({
                    show:true,
                    message : res.message,
                    type:'success'
                })
                
            }).catch(error=> {
                setAlert({
                    show:true,
                    message : "Can't insert new category",
                    type:'error'
                })
            })
        }
       
        
    }
    return <form noValidate onSubmit={handleSubmit(onSubmit)} >
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
            <CardHeader title="New Category" action={
             <FormControlLabel
                 control={<Switch checked name="status" inputRef={register}  inputProps={{'name' : 'status'}}/>}
           />
            }/>
            <CardContent>
            
            <TextField
                                error={errors.title ? true : false}
                                variant="outlined"
                                margin="normal"
                                inputRef={register}
                                required
                                fullWidth
                                id="title"
                                label="Title"
                                name="title"
                                autoComplete="title"
                                helperText={errors.title?.message}
                                autoFocus
                            />
                            
                            <TextField
                            
                                multiline
                                rows={4}
                                error={errors.description ? true : false}
                                variant="outlined"
                                margin="normal"
                                inputRef={register}
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                autoComplete="description"
                                helperText={errors.description?.message}
                                
                            />
                            
                            <TextField
                                type="file"
                                error={errors.categoryImage ? true : false}
                                variant="outlined"
                                margin="normal"
                                inputRef={register}
                                required
                                fullWidth
                                id="categoryImage"
                                name="categoryImage"
                                autoComplete="categoryImage"
                                helperText={errors.categoryImage?.message}
                                
                            />
                            
                          
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button type="reset" fullWidth variant="contained" color="primary" onClick={() =>resetForm()}>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button type="submit" fullWidth variant="contained" color="primary" >
                                    {editItemObj.isEdit ? 'Edit' : 'Save'}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Box width="100%" className={classes.box} mr={1} style={{display : showProgress ? 'block' :'none'}}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>

                        
            </CardContent>
        </Card>
    </form>
}

export default NewCategory
