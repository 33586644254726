import React ,{useState, useEffect, Fragment} from 'react'

import {useTheme , makeStyles}  from '@material-ui/core/styles'
import { Card, Checkbox, Switch, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Collapse, CardContent, CardHeader, Menu, FormControlLabel, CircularProgress } from '@material-ui/core'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import SortIcon from '@material-ui/icons/Sort';
import { proxyPath } from '../../services/ApiPath';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close'
import {useSelector , useDispatch} from 'react-redux'
import { Actions } from '../../reducers/actions';
import { SubCategoryService } from '../../services/SubCategoryService';
import { createSelector } from 'reselect';
import MAlert from '../elements/MAlert';
//import CategoryActions from './../../reducers/category'
const socketSelector = createSelector(state=> state.socket , socket=> socket)
const useStyle = makeStyles(theme => ({
    root: {
        width: 400,
        overflow: 'auto',
        maxHeight: 600,
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
	}
}))

const SubCategoryList = (props) => {
    const classes= useStyle()
    const socket=useSelector(socketSelector)
    const [filter , setFilter] = React.useState({
        statusAll : true,
        status : false
    })
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [subCategories, setSubCategories] = useState([])
    const [filterSubCategories , setFilterSubCategories] = useState([])
    const [loading , setLoading] = useState(true)
    const editItem = useSelector(state=> state.editItem)
    const dispatch = useDispatch()
    const [state , setState] = React.useState(false)
    const theme= useTheme()
    const [open , setOpen] = React.useState(false)
    const [currentCat ,setCurrentCat] = React.useState(null)
    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

    useEffect(() => {
        setFilterSubCategories(filter.statusAll && !filter.status ? subCategories : subCategories.filter(cat=> cat.status==filter.status))
    }, [filter])

    useEffect(() => {
        const {item , isNew} = props.itemObj
        if(isNew)
            onInsertItem(item)
        else
            onItemUpdate(item)
    } , [props.itemObj])

    useEffect(() => {
        SubCategoryService.findAll().then((data) => {
            setSubCategories(data.response)
            setFilterSubCategories(data.response)
            setLoading(false)
		}).catch(error=> setLoading(false));
    } ,[])
    const columns = [
        {
            name: 'Image',
            selector: 'image.path',
            cell: ({image}) => <Avatar src={`${proxyPath}${image?.path}`} />
        },
        {
            name: "Title",
            selector: 'title',
            sortable: true
        },
        {
            name: "Category",
            selector: 'Category.title',
            sortable: true
        },
        {
            name: "Status",
            selector: 'status',
            cell: (category ) => <Switch
            checked={category.status}
            onChange={(e)=> handleCheckChange(e,category)}
            name="status"
            inputProps={{ 'aria-label': 'staus checkbox' }}
          />
            
        },{
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (category) => (
                <React.Fragment>
                    <IconButton onClick={() => props.setEditItemObj({item:category, isEdit:true})} size="small" component="span">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(category)} size="small" component="span" style={{display:'none'}}>
                        <DeleteIcon />
                    </IconButton>
                </React.Fragment>
            ),
            right: true
        }
    ]
    const handleCheckChange = (event,category)=> {
        category.status=!category.status
        SubCategoryService.changeStatus(category).then(res=> {
            socket.emit("EVENT_SUB_CATEGORY" , res.response)
            onItemUpdate(res.response)
            setAlert({
                show:true,
                message : res.message,
                type:'success'
            })
            
        }).catch(error=> {
            setAlert({
                show:true,
                message : "Can't change category status.Something went wrong",
                type:'error'
            })
        })
    }

    const onItemUpdate = (item) => {
        setSubCategories(subCategories.map(cat => cat.id===item.id ? item : cat))
        setFilterSubCategories(filterSubCategories.map(cat => cat.id===item.id ? item : cat))
    }

    const onInsertItem = (item) => {
        setSubCategories([item, ...subCategories])
        setFilterSubCategories([item,...filterSubCategories])
    }

    

   

    
    
    const onDeleteClick = (category) => {
        setCurrentCat(category)
        setOpen(true)
    };

    const deleteItem = ()=> {
        SubCategoryService.deleteOne(currentCat).then(res => {
            dispatch(Actions.removeSubCategory(currentCat))
            setOpen(!open)
            setAlert({
                show: true,
                message: res.message,
                type:"success"
            })
            
        }).catch(error=> {
            setAlert({
                show: true,
                message: "Can't delete category item.Something went wrong",
                type:"error"
            })
        })
    }

    const tableData= {
        columns : columns,
        data : filterSubCategories
    }
    return (
        <Fragment>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
                        <CardHeader title="Sub Categories" action=
                        {
                        <React.Fragment>
                            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}
                            aria-controls="filter-menu"
                            aria-haspopup="true">
                            <SortIcon/>
                        </IconButton>
                       
                         <Menu  id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                         <Card>
                            <CardHeader title="Filter Categories" />
                            <CardContent className={classes.root}>
                            <FormControlLabel
                                control={<Checkbox checked={filter.statusAll} onChange={(event)=> setFilter({
                                    ...filter,
                                    statusAll:!filter.statusAll
                                })} name="statusAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filter.status} onChange={(event)=> setFilter({
                                    ...filter,
                                    status:!filter.status
                                })} name="status" />}
                                label="Show By Status"
                            />
                            </CardContent>
                            </Card>
                        </Menu>
                        </React.Fragment>
                        
                        }>
                        
                    </CardHeader>
            <CardContent>
                <DataTableExtensions {...tableData} filterPlaceholder="Filter Category">
                
                <DataTable
                noHeader
                    keyField="id"
                    theme={theme.palette.type}
                    title="Sub Categories"
                    columns={columns}
                    data={filterSubCategories}
                    progressComponent={<CircularProgress color="secondary"/>}
                    progressPending={loading}
                    defaultSortField="title"
                    sortIcon={<SortIcon />}
                    pagination
                    highlightOnHover
                />
                </DataTableExtensions>
            </CardContent>
        </Card>
        <Dialog
            open={open}
            onClose={()=> setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete {currentCat?.title} cateogory?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=> setOpen(!open)} color="secondary">
                Not Sure
            </Button>
            <Button onClick={()=> deleteItem()} color="secondary" autoFocus>
                Yes, Delete
            </Button>
            </DialogActions>
        </Dialog>
        </Fragment>
    )
}

export default SubCategoryList
