import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findAll = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(Urls.products.v3.findAll, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findAllByPage = (search, page, perPage, status, discount) => {
  let url =
    search && search !== ""
      ? `${Urls.products.v3.findAll}?search=${search}&page=${page}&per_page=${perPage}&status=${status}`
      : `${Urls.products.v3.findAll}?page=${page}&per_page=${perPage}&status=${status}`;
  if (discount) url += `&discount=${discount}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.products.v3.findById}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteOne = (item) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Urls.products.v3.delete}/${item.id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const save = (product, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    generateFormData(product, -1, (formData) => {
      axios
        .post(Urls.products.v3.save, formData, {
          headers: headers,
          onUploadProgress,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const updateProductXlsx = (files, onUploadProgress) => {
  let formData = new FormData();
  if (files.length > 0) {
    for (const key of Object.keys(files)) {
      formData.append("xlsx", files[key]);
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.products.v3.xlsx, formData, {
        headers: headers,
        onUploadProgress,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const exportProductXlsx = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(Urls.products.v3.xlsxExport, {
        headers: {
          ...headers,
        },
      })
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};

const changeStatus = (product) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.products.v3.changeStatus, product, { headers: headers })
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const generateFormData = (product, id, cb) => {
  let formData = new FormData();
  id > -1 && formData.append("id", id);
  formData.append("title", product.title);

  if ((product.productImages || []).length > 0) {
    for (const key of Object.keys(product.productImages)) {
      formData.append("productImages", product.productImages[key]);
    }
  }
  formData.append("status", product.status);
  //formData.append('productImages' , product.productImages)
  formData.append("ProductCategoryId", product.ProductCategoryId);
  formData.append("CategoryId", product.CategoryId);
  formData.append("SubCategoryId", product.SubCategoryId);
  formData.append("quantity", product.quantity);
  formData.append("discount", product.discount);
  formData.append("qtyPerUnit", product.qtyPerUnit);
  formData.append("price", product.price);
  formData.append("description", product.description);
  formData.append("keyFeatures", product.keyFeatures);
  cb(formData);
};

const edit = (product, id, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    generateFormData(product, id, (formData) => {
      axios
        .put(Urls.products.v3.edit, formData, {
          headers: headers,
          onUploadProgress,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        });
    });
  });
  // } else {
  //   product.id = id;
  //   console.log("updatingwithout image request", product);
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .put(`${Urls.products.v3.edit}/edit`, product, {
  //         headers: headers,
  //         onUploadProgress,
  //       })
  //       .then((response) => resolve(response.data))
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }
};

const removeImageById = (image, id) => {
  return new Promise((resolve, reject) => {
    let images = [];
    images.push(image);
    axios
      .post(
        `${Urls.products.v3.removeImageById}`,
        { id: id, images: images },
        { headers: headers }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const restoreItem = (item) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${Urls.products.v3.restoreItem}`, item, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findAllDeleted = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.products.v3.allDeleted}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const monthlySales = (month) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.products.v3.sales}/${month.id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ProductService = {
  findAll: findAll,
  deleteOne: deleteOne,
  save: save,
  findById: findById,
  edit: edit,
  removeImageById: removeImageById,
  changeStatus: changeStatus,
  findAllDeleted,
  restoreItem,
  monthlySales,
  findAllByPage,
  updateProductXlsx,
  exportProductXlsx,
};
