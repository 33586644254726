import React ,{ useEffect} from 'react'
import  {Dialog , DialogActions, DialogTitle, DialogContent , DialogContentText, TextField , Button} from '@material-ui/core'

const DeclinedDialog = ({open,onClose,onConfirm, ...rest}) => {
    const [declinedReason , setDeclinedReason] = React.useState({
        status:"Declined",
        reason: null,
        error : null
    })

    const [isDisabled, setDisbaled] =React.useState(true) 

    useEffect(() => {
        setDisbaled(declinedReason.reason===null || declinedReason==="" || declinedReason.reason===undefined || declinedReason.error!==null)
    } , [declinedReason])

    const onChangeHandle= (event) => {
        let value= event.target.value
                                if(value===""){
                                    setDeclinedReason({
                                        ...declinedReason,
                                        reason: value,
                                        error : "Declined reason is required"
                                    })
                                    return
                                }
                                setDeclinedReason({
                                    ...declinedReason,
                                    reason: value,
                                    error :null
                                })
                    
    }
    return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">{"Declined Order"}</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
            <TextField
                    multiline
                    error={declinedReason.error ? true : false}
                    helperText={declinedReason.error}
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="declinedReason"
                    label="Declined Reason"
                    name="declinedReason"
                    autoComplete="declinedReason"
                    onChange={onChangeHandle}
                    
                />
    </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button onClick={onClose} color="secondary">
        Not Sure
    </Button>
    <Button onClick={() => onConfirm(declinedReason)} color="secondary" disabled={isDisabled}>
        Yes, Decline
    </Button>
    </DialogActions>
</Dialog>
}

export default DeclinedDialog
