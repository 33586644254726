import React, { useEffect, useState } from "react";
import moment from "moment";

import SalesReportsTable from "./SalesReportsTable";
import { PharmacyReportService } from "./../../../services/PharmacyReportService";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};

const PharmacyMonthlySaleReports = () => {
  const [selectedDate, setSelectedDate] = React.useState(date);

  const startOfMonth = moment().startOf("month").format("MMMM DD YYYY");
  const currentOfMonth = moment().format("MMMM DD YYYY");
  const [reports, setReports] = useState([]);
  const [csv, setCSV] = useState([]);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(currentDate);
  const [startDate, setStartDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    PharmacyReportService.getMonthlyReportsByYear(month.id, month.year)
      .then((res) => {
        setLoading(false);
        setReports(res.response);
        let mCSV = res.response.map((r, index) => ({
          "Sr.#": index == res.response.length - 1 ? "" : index + 1,
          Day: r.day,
          Discount: r.totalDiscount,
          SaleAmount: r.total,
          DeliveryCharges: r.deliveryCharges,
          Cost: r.cost,
          Profit: r.profit,
          "%": r.percent,
        }));
        setCSV(mCSV);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Something went wrong cant load reports. Try again!.");
      });
  }, [month]);

  useEffect(() => {
    setStartDate(moment(selectedDate).startOf("month").format("MMMM DD YYYY"));
    setLastDate(moment(selectedDate).endOf("month").format("MMMM DD YYYY"));
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  return (
    <Card>
      <CardHeader
        title="Day wise sales report of Pharmacy"
        subheader={
          moment().format("MMMM") !== month.name
            ? `From ${startDate} To ${lastDate}`
            : `From ${startOfMonth} To ${currentOfMonth}`
        }
        action={
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <DatePicker
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Year and Month"
                helperText="Start from month selection"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        }
      />
      <CardContent>
        <SalesReportsTable
          loading={loading}
          reports={reports}
          csv={csv}
          message={message}
        />
      </CardContent>
    </Card>
  );
};

export default PharmacyMonthlySaleReports;
