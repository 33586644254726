import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import Invoice from './Invoice';
import { useLocation, useParams } from 'react-router-dom';
const ordersSelector = createSelector((state) => state.orders, (orders) => orders);
const TestInvoice = () => {
	const params = useParams();
	// useEffect(() => {
	// 	let order = params.order;
	// 	console.log(JSON.parse(order));
	// });
	// const orders = useSelector(ordersSelector);
	// const [ order, setOrder ] = useState(null);
	// const location = useLocation();

	// useEffect(
	// 	() => {
	// 		if (order === null || order === undefined) {
	// 			let id = params.id;

	// 			let o = orders.filter((order) => order.id == id);
	// 			console.log('find', o, id);
	// 			setOrder(o);
	// 		}
	// 	},
	// 	[ order ]
	// );

	return <div> {JSON.parse(params.order).address}</div>;
};

export default TestInvoice;
