import React ,{useState, useEffect, Fragment} from 'react'

import {useTheme , makeStyles}  from '@material-ui/core/styles'
import { Card, Checkbox, Switch, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Collapse, CardContent, CardHeader, Menu, FormControlLabel, CircularProgress } from '@material-ui/core'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import SortIcon from '@material-ui/icons/Sort';
import { proxyPath } from '../../services/ApiPath';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close'
import {useSelector , useDispatch} from 'react-redux'
import { Actions } from '../../reducers/actions';
import { SubCategoryService } from '../../services/SubCategoryService';
import { ProductCategoryService } from '../../services/ProductCategoryService';
import { createSelector } from 'reselect';
import MAlert from '../elements/MAlert';
import { Socket } from 'socket.io-client';
//import CategoryActions from './../../reducers/category'
const socketSelector = createSelector(state=> state.socket , socket=> socket)
const useStyle = makeStyles(theme => ({
    root: {
        width: 400,
        overflow: 'auto',
        maxHeight: 600,
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
	}
}))

const ProductCategoryList = (props) => {
    const socket= useSelector(socketSelector)
    const [filter , setFilter] = React.useState({
        statusAll : true,
        status : false
    })
    const classes = useStyle()
   
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [productCategories , setProductCategories] = useState([]) 
    const [filterProductCategories , setFilterProductCategories] = useState([])
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const theme= useTheme()
    const [open , setOpen] = React.useState(false)
    const [currentCat ,setCurrentCat] = React.useState(null)
    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

    useEffect(() => {
        ProductCategoryService.findAll().then((data) => {
            setFilterProductCategories(data.response)
            setProductCategories(data.response)
            setLoading(false)
		}).catch(error => setLoading(false));
    } , [])

    useEffect(() => {
        setFilterProductCategories(filter.statusAll && !filter.status ? productCategories : productCategories.filter(cat=> cat.status==filter.status))
    } , [filter])

    useEffect(() => {
        const {item,isNew} = props.itemObj
        if(isNew)
            onInsertItem(item)
        else 
            onItemUpdate(item)
    } , [props.itemObj])
    const columns = [
        {
            name:"#ID",
            selector:"id",
            sortable:true
        }, 
        {
            name: "Title",
            selector: 'title',
            sortable: true
        },
        {
            name: "Category",
            selector: 'SubCategory.Category.title',
            sortable: true
        },
        {
            name: "Sub Category",
            selector: 'SubCategory.title',
            sortable: true
        },
        {
            name: "Status",
            selector: 'status',
            cell: (category ) => <Switch
            checked={category.status}
            onChange={(e)=> handleCheckChange(e,category)}
            name="status"
            inputProps={{ 'aria-label': 'staus checkbox' }}
          />
            
        },{
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (category) => (
                <React.Fragment>
                    <IconButton onClick={() => props.setEditItemObj({item:category, isEdit:true})} size="small" component="span">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(category)} size="small" component="span" style={{display:'none'}}>
                        <DeleteIcon />
                    </IconButton>
                </React.Fragment>
            ),
            right: true
        }
    ]
    const handleCheckChange = (event,category)=> {
        category.status=!category.status
        ProductCategoryService.changeStatus(category).then(res=> {
            socket.emit("EVENT_PRODUCT_CATEGORY" , res.response)
            onItemUpdate(res.response)
            setAlert({
                show:true,
                message : res.message,
                type:'success'
            })
            
        }).catch(error=> {
            setAlert({
                show:true,
                message : "Can't change category status.Something went wrong",
                type:'error'
            })
        })
    }

    const onItemUpdate = (item) => {
        setProductCategories(productCategories.map(cat => cat.id===item.id ? item : cat))
        setFilterProductCategories(filterProductCategories.map(cat => cat.id===item.id ? item : cat))
    }

    const onInsertItem = (item) => {
        setProductCategories([item, ...productCategories])
        setFilterProductCategories([item,...filterProductCategories])
    }
    

   

    
    
    const onDeleteClick = (category) => {
        setCurrentCat(category)
        setOpen(true)
    };

    const deleteItem = ()=> {
        ProductCategoryService.deleteOne(currentCat).then(res => {
            dispatch(Actions.removeProductCategory(currentCat))
            setOpen(!open)
            setAlert({
                show: true,
                message: res.message,
                type:"success"
            })
            
        }).catch(error=> {
            setAlert({
                show: true,
                message: "Can't delete category item.Something went wrong",
                type:"error"
            })
        })
    }

    const tableData= {
        columns : columns,
        data : filterProductCategories
    }
    return (
        <Fragment>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
                        
                        <CardHeader title="Product Categories" action=
                        {
                        <React.Fragment>
                            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}
                            aria-controls="filter-menu"
                            aria-haspopup="true">
                            <SortIcon/>
                        </IconButton>
                       
                         <Menu  id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                         <Card>
                            <CardHeader title="Filter Categories" />
                            <CardContent className={classes.root}>
                            <FormControlLabel
                                control={<Checkbox checked={filter.statusAll} onChange={(event)=> setFilter({
                                    ...filter,
                                    statusAll:!filter.statusAll
                                })} name="statusAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filter.status} onChange={(event)=> setFilter({
                                    ...filter,
                                    status:!filter.status
                                })} name="status" />}
                                label="Show By Status"
                            />
                            </CardContent>
                            </Card>
                        </Menu>
                        </React.Fragment>
                        
                        }>
                        
                    </CardHeader>
            <CardContent>
            <DataTableExtensions {...tableData} filterPlaceholder="Filter Category">
            
			<DataTable
            noHeader
                keyField="id"
				theme={theme.palette.type}
				title="Product Categories"
				columns={columns}
                data={filterProductCategories}
                progressPending={loading}
                progressComponent={<CircularProgress color="secondary"/>}
				defaultSortField="title"
				sortIcon={<SortIcon />}
				pagination
                highlightOnHover
			/>
            </DataTableExtensions>
            </CardContent>
        </Card>
        <Dialog
            open={open}
            onClose={()=> setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure to delete {currentCat?.title} cateogory?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=> setOpen(!open)} color="secondary">
                Not Sure
            </Button>
            <Button onClick={()=> deleteItem()} color="secondary" autoFocus>
                Yes, Delete
            </Button>
            </DialogActions>
        </Dialog>
        </Fragment>
    )
}

export default ProductCategoryList
