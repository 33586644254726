import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  TextField,
  Switch,
  CardContent,
  Button,
  Grid,
  Collapse,
  IconButton,
  FormControlLabel,
  LinearProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { CategoryService } from "../../services/CategoryService";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { ProductCategoryService } from "../../services/ProductCategoryService";
import MAlert from "../elements/MAlert";
import { createSelector } from "reselect";
import { SubCategoryService } from "../../services/SubCategoryService";

const useStyle = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
}));

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);

const NewProductCategory = ({ editItemObj, setItemObj, ...rest }) => {
  const socket = useSelector(socketSelector);
  const classes = useStyle();
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);

  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => {
        setProgress(0);
        setShowProgress(false);
      }, 1000);
    }
  };

  //console.log(category)
  const CategorySchema = yup.object().shape({
    title: yup
      .string()
      .trim("The category title cannot include leading and trailing spaces ")
      .required(),
    status: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState,
    errors,
  } = useForm({
    resolver: yupResolver(CategorySchema),
    defaultValues: { status: true },
  });

  useEffect(() => {
    CategoryService.findAll().then((res) => {
      setCategories(res.response);
    });
  }, []);

  useEffect(() => {
    if (categoryId !== null) {
      SubCategoryService.findByCategoryId(categoryId).then((res) => {
        setSubCategories(res.response);
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (editItemObj.isEdit) {
      let mCategoryId = editItemObj.item?.SubCategory.Category.id;
      let subCategoryId = editItemObj.item?.SubCategory.id;

      setCategoryId(mCategoryId);
      setSubCategoryId(subCategoryId);
      setValue("title", editItemObj.item?.title, { shouldValidate: true });
      //setSelectedCategory(categoryId)
    }
  }, [editItemObj]);

  const resetForm = () => {
    editItemObj.isEdit = false;
    setSubCategoryId(null);

    reset(
      {
        title: null,
        categoryImage: null,
      },
      {
        errors: true, // errors will not be reset
        dirtyFields: true, // dirtyFields will not be reset
        isDirty: true, // dirty will not be reset
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  };

  const onSubmit = (data) => {
    data.SubCategoryId = subCategoryId;
    data.CategoryId = categoryId;

    setShowProgress(true);
    if (editItemObj.isEdit) {
      data.id = editItemObj.item?.id;

      ProductCategoryService.edit(data)
        .then((res) => {
          socket.emit("EVENT_PRODUCT_CATEGORY", res.response);
          setItemObj({ item: res.response, isNew: false });
          resetForm();
          setShowProgress(false);
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Can't update  category",
            type: "error",
          });
        });
      return;
    }

    ProductCategoryService.save(data, onUploadProgress)
      .then((res) => {
        setItemObj({ item: res.response, isNew: true });
        setShowProgress(false);
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: "Can't insert new category",
          type: "error",
        });
      });
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <MAlert alert={alert} setAlert={setAlert} />
        <CardHeader
          title="New Sub Category"
          action={
            <FormControlLabel
              control={
                <Switch
                  checked
                  name="status"
                  inputRef={register}
                  inputProps={{ name: "status" }}
                />
              }
            />
          }
        />
        <CardContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="category">Category</InputLabel>
            <Select
              name="category"
              value={categoryId}
              onChange={(event) => {
                let value = event.target.value;
                setCategoryId(value);
              }}
              required
              labelId="demo-simple-select-outlined-label"
              id="category"
              name="category"
              label="categories"
            >
              {categories.map((category) => (
                <MenuItem value={category.id}>{category.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: theme.spacing(2) }}
          >
            <InputLabel id="category">Sub Category</InputLabel>
            <Select
              name="category"
              value={subCategoryId}
              onChange={(event) => setSubCategoryId(event.target.value)}
              required
              labelId="demo-simple-select-outlined-label"
              id="category"
              name="category"
              label="categories"
            >
              {subCategories.map((category) => (
                <MenuItem value={category.id}>{category.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            error={errors.title ? true : false}
            variant="outlined"
            margin="normal"
            inputRef={register}
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoComplete="title"
            helperText={errors.title?.message}
            autoFocus
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                type="reset"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => resetForm()}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {editItemObj.isEdit ? "Edit" : "Save"}
              </Button>
            </Grid>
          </Grid>

          <Box
            width="100%"
            className={classes.box}
            mr={1}
            style={{ display: showProgress ? "block" : "none" }}
          >
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

export default NewProductCategory;
