import React, { useCallback } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  makeStyles,
  Avatar,
  IconButton,
  CardMedia,
  Typography,
  CardActions,
  Button,
  Badge,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { proxyPath } from "../../services/ApiPath";
import CancelIcon from "@material-ui/icons/Cancel";
import MDialog from "../elements/MDialog";
import { OrdersService } from "../../services/OrdersService";
const useStyle = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  priceBg: {
    marginTop: 5,
    backgroundColor: theme.palette.success.main,
    padding: 5,
    borderRadius: "5px",
  },
  price: {
    marginTop: "5px",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    color: "#fff",
    padding: "4px 10px 4px 10px",
  },
}));

const calcItemDiscountPrice = (item) => {
  return (item.price * item.discount) / 100;
};

const OrderItems = (props) => {
  const classes = useStyle();
  const [dialog, setDialog] = React.useState({ open: false, payload: null });
  const { order, onOrderItemRemoved, orderItems } = props;

  const onRemove = (payload) => {
    onOrderItemRemoved(payload);
    onClose();
  };

  const onClose = () => {
    setDialog({
      open: false,
      payload: null,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {order &&
          order?.OrderItems?.map((item) => {
            return (
              <Grid item xs={12} sm={4}>
                <Card className={classes.root}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="cancel"
                        onClick={() =>
                          setDialog({
                            open: true,
                            payload: item,
                          })
                        }
                      >
                        <CancelIcon color="primary" />
                      </IconButton>
                    }
                    title={`${item?.Product?.title} ${item?.Product?.qtyPerUnit}`}
                  />
                  {/* <CardMedia
                            className={classes.media}
                            image={`${proxyPath}${item?.Product?.images[0]?.path}`}
                            title="Paella dish"
                        /> */}
                  <CardContent>
                    <Badge
                      color="secondary"
                      badgeContent={`${Math.round(item.Product.discount)}%`}
                      invisible={item.Product.discount > 0 ? false : true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <img
                        width="50"
                        height="60"
                        src={`${proxyPath}${item?.Product?.images[0]?.path}`}
                      />
                    </Badge>

                    {item?.content?.length > 0 ? (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ color: "red" }}
                      >
                        Note : {item?.content}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </CardContent>
                  <CardActions>
                    <Grid container>
                      <Grid item xs={12}>
                        <span className={classes.price}>
                          Rs{" "}
                          {Math.round(
                            item.Product.price - calcItemDiscountPrice(item)
                          )}
                        </span>
                        &nbsp;&nbsp;
                        {item.Product.discount > 0 ? (
                          <del> Rs {item.Product.price} </del>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <MDialog
        open={dialog.open}
        payload={dialog.payload}
        onYes={onRemove}
        onClose={onClose}
      />
    </React.Fragment>
  );
};

export default OrderItems;
