import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    box : {
        backgroundColor :props => props.color,
        borderRadius : 5,
        height : 20,
        width :20,
        marginRight: 5
    },
    div : {
        display : 'flex'
    },
}))

const LabelButton = ({onClick , children , ...rest}) => {
    const classes = useStyles(rest)
    return (
        <Button className={classes.div} onClick={() => onClick()}>
            <div className={classes.box}/>
           <span> {children}</span>
        </Button>
    )
}

export default LabelButton