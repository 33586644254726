export const getDiscountPrice = (price, discount) => {
  return roundTo2Digit(price - calculateDiscount(price, discount));
};

export const calculateDiscount = (price, discount) => {
  return (price * discount) / 100;
};

export const roundTo2Digit = (price) => {
  return Math.round(price * 100) / 100;
};

export const roundTo1Digit = (price) => {
  return Math.round(price * 10) / 10;
};

export const replaceString = (str, params) => {
  const keys = Object.keys(params);
  let newStr = str;
  keys.forEach((key) => {
    newStr = newStr.replaceAll(`{${key}}`, params[key]);
  });
  return newStr;
};

export function encodeUrl(url) {
  return encodeURI(url).replace(/[!'()*]/g, function (char) {
    return "%" + char.charCodeAt(0).toString(16);
  });
}
