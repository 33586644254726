import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Collapse,
  IconButton,
  CardContent,
  CardActionArea,
  CardMedia,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { proxyPath } from "../../services/ApiPath";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { BannerService } from "../../services/BannerService";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}));
const BannersList = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  //const banners = useSelector((state) => state.banners);
  const [banners, setBanners] = useState([]);
  console.log(banners);

  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [alertType, setAlertType] = React.useState(null);

  useEffect(() => {
    BannerService.findAll().then((data) => {
      console.log(data.response);
      setBanners(data.response);
    });
  }, []);

  useEffect(() => {
    if (props.banner !== null) {
      setBanners([props.banner, ...banners]);
    }
  }, [props.banner]);

  const onDelete = (banner) => {
    BannerService.deleteOne(banner)
      .then((res) => {
        setBanners(banners.filter((b) => b.id !== banner.id));
        setShow(res.success);
        setAlertType("success");
        setMessage(res.message);
      })
      .catch((error) => {
        setMessage("Can't delete banner image.");
        setShow(true);
        setAlertType("error");
      });
  };
  return (
    <Card>
      <Collapse in={show}>
        <Alert
          severity={`${alertType}`}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShow(!show);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
      <CardHeader title="Banners" />
      <CardContent>
        <Grid container spacing={4}>
          {banners.map((banner) => (
            <Grid item xs={12} sm={4}>
              <Card className={classes.root}>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(banner)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={`${proxyPath}${banner.path}`}
                    title="Banner Image"
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BannersList;
