import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import { blue, pink, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: pink[500],
			light: pink[300],
			dark: pink[700]
		},
		secondary: {
			main: blue[500]
		}
	}
});

export default theme;
