import React, { useEffect, useState } from "react";
import { Grid, Box, useTheme } from "@material-ui/core";

import OrdersByDate from "../orders/OrdersByDate";
import NotificationPanel from "./NotificationPanel";
import ReceivedOrders from "../orders/ReceivedOrders";
import StateCard from "../elements/StateCard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { StatsService } from "../../services/StatsService";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import TodayDrugOrder from "../pharmacy/order/TodayDrugOrder";
import TodayPrescriptions from "../pharmacy/prescription/TodayPrescriptions";

const Dashboard = () => {
  const theme = useTheme();
  const [stats, setStats] = useState([]);
  const [statusChange, setStatusChange] = useState(false);
  useEffect(() => {
    if (statusChange) {
      loadStats();
    }
  }, [statusChange]);

  useEffect(() => {
    loadStats();
  }, []);

  const loadStats = () => {
    StatsService.generateStats()
      .then((res) => {
        const stateObj = [
          {
            title: "Delivered",
            count:
              parseInt(res.response[0].count) + parseInt(res.response[3].count),
          },
          {
            title: "In-Progress",
            count:
              parseInt(res.response[1].count) + parseInt(res.response[4].count),
          },
          {
            title: "New",
            count:
              parseInt(res.response[2].count) + parseInt(res.response[5].count),
          },
          {
            title: "Users",
            count: res.response[6].count,
          },
        ];
        setStats(stateObj);
        setStatusChange(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getIcon = (obj) => {
    if (obj.title === "Delivered")
      return {
        icon: <ListAltIcon style={{ color: theme.palette.primary.main }} />,
        color: theme.palette.primary.main,
        title: obj.title,
        content: obj.count > 1 ? "Orders" : "Order",
      };
    else if (obj.title === "In-Progress")
      return {
        icon: <DonutLargeIcon style={{ color: theme.palette.info.main }} />,
        color: theme.palette.info.main,
        title: obj.title,
        content: obj.count > 1 ? "Orders" : "Order",
      };
    else if (obj.title === "New")
      return {
        icon: <FiberNewIcon style={{ color: theme.palette.success.main }} />,
        color: theme.palette.success.main,
        title: obj.title,
        content: obj.count > 1 ? "Orders" : "Order",
      };
    else if (obj.title === "Users")
      return {
        icon: <PeopleAltIcon style={{ color: theme.palette.warning.main }} />,
        color: theme.palette.warning.main,
        title: "Today new users",
        content: obj.count > 1 ? "Users" : "User",
      };
  };
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        {stats.map((s) => (
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <StateCard count={s.count} {...getIcon(s)} />
          </Grid>
        ))}
      </Grid>

      <Box
        component="span"
        display="block"
        p={1}
        m={1}
        bgcolor="background.paper"
      ></Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <OrdersByDate setStatusChange={setStatusChange} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TodayDrugOrder />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TodayPrescriptions />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ReceivedOrders />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;
