import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  Card,
  Checkbox,
  Switch,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  Typography,
  CircularProgress,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ContactsService } from "../../services/ContactsService";
import { useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SortIcon from "@material-ui/icons/Sort";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const Contacts = () => {
  const history = useHistory();
  const theme = useTheme();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });

  useEffect(() => {
    ContactsService.findAll()
      .then((data) => {
        setLoading(false);
        setContacts(data.response);
      })
      .catch((error) => setLoading(false));
  }, []);

  const onDeleteClick = (contact) => {
    ContactsService.deleteOneById(contact.id)
      .then((res) => {
        setAlert({
          show: true,
          type: "success",
          message: "Contact deleted successfully.",
        });
        setContacts(contacts.filter((con) => con.id !== contact.id));
      })
      .catch((err) => {
        setAlert({
          show: true,
          type: "error",
          message: "Can't delete contact try again.",
        });
      });
  };

  const columns = [
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      cell: (user) => (
        <Switch
          checked={user.status}
          onChange={(e) => handleCheckChange(e, user)}
          name="status"
          inputProps={{ "aria-label": "staus checkbox" }}
        />
      ),
    },
    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (contact) => (
        <React.Fragment>
          <IconButton
            onClick={() => {
              //dispatch(Actions.editItem(product))
              history.push({
                pathname: `/contacts/edit/${contact.id}`,
              });
            }}
            size="small"
            component="span"
          >
            <EditIcon />
          </IconButton>

          <IconButton
            onClick={() => onDeleteClick(contact)}
            size="small"
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        </React.Fragment>
      ),
      right: true,
    },
  ];

  const handleCheckChange = (e, contact) => {
    contact.status = !contact.status;
    ContactsService.edit(contact)
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((err) =>
        setAlert({
          show: true,
          message: "Can't change user status",
          type: "error",
        })
      );
  };

  const tableData = {
    columns: columns,
    data: contacts,
  };
  return (
    <Card>
      <Collapse in={alert.show}>
        <Alert
          severity={`${alert.type}`}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({
                  show: false,
                  message: null,
                  type: null,
                });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Collapse>
      <CardHeader
        title="Contacts"
        action={
          <Button
            color="primary"
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => history.push("/contacts/new")}
          >
            Add New
          </Button>
        }
      ></CardHeader>
      <CardContent>
        <DataTableExtensions {...tableData} filterPlaceholder="Filter contact">
          <DataTable
            progressComponent={<CircularProgress color="secondary" />}
            progressPending={loading}
            theme={theme.palette.type}
            noHeader
            title="Contacts"
            columns={columns}
            data={contacts}
            defaultSortField="phone"
            sortIcon={<SortIcon />}
            pagination
            highlightOnHover
            subHeader
            subHeaderAlign="right"
          />
        </DataTableExtensions>
      </CardContent>
    </Card>
  );
};

export default Contacts;
