import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Invoice from './Invoice';
import { OrdersService } from '../../services/OrdersService';
import {
	Grid,
	Card,
	CardHeader,
	CardContent,
	Select,
	MenuItem,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Button
} from '@material-ui/core';
import OrderItems from './OrderItems';
import { NotificationService } from '../../services/NotificationService';
import { useDispatch } from 'react-redux';
import { Actions } from '../../reducers/actions';
import ForwardIcon from '@material-ui/icons/Forward';
import ForwardOrder from './ForwardOrder';

const NotificationOrder = (props) => {
	const { id, entityTypeId, entityId } = props.match.params;
	const [ order, setOrder ] = React.useState(null);
	const [ isLoaded, setLoaded ] = React.useState(false);
	const dispatch = useDispatch();

	const [ forwardOrderDialog, setForwardOrderDialog ] = React.useState({
		open: false,
		order: null
	});

	const [ alert, setAlert ] = React.useState({
		show: false,
		message: null,
		type: null
	});

	const [ declinedReason, setDeclinedReason ] = React.useState({
		status: 'Declined',
		reason: null,
		error: null
	});
	const [ declinedDialog, setDeclinedDialog ] = React.useState({
		open: false,
		order: null
	});

	useEffect(() => {
		if (!isLoaded) {
			NotificationService.findOne(id, entityTypeId, entityId)
				.then(({ success, response }) => {
					setLoaded(true);
					setOrder(response);
				})
				.catch((error) => {
					setLoaded(true);
				});
		}
	});

	const handleStatusChange = (event) => {
		const { name, value } = event.target;
		if (value === 'Declined') {
			setDeclinedDialog({
				open: true,
				order: order
			});
			return;
		}

		order.orderStatus = event.target.value;
		changeOrderStatus(order);
	};

	const onDeclinedOrder = () => {
		if (declinedReason.reason === null || declinedReason.reason === '') {
			setDeclinedReason({
				...declinedReason,
				error: 'Declined reason is required.'
			});
			return;
		}

		let order = declinedDialog.order;
		order.orderStatus = declinedReason.status;
		order.declinedReason = declinedReason.reason;

		changeOrderStatus(order);
	};

	const changeOrderStatus = (order) => {
		setDeclinedDialog({
			open: false,
			order: null
		});
		OrdersService.edit(order)
			.then((res) => {
				if (order.orderStatus === 'Delivered' || order.orderStatus === 'Declined') {
					dispatch(Actions.removeTodayOrder(order));
				} else {
					dispatch(Actions.editOrder(order));
					dispatch(Actions.editTodayOrder(order));
				}

				setAlert({
					show: true,
					message: res.message,
					type: 'success'
				});
			})
			.catch((error) => {
				setAlert({
					show: false,
					message: "Can't change order status",
					type: 'error'
				});
			});
	};
	return (
		<React.Fragment>
			<div id="non-printable">
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6}>
						<Card id="non-printable">
							<CardHeader
								title="Invoice Detail"
								action={
									<div>
										<Select
											style={{ width: 150 }}
											labelId="order-status"
											id="orderStatus"
											value={order && order.orderStatus}
											onChange={(event) => handleStatusChange(event)}
										>
											<MenuItem value={'New'}>New</MenuItem>
											<MenuItem value={'Pending'}>Pending</MenuItem>
											<MenuItem value={'Declined'}>Declined</MenuItem>
											<MenuItem value={'In-Progress'}>In-Progress</MenuItem>
											<MenuItem value={'Delivered'}>Delivered</MenuItem>
										</Select>
										<IconButton
											onClick={() =>
												setForwardOrderDialog({
													open: true,
													order: order
												})}
											size="small"
											component="span"
										>
											<ForwardIcon />
										</IconButton>
									</div>
								}
							/>
							<CardContent>
								<Invoice order={order} isCenter={true} />
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<OrderItems order={order} />
					</Grid>
				</Grid>
			</div>
			<Invoice order={order} isCenter={false} printedDiv="invoice" id="printable" />
			<Dialog
				open={declinedDialog.open}
				onClose={() =>
					setDeclinedDialog({
						open: false,
						order: null
					})}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Declined Order'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<TextField
							multiline
							error={declinedReason.error ? true : false}
							helperText={declinedReason.error}
							rows={4}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="declinedReason"
							label="Declined Reason"
							name="declinedReason"
							autoComplete="declinedReason"
							onChange={(event) => {
								let value = event.target.value;
								if (value === '') {
									setDeclinedReason({
										...declinedReason,
										reason: value,
										error: 'Declined reason is required'
									});
									return;
								}
								setDeclinedReason({
									...declinedReason,
									reason: value,
									error: null
								});
							}}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() =>
							setDeclinedDialog({
								open: false,
								order: null
							})}
						color="secondary"
					>
						Not Sure
					</Button>
					<Button onClick={() => onDeclinedOrder()} color="secondary" autoFocus>
						Yes, Decline
					</Button>
				</DialogActions>
			</Dialog>
			<ForwardOrder
				setAlert={setAlert}
				order={forwardOrderDialog.order}
				open={forwardOrderDialog.open}
				setForwardOrderDialog={setForwardOrderDialog}
			/>
		</React.Fragment>
	);
};

export default withRouter(NotificationOrder);
