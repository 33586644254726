import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/parts/Header';
import AppDrawer from '../components/parts/Drawer';
import { Auth } from '../auth/Auth';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: theme.spacing(2)
	}
}));

const PrivateRoutes = ({ component: Component, path, ...rest }) => {
	const classes = useStyles();
	const [ open, setOpen ] = React.useState(true);
	const openDrawer = React.useCallback(() => setOpen(true));
	const closeDrawer = React.useCallback(() => setOpen(false));
	return (
		<Fragment>
			<Header open={open} openDrawer={openDrawer} drawerWidth={drawerWidth} />
			<AppDrawer open={open} closeDrawer={closeDrawer} drawerWidth={drawerWidth} />
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Route
					{...rest}
					render={({ match }) =>
						Auth.isLogin() ? (
							<Component match={match} />
						) : (
							<Redirect to={{ pathname: '/signin', state: { from: match } }} />
						)}
				/>
			</main>
		</Fragment>
	);
};

export default PrivateRoutes;
