import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findAll = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.orders.v3.findAllByMonths}/${monthId}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const filterTopCustomer = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Urls.orders.v3.filterTopCustomer, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const filterTopCustomerOptions = (option) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.orders.v3.filterTopCustomer}/${option}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const topMonthCustomers = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(Urls.orders.v3.topCustomers, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const filter = (filter) => {
  const data = {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    phone: filter.phone,
    orderStatus: filter.orderStatus,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(Urls.orders.v3.filter, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findAllByToday = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(Urls.orders.v3.today, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const forwardOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Urls.orders.v3.forward, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findOrderByIdAndAdminId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.orders.v3.findOrderByIdAndAdminId}/${id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findOrderByUserIdAndAdminId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.orders.v3.findOrderByUserIdAndAdminId}/${id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (item) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.orders.v3.edit, item, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeOrderItemById = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.orders.v3.removeItem, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addOrderItem = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.orders.v3.addItem, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const orderReports = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(Urls.orders.v3.orderReports, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const dayWiseReports = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.orders.v3.dayWiseReports}/${monthId}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const OrdersService = {
  findAll: findAll,
  edit: edit,
  findOrderByIdAndAdminId: findOrderByIdAndAdminId,
  findOrderByUserIdAndAdminId: findOrderByUserIdAndAdminId,
  findAllByToday,
  filter,
  topMonthCustomers,
  filterTopCustomer,
  filterTopCustomerOptions,
  forwardOrder,
  removeOrderItemById,
  addOrderItem,
  orderReports,
  dayWiseReports,
};
