import React from 'react';
import {
	Card,
	Collapse,
	IconButton,
	TextField,
	Button,
	CardHeader,
	CardContent,
	Box,
	LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { BannerService } from '../../services/BannerService';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../reducers/actions';

const useStyle = makeStyles((theme) => ({
	box: {
		marginTop: theme.spacing(4)
	}
}));

const NewBanners = (props) => {
	const classes = useStyle();
	const [ show, setShow ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertType, setAlertType ] = React.useState(null);
	const [ error, setError ] = React.useState(null);
	const [ bannerImage, setBannerImage ] = React.useState(null);
	const [ progress, setProgress ] = React.useState(0);
	const [ showProgress, setShowProgress ] = React.useState(false);

	const uploadImage = () => {
		if (bannerImage === null) {
			setError('Image is required');
			return;
		}

		BannerService.save(bannerImage, onUploadProgress)
			.then((res) => {
				props.setBanner({ path: res.response.image, id:res.response.id })
				setShow(res.success);
				setAlertType('success');
				setMessage(res.message);
			})
			.catch((error) => {
				setMessage("Can't upload new banners.");
				setShow(true);
				setAlertType('error');
			});
	};

	const onUploadProgress = (progressEvent) => {
		const { loaded, total } = progressEvent;
		let percent = Math.floor(loaded * 100 / total);
		setProgress(percent);
		if (percent === 100) {
			setTimeout(() => {
				setProgress(0);
				setShowProgress(false);
			}, 1000);
		}
	};

	return (
		<Card>
			<Collapse in={show}>
				<Alert
					severity={`${alertType}`}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setShow(!show);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{message}
				</Alert>
			</Collapse>
			<CardHeader title="New Banner" />
			<CardContent>
				<TextField
					type="file"
					error={error ? true : false}
					variant="outlined"
					margin="normal"
					required
					fullWidth
					onChange={(event) => {
						const { name, files } = event.target;
						if (files.length == 0) setError('Image is required');
						else {
							setError(null);
							setBannerImage(files[0]);
						}
					}}
					id="bannerImage"
					name="bannerImage"
					autoComplete="bannerImage"
					helperText={error}
				/>
				<Button type="submit" fullWidth variant="contained" color="primary" onClick={() => uploadImage()}>
					Upload
				</Button>

				<Box width="100%" className={classes.box} mr={1} style={{ display: showProgress ? 'block' : 'none' }}>
					<LinearProgress variant="determinate" value={progress} />
				</Box>
			</CardContent>
		</Card>
	);
};

export default NewBanners;
