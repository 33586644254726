import React from 'react';
import jwt from 'jsonwebtoken';

const TOKEN_KEY = 'token_key';

const login = (user) => {
	localStorage.setItem(TOKEN_KEY, JSON.stringify(user));
};

const logout = () => {
	localStorage.removeItem(TOKEN_KEY);
};

const isLogin = () => {
	if (!localStorage.getItem(TOKEN_KEY)) {
		return false;
	}
	const user = JSON.parse(localStorage.getItem(TOKEN_KEY));
	const decodedToken = jwt.decode(user.token, { complete: true });
	if (decodedToken.payload.exp < Math.round(new Date().getTime() / 1000)) {
		localStorage.removeItem(TOKEN_KEY);
		return false;
	}
	return true;
};

const User = () => {
	if (!isLogin()) {
		return '';
	}
	const data = JSON.parse(localStorage.getItem(TOKEN_KEY));
	const decodedToken = jwt.decode(data.token, { complete: true });

	return decodedToken.payload;
};

const token = () => {
	if (!isLogin()) {
		return null;
	}
	const data = JSON.parse(localStorage.getItem(TOKEN_KEY));
	return data.token;
};

export const Auth = {
	login,
	logout,
	token,
	isLogin,
	User
};
