import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
//import allReducers from './reducers'
import rootReducer from './reducers/rootReducer';
import { Store } from './reducers';


ReactDOM.render(

		<React.StrictMode>
			<ThemeProvider theme={theme}>
			<React.Suspense fallback={<span>Loading...</span>}>
				<BrowserRouter>
					<Provider store={Store.store}>
						<App />
					</Provider>
				</BrowserRouter>
				</React.Suspense>
			</ThemeProvider>
		</React.StrictMode>,

	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
