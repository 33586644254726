import React from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const MAlert = ({ alert, setAlert, ...rest }) => {
	return (
		<Collapse in={alert.show}>
			<Alert
				severity={`${alert.type}`}
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => {
							setAlert({
								show: false,
								message: null,
								type: null
							});
						}}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
			>
				{alert.message}
			</Alert>
		</Collapse>
	);
};

export default MAlert;
