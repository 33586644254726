import React, { useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, Avatar, Card, CardHeader, CardContent, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MAlert from '../elements/MAlert';
import Moment from 'react-moment';
import SortIcon from '@material-ui/icons/Sort';
import { proxyPath } from '../../services/ApiPath';
import DataTable from 'react-data-table-component';
import { OrdersService } from '../../services/OrdersService';
import { Actions } from '../../reducers/actions';
import { NotificationService } from '../../services/NotificationService';
import NumberTextField from '../elements/NumberTextField';
const notificationSelector = createSelector(
	(state) => state.notifications,
	(notifications) =>
		notifications.filter(
			(v, i, a) =>
				a.findIndex(
					(t) =>
						t.notificationObject.entityId === v.notificationObject.entityId &&
						v.notificationObject.entityTypeId == 7
				) === i
		)
	// notifications.filter((noti) => {
	// 	return noti.notificationObject.entityTypeId == 7;
	// })
);
const socketSelector = createSelector(state => state.socket , socket=>socket)
const useStyles = makeStyles((theme) => ({
	new: {
		backgroundColor: theme.palette.success.main,
		padding: '5px',
        borderRadius: '5px',
        color: '#FFF'
	},
	pending: {
		backgroundColor: theme.palette.warning.main,
		padding: '5px',
		borderRadius: '5px'
	},
	inProgress: {
		backgroundColor: theme.palette.secondary.main,
		padding: '5px',
        borderRadius: '5px',
        color: '#FFF'
	},
	declined: {
		backgroundColor: theme.palette.error.main,
		padding: '5px',
		borderRadius: '5px'
	},
	delivered: {
		backgroundColor: theme.palette.primary.main,
		padding: '5px',
		borderRadius: '5px'
	}
}));

const orderSelector = createSelector((state) => state.todayOrders, (todayOrders) => todayOrders);

const ReceivedOrders = () => {
    const socket = useSelector(socketSelector)
    const [invoiceNo , setInvoiceNo] =React.useState('')
    const dispatch=useDispatch()
    const theme = useTheme()
	const classes = useStyles();
	const notifications = useSelector(notificationSelector);
	const orders = useSelector(orderSelector);
	const [ receivedOrders, setReceievedOrder ] = React.useState([]);
	const [ alert, setAlert ] = React.useState({
		show: false,
		message: null,
		type: null
    });
    
    const columns = [
        {
            name: 'Invoice No',
            selector: 'id',
            sortable:true,
            cell: (order) => <Typography variant="span"> INVOICE-#{order.id}</Typography> 
        },
        {
            name: 'Image',
            selector: 'image.path',
            cell: ({User}) => <>
                <Avatar src={`${proxyPath}${User?.picUrl?.path}`}/>
                <Typography variant="span"> {User?.name}</Typography> </>
        },

        {
            name: 'Status',
            selector: 'orderStatus',
            cell: (order) => <Typography  component="span" variant="span" className={classes.inProgress}> Received</Typography>
        },
        {
            name: 'Date',
            selector: 'createdAt',
            cell : (order) => <Moment format="D MMM YYYY hh:mm" withTitle>
                    {order.createdAt}
                </Moment>
        },
        {
            name : "Actions",
            selector: "action",
            ignoreRowClick:true,
            cell : (order) => <Button variant="contained" color="primary" size="small" onClick={()=>changeOrderStatus(order)}>Close Order</Button>
        }
        
    ]

    const changeOrderStatus =(order) => {
		const { entityTypeId, id, entityId } = order.notification.notificationObject;
		order.orderStatus="Delivered"
		OrdersService.edit(order).then(res => {
            socket.emit('EVENT_ORDER' , order)
			dispatch(Actions.removeNotification(order.notification))
			NotificationService.edit(id,entityTypeId, entityId).then(data=> {
				console.log(data)
			})
            if(order.orderStatus==="Delivered" || order.orderStatus==="Declined"){
                dispatch(Actions.removeTodayOrder(order))
            }else{
                dispatch(Actions.editOrder(order))
                dispatch(Actions.editTodayOrder(order))
            }

            
        }).catch(error => {
           console.log(error)
        })
	}

    

	useEffect(
		() => {
			let mOrders = [];
			notifications.map((notification) => {
                const order = orders.find((order) => order.id == notification.notificationObject.entityId);
                
				if (order !== undefined){
                    order.notification = notification
                    mOrders = [order , ...mOrders ];
                } 
			});
			setReceievedOrder(invoiceNo===null || invoiceNo==='' ? mOrders : mOrders.filter(order=> order.id==invoiceNo));
		},
		[ notifications, orders ]
	);

	return <React.Fragment>
            <MAlert alert={alert} setAlert={setAlert}/>
                <Card>
            <CardHeader title="Users Received Orders" action={
                <NumberTextField
                value={invoiceNo}
                label="Invoice No"
                onChange={(event) => setInvoiceNo(event.target.value)}
            />
            }/>
            <CardContent>
            <DataTable
            noHeader
                // progressPending 
                // progressComponent={<CircularProgress />}
                defaultSortAsc={false}
                keyField="id"
                theme={theme.palette.type}
                columns={columns}
                data={receivedOrders}
                defaultSortField="id"
                sortIcon={<SortIcon />}
                pagination
                highlightOnHover
            />
            </CardContent>
        </Card>
            </React.Fragment>;
};

export default React.memo(ReceivedOrders);
