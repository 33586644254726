import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const getMartUserOrdersByMonthReports = (startDate, endDate) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.allReports.v4.martUserOrdersByMonthReports}?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            ...headers,
          },
          // responseType: "blob",
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getPharmacyUserOrdersByMonthReports = (startDate, endDate) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${Urls.allReports.v4.pharmacyUserOrdersByMonthReports}?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            ...headers,
          },
          // responseType: "blob",
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const AllReportsService = {
  getMartUserOrdersByMonthReports,
  getPharmacyUserOrdersByMonthReports,
};
