import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import DrugTypeList from './DrugTypeList'
import NewDrugType from './NewDrugType'

const DrugTypes = () => {
    const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
    return <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={4}>
            <NewDrugType setItemObj={setItemObj} editItemObj={editItemObj}/>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
            <DrugTypeList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
        </Grid>
    </Grid>
}

export default DrugTypes
