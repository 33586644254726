import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { DrugOrderService } from "../../../services/DrugOrderService";
import MAlert from "../../elements/MAlert";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../reducers/actions";
import { createSelector } from "reselect";
import Frame from "react-frame-component";
import { Auth } from "../../../auth/Auth";
import { makeStyles } from "@material-ui/core/styles";
import DrugOrderItem from "./DrugOrderItem";
import DrugInvoice from "./DrugInvoice";
import DrugListItems from "./DrugListItems";
import {
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { getDiscountPrice } from "../../../utils/Utils";
import { DeliveryChargesService } from "../../../services/DeliveryChargesService";
import Alert from "@material-ui/lab/Alert";

const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
    // 	width: 700
    // }
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    // [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
    // 	marginTop: theme.spacing(6),
    // 	marginBottom: theme.spacing(6),
    // 	padding: theme.spacing(3)
    // }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Order Items", "Invoice Detail", "Review order"];
const DrugOrderDetail = () => {
  const socket = useSelector(socketSelector);
  const history = useHistory();
  const params = useParams();
  const [order, setOrder] = React.useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState({
    id: 0,
    minAmount: 0,
    charges: 0,
  });

  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });

  useEffect(() => {
    DeliveryChargesService.findOne()
      .then((res) => {
        setDeliveryCharge({
          ...res.response,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onOrderItemRemoved = (orderItem) => {
    let items = order.DrugOrderItems.filter((item) => orderItem.id != item.id);
    order.DrugOrderItems = items;
    let total = getTotal(order.DrugOrderItems);
    order.deliveryCharges =
      total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;
    order.total = total;
    order.grandTotal = total;
    order.subTotal = total;
    DrugOrderService.removeOrderItemById({
      order: order,
      orderItem: orderItem,
    })
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
        socket.emit("PHARMACY_ORDER_CHANGE", JSON.stringify(order));
        setOrder(order);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };

  const getTotal = (orderItems) => {
    var totalPrice = 0.0;
    orderItems.map((orderItem) => {
      totalPrice +=
        orderItem.quantity *
        getDiscountPrice(orderItem.price, orderItem.discount);
    });
    return totalPrice;
  };

  useEffect(() => {
    socket.on(`PHARMACY_ORDER_ITEM_EVENT_${Auth.User().id}`, (data) => {
      setOrder(data);
      if (data.DrugOrderItems.length === 0) {
        setCancelDialog(true);
        dispatch(Actions.removeTodayOrder(data));
      }
    });
  }, []);

  useEffect(() => {
    DrugOrderService.findById(params.id).then((res) => {
      setOrder(res.response);
      console.log(res.response);
    });
  }, []);

  const handleNext = () => {
    if (activeStep == 1) {
      printIframe("receipt");
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onOrderItemAdd = (quantity, saleUnit, drug) => {
    //let saleType = drug.DrugUnits[0].title
    let orderItem = {
      id: -1,
      price: saleUnit.price,
      discount: saleUnit.discount,
      quantity: quantity,
      DrugId: drug.id,
      Drug: drug,
      saleType: saleUnit.title,
      DrugOrderId: order.id,
    };
    let items = [orderItem, ...order.DrugOrderItems];
    order.DrugOrderItems = items;
    let total = getTotal(order.DrugOrderItems);
    order.deliveryCharges =
      total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;
    order.total = total;
    order.grandTotal = total;
    order.subTotal = total;

    console.log({
      orderItem,
      order,
    });

    DrugOrderService.addOrderItem({
      orderItem,
      order,
    })
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
        setOrderItems([res.response, ...orderItems]);
        order.DrugOrderItems = order.DrugOrderItems.map((item) =>
          item.id == -1 ? res.response : item
        );
        socket.emit("PHARMACY_ORDER_CHANGE", JSON.stringify(order));
        setOrder(order);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframe.onload = function () {
      var mediaQueryList = iframe.contentWindow.matchMedia("print");
      mediaQueryList.addListener(function (mql) {
        console.log("print event", mql);
      });
    };
    iframeWindow.print();

    return false;
  };

  useEffect(() => {
    const id = "receipt";
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);

    iframe.onload = function () {
      var mediaQueryList = iframe.contentWindow.matchMedia("print");
      // var beforePrint = function() {
      // };

      var afterPrint = function () {
        setActiveStep(2);
      };
      // mediaQueryList.addListener(function (mql) {
      //   console.log('print event', mql);

      // });

      //iframe.contentWindow.onbeforeprint = beforePrint;
      iframe.contentWindow.onafterprint = afterPrint;
    };
  }, []);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <DrugOrderItem
            order={order}
            onOrderItemRemoved={onOrderItemRemoved}
            orderItems={orderItems}
          />
        );
      case 1:
        return <DrugInvoice order={order} isCenter={true} />;
      case 2:
        return (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Review Order
            </Typography>
            <Typography variant="subtitle1">
              Order invoice is printed must set order in progress to notify user
              about his order.
            </Typography>
          </React.Fragment>
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const setOrderInProgress = () => {
    setLoader(true);
    order.orderStatus = "In-Progress";
    DrugOrderService.edit(order)
      .then((res) => {
        socket.emit("PHARMACY_ORDER_CHANGE", JSON.stringify(order));
        if (
          order.orderStatus === "Delivered" ||
          order.orderStatus === "Declined"
        ) {
          //dispatch(Actions.removeTodayOrder(order));
        } else {
          // dispatch(Actions.editTodayOrder(order));
        }

        dispatch(Actions.editDrugTodayOrder(order));
        setLoader(false);
        handleNext();
      })
      .catch((error) => {
        setAlert({
          show: false,
          message: "Can't change order status",
          type: "error",
        });
      });
  };

  return (
    <main className={classes.layout}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper} id="non-printable">
            <MAlert alert={alert} setAlert={setAlert} />
            <Typography component="h1" variant="h4" align="center">
              Order Detail
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {order && order.note && (
              <div style={{ marginBottom: 10 }}>
                <Alert severity={"info"}>{order.note}</Alert>
              </div>
            )}
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Compelete.
                  </Typography>
                  <Typography variant="subtitle1">{`Order by ${order.User.name} with INVOICE-#${order.id} is in progress now.`}</Typography>
                  <div className={classes.buttons}>
                    <Button
                      onClick={() => history.push("/")}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Ok
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    {activeStep === steps.length - 1 ? (
                      loader ? (
                        <CircularProgress className={classes.button} />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={setOrderInProgress}
                          className={classes.button}
                        >
                          {"Set Order In Progress"}
                        </Button>
                      )
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep == 1 ? "Print" : "Next"}
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DrugListItems onOrderItemAdd={onOrderItemAdd} id="non-printable" />
        </Grid>
      </Grid>
      <Frame
        id="receipt"
        css={""}
        style={{ display: "none" }}
        initialContent={
          "<!DOCTYPE html><html><link rel='stylesheet' href='/assets/css/thermal.css' type='text/css'><head></head><body><div id='mountHere'></div></body></html>"
        }
        mountTarget="#mountHere"
      >
        <DrugInvoice order={order} isCenter={false} printedDiv="invoice" />
      </Frame>

      {/* <iframe
				id="receipt"
				src={`/payment/receipt/${JSON.stringify(order)}`}
				style={{ display: 'none' }}
				title="Receipt"
			/> */}

      <DrugInvoice
        order={order}
        isCenter={false}
        printedDiv="invoice"
        id="printable"
      />

      <Dialog
        disableBackdropClick
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancel Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`This order INVOICE-#${order?.id} has been canceled by ${order?.User?.name}.You can't proceed this order go back to dashboard.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelDialog(false);
              history.push("/");
            }}
            color="secondary"
            autoFocus
          >
            Go To Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default DrugOrderDetail;
