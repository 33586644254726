import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "./order.css";
import {
  Card,
  Checkbox,
  Switch,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  Typography,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Radio,
  CardContent,
  CardHeader,
  Menu,
  FormGroup,
  CircularProgress,
  Snackbar,
  Grid,
} from "@material-ui/core";
import { proxyPath } from "../../services/ApiPath";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import SortIcon from "@material-ui/icons/Sort";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { UserService } from "../../services/UserService";
import { Actions } from "../../reducers/actions";
import Moment from "react-moment";
import { OrdersService } from "../../services/OrdersService";
import PrintIcon from "@material-ui/icons/Print";
import ForwardIcon from "@material-ui/icons/Forward";
import { withRouter, useHistory } from "react-router-dom";
import ForwardOrder from "./ForwardOrder";
import MAlert from "../elements/MAlert";
import { createSelector } from "reselect";
import NumberTextField from "../elements/NumberTextField";
import { NotificationService } from "../../services/NotificationService";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const notificationSelector = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);
const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    overflow: "auto",
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  new: {
    backgroundColor: theme.palette.success.main,
    padding: "5px",
    borderRadius: "5px",
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
    padding: "5px",
    borderRadius: "5px",
  },
  inProgress: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px",
    borderRadius: "5px",
  },
  declined: {
    backgroundColor: theme.palette.error.main,
    padding: "5px",
    borderRadius: "5px",
  },
  delivered: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px",
    borderRadius: "5px",
  },
}));

const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);

const OrdersList = (props) => {
  const socket = useSelector(socketSelector);
  const history = useHistory();
  const [filter, setFilter] = React.useState({
    orderStatus: "All",
  });

  const [snakbar, setSnakbar] = useState({
    open: false,
    message: "",
  });

  const [invoiceNo, setInvoiceNo] = React.useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [orders, setOrders] = React.useState([]);
  const notifications = useSelector(notificationSelector);

  useEffect(() => {
    console.log("useEffect orderList render");
    setOrders(
      filter.orderStatus === "All"
        ? props.orders
        : props.orders.filter(
            (order) => order.orderStatus === filter.orderStatus
          )
    );
  }, [props.orders, filter]);

  useEffect(() => {
    setOrders(
      invoiceNo === null || invoiceNo === ""
        ? props.orders
        : props.orders.filter((order) => order.id == invoiceNo)
    );
  }, [invoiceNo]);

  const dispatch = useDispatch();
  const [forwardOrderDialog, setForwardOrderDialog] = React.useState({
    open: false,
    order: null,
  });
  const [declinedReason, setDeclinedReason] = React.useState({
    status: "Declined",
    reason: null,
    error: null,
  });
  const [declinedDialog, setDeclinedDialog] = React.useState({
    open: false,
    order: null,
  });
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });
  const columns = [
    {
      name: "Invoice No",
      selector: "id",
      sortable: true,
      cell: (order) => (
        <Typography variant="span"> INVOICE-#{order.id}</Typography>
      ),
    },
    {
      name: "Image",
      selector: "image.path",
      cell: ({ User }) => (
        <>
          <Avatar src={`${proxyPath}${User?.picUrl?.path}`} />
          <Typography variant="span"> {User?.name}</Typography>{" "}
        </>
      ),
    },
    {
      name: "Recipient",
      sortable: true,
      cell: (order) => (
        <>
          <span>{getReceipent(order).name}</span>
        </>
      ),
    },
    {
      name: "Recipient phone",
      sortable: true,
      cell: (order) => (
        <>
          <span>{getReceipent(order).phone}</span>
        </>
      ),
    },
    {
      name: "Total",
      selector: "total",
      cell: (order) => (
        <Typography variant="span"> {order.total} PKR</Typography>
      ),
    },
    {
      name: "Grand Total",
      selector: "total",
      cell: (order) => (
        <Typography variant="span"> {order.grandTotal} PKR</Typography>
      ),
    },

    {
      name: "Status",
      selector: "orderStatus",
      cell: (order) => (
        <Typography
          component="span"
          variant="span"
          className={getStatusClass(order.orderStatus)}
        >
          {" "}
          {order.orderStatus}
        </Typography>
      ),
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (order) => (
        <Moment format="D MMM YYYY hh:mm a" withTitle>
          {order.createdAt}
        </Moment>
      ),
    },
    {
      name: "Actions",
      selector: "action",
      ignoreRowClick: true,
      cell: (order) =>
        order.orderStatus !== "Delivered" ? (
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="order-status">Order Status</InputLabel>
            <Select
              fullWidth
              labelId="order-status"
              id="orderStatus"
              value={order.orderStatus}
              onChange={(event) => handleStatusChange(event, order)}
            >
              <MenuItem value={"New"}>New</MenuItem>
              {/* <MenuItem value={'Pending'}>Pending</MenuItem> */}
              <MenuItem value={"Declined"}>Declined</MenuItem>
              <MenuItem value={"In-Progress"}>In-Progress</MenuItem>
              <MenuItem value={"Out For Delivery"}>Out For Delivery</MenuItem>
              <MenuItem value={"Delivered"}>Delivered</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <Typography
            component="span"
            variant="span"
            className={getStatusClass(order.orderStatus)}
          >
            {" "}
            Order Closed
          </Typography>
        ),
    },
    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (order) => (
        <React.Fragment>
          <IconButton
            onClick={() =>
              history.push({
                pathname: `/orders/detail/${order.id}`,
                state: order,
              })
            }
            size="small"
            component="span"
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              order.orderStatus === "New"
                ? setForwardOrderDialog({
                    open: true,
                    order: order,
                  })
                : setSnakbar({
                    open: true,
                    message: `The order is in ${order.orderStatus} you can't forward this order.`,
                  })
            }
            size="small"
            component="span"
          >
            <ForwardIcon />
          </IconButton>
        </React.Fragment>
      ),
      right: true,
    },
  ];

  const getReceipent = (order) => {
    let data = {
      name: order.name,
      phone: order.phone,
    };
    if (order.name === null || order.name === "") {
      data = {
        ...data,
        name: order.User.name,
      };
    }

    if (order.phone === null || order.phone === "") {
      data = {
        ...data,
        phone: order.User.phone,
      };
    }

    return data;
  };

  const handleStatusChange = (event, order) => {
    const { name, value } = event.target;
    if (value === "Declined") {
      setDeclinedDialog({
        open: true,
        order: order,
      });
      return;
    }

    order.orderStatus = event.target.value;
    changeOrderStatus(order);
  };

  const onDeclinedOrder = () => {
    if (declinedReason.reason === null || declinedReason.reason === "") {
      setDeclinedReason({
        ...declinedReason,
        error: "Declined reason is required.",
      });
      return;
    }

    let order = declinedDialog.order;
    order.orderStatus = declinedReason.status;
    order.declinedReason = declinedReason.reason;

    changeOrderStatus(order);
  };

  const changeOrderStatus = (order) => {
    setDeclinedDialog({
      open: false,
      order: null,
    });
    OrdersService.edit(order)
      .then((res) => {
        socket.emit("EVENT_ORDER", order);
        if (
          order.orderStatus === "Delivered" ||
          order.orderStatus === "Declined"
        ) {
          dispatch(Actions.removeTodayOrder(order));
          let notification = notifications.find(
            (notify) => notify.notificationObject.entityId == order.id
          );
          if (notification !== undefined) {
            const { id, entityId, entityTypeId } =
              notification.notificationObject;
            NotificationService.edit(id, entityTypeId, entityId).then((res) => {
              console.log("Notification status changed");
              dispatch(Actions.removeNotificationByOrder(order));
            });
          }
          dispatch(Actions.removeNotificationByOrder(order));
        } else {
          dispatch(Actions.editTodayOrder(order));
        }

        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((error) => {
        setAlert({
          show: false,
          message: "Can't change order status",
          type: "error",
        });
      });
  };

  const getStatusClass = (orderStatus) => {
    if (orderStatus === "New") {
      return classes.new;
    } else if (orderStatus === "Pending") {
      return classes.pending;
    } else if (
      orderStatus === "In-Progress" ||
      orderStatus === "Out For Delivery"
    ) {
      return classes.inProgress;
    } else if (orderStatus === "Delivered") {
      return classes.delivered;
    } else {
      return classes.declined;
    }
  };

  const tableData = {
    columns: columns,
    data: orders,
  };
  return (
    <React.Fragment>
      <Card>
        <MAlert alert={alert} setAlert={setAlert} />
        <CardHeader
          title={props.title}
          action={
            <React.Fragment>
              <NumberTextField
                value={invoiceNo}
                label="Invoice No"
                onChange={(event) => setInvoiceNo(event.target.value)}
              />
              {props.isShowMonthlyFilter ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <DatePicker
                      variant="inline"
                      openTo="month"
                      views={["year", "month"]}
                      label="Year and Month"
                      helperText="Start from month selection"
                      value={props.selectedDate}
                      onChange={(date) => props.setSelectedDate(date)}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              ) : null}
              <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                aria-controls="filter-menu"
                aria-haspopup="true"
              >
                <SortIcon />
              </IconButton>

              <Menu
                id="filter-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <Card>
                  <CardHeader title="Filter Orders" />
                  <CardContent className={classes.root}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filter.orderStatus === "All"}
                          onChange={(event) =>
                            setFilter({
                              ...filter,
                              orderStatus: "All",
                            })
                          }
                          name="statusAll"
                        />
                      }
                      label="All"
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.orderStatus === "New"}
                            onChange={(event) =>
                              setFilter({
                                ...filter,
                                orderStatus: "New",
                              })
                            }
                            name="orderStatus"
                          />
                        }
                        label="New"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.orderStatus === "Pending"}
                            onChange={(event) =>
                              setFilter({
                                ...filter,
                                orderStatus: "Pending",
                              })
                            }
                            name="orderStatus"
                          />
                        }
                        label="Pending"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.orderStatus === "In-Progress"}
                            onChange={(event) =>
                              setFilter({
                                ...filter,
                                orderStatus: "In-Progress",
                              })
                            }
                            name="orderStatus"
                          />
                        }
                        label="In-Progress"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.orderStatus === "Declined"}
                            onChange={(event) =>
                              setFilter({
                                ...filter,
                                orderStatus: "Declined",
                              })
                            }
                            name="orderStatus"
                          />
                        }
                        label="Declined"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.orderStatus === "Delivered"}
                            onChange={(event) =>
                              setFilter({
                                ...filter,
                                orderStatus: "Delivered",
                              })
                            }
                            name="orderStatus"
                          />
                        }
                        label="Delivered"
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              </Menu>
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent>
          <DataTableExtensions {...tableData} filterPlaceholder="Filter Orders">
            <DataTable
              noHeader
              // progressPending
              // progressComponent={<CircularProgress />}
              defaultSortAsc={false}
              keyField="id"
              theme={theme.palette.type}
              title={props.title}
              columns={columns}
              data={orders}
              defaultSortField="id"
              sortIcon={<SortIcon />}
              pagination
              highlightOnHover
              progressPending={props.loading}
              progressComponent={<CircularProgress color="secondary" />}
            />
          </DataTableExtensions>
        </CardContent>
      </Card>

      <Dialog
        open={declinedDialog.open}
        onClose={() =>
          setDeclinedDialog({
            open: false,
            order: null,
          })
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Declined Order"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              multiline
              error={declinedReason.error ? true : false}
              helperText={declinedReason.error}
              rows={4}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="declinedReason"
              label="Declined Reason"
              name="declinedReason"
              autoComplete="declinedReason"
              onChange={(event) => {
                let value = event.target.value;
                if (value === "") {
                  setDeclinedReason({
                    ...declinedReason,
                    reason: value,
                    error: "Declined reason is required",
                  });
                  return;
                }
                setDeclinedReason({
                  ...declinedReason,
                  reason: value,
                  error: null,
                });
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeclinedDialog({
                open: false,
                order: null,
              })
            }
            color="secondary"
          >
            Not Sure
          </Button>
          <Button onClick={() => onDeclinedOrder()} color="secondary" autoFocus>
            Yes, Decline
          </Button>
        </DialogActions>
      </Dialog>

      <ForwardOrder
        setAlert={setAlert}
        order={forwardOrderDialog.order}
        open={forwardOrderDialog.open}
        setForwardOrderDialog={setForwardOrderDialog}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snakbar.open}
        onClose={() => setSnakbar({ open: false, message: "" })}
        message={snakbar.message}
        key={"bottom" + "right"}
      />
    </React.Fragment>
  );
};

export default React.memo(OrdersList);
