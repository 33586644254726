import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
	headers: {'x-access-token': Auth.token()}
  };
const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.drugBrands.v3.findAll}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const save = (data , onUploadProgress) => {
	return new Promise((resolve, reject) => {
		generateFormData(data ,false, -1 , (formData) => {
			axios
				.post(Urls.drugBrands.v3.save, formData, { headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	});
};

const edit = (data , isStatus,id , onUploadProgress ) => {
	return new Promise((resolve, reject) => {
		generateFormData(data,isStatus ,id , (formData) => {
			axios
				.put(Urls.drugBrands.v3.edit, formData, { headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	});
};


const generateFormData = (data ,isStatus ,id , cb) => {
	const formData = new FormData();
	id>-1 && 	formData.append('id', id);
	formData.append('status' , data.status)
	if(!isStatus){
		formData.append('image', data.image);
	}
	formData.append('title', data.title);
	cb(formData)
}
export const DrugBrandService = {
	findAll: findAll,
	save: save,
	edit
}