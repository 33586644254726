import React, { useState, useEffect, Fragment } from "react";
import { ProductService } from "../../services/ProductService";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Checkbox,
  Switch,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
  CardContent,
  CardHeader,
  Menu,
  FormControlLabel,
  TextField,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/Sort";
import { Urls, proxyPath } from "../../services/ApiPath";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import MAlert from "../elements/MAlert";
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { NotificationService } from "../../services/NotificationService";
import { UserService } from "../../services/UserService";
import { Link, useHistory } from "react-router-dom";
import { Actions } from "../../reducers/actions";
import { saveAs } from "file-saver";

const useStyle = makeStyles((theme) => ({
  root: {
    width: 400,
    overflow: "auto",
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

let deviceIds = [];
const socketSelector = createSelector(
  (state) => state.socket,
  (socket) => socket
);

const ProductPagingList = () => {
  const { productCurrentPage } = useSelector((state) => state);

  const history = useHistory();
  const socket = useSelector(socketSelector);
  const [users, setUsers] = useState([]);

  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showByStatus, setShowByStatus] = useState(true);
  const [showByDiscount, setShowByDiscount] = useState(false);
  const classes = useStyle();

  const [products, setProducts] = useState([]);
  //const products = useSelector(filterProducts)
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [currentProduct, setCurrentProduct] = React.useState(null);
  const [productLoading, setProductLoading] = useState(true);

  const [alert, setAlert] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [loading, setLoading] = useState(false);

  const [alertNotification, setAlertNotification] = useState({
    show: false,
    type: null,
    message: null,
  });
  const [title, setTitle] = useState("");

  const loadProducts = (searchQuery, page, status, discount) => {
    setProductLoading(true);
    ProductService.findAllByPage(searchQuery, page, 50, status, discount)
      .then((res) => {
        setProductLoading(false);
        setProducts(res.response);
        setTotal(res.total - 1);
      })
      .catch((err) => {
        setProductLoading(false);
      });
  };

  useEffect(() => {
    loadProducts(searchQuery, productCurrentPage, showByStatus, showByDiscount);
  }, [productCurrentPage, showByStatus, showByDiscount]);

  useEffect(() => {
    if (searchQuery.length >= 3)
      loadProducts(
        searchQuery,
        productCurrentPage,
        showByStatus,
        showByDiscount
      );
    if (searchQuery === "")
      loadProducts(undefined, productCurrentPage, showByStatus, showByDiscount);
  }, [searchQuery]);

  const [userDialog, setUserDialog] = useState({
    open: false,
    product: null,
  });

  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    if (userDialog.open) {
      UserService.findAll()
        .then((res) => {
          setUsers(res.response);
          setUserLoading(false);
        })
        .catch((error) => setUserLoading(false));
    }
  }, [userDialog]);

  const columns = [
    {
      name: "#ID",
      selector: "id",
    },
    {
      name: "Image",
      selector: "image.path",
      cell: ({ images }) => <Avatar src={`${proxyPath}${images[0]?.path}`} />,
    },
    {
      name: "Title",
      selector: "title",
    },
    {
      name: "Description",
      selector: "description",
    },
    // {
    //   name: "Category",
    //   selector: "ProductCategory.title",
    //   sortable: true,
    // },
    {
      name: "Quantity",
      selector: "quantity",
    },
    {
      name: "Unit",
      selector: "qtyPerUnit",
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
    },
    {
      name: "Status",
      selector: "status",
      cell: (product) => (
        <Switch
          checked={product.status}
          onChange={(e) => handleCheckChange(e, product)}
          name="status"
          inputProps={{ "aria-label": "staus checkbox" }}
        />
      ),
    },
    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (product) => (
        <React.Fragment>
          <Link to={`/products/edit/${product.id}`}>
            <EditIcon />
          </Link>
          <Link to={`/notifications?productId=${product.id}&module=mart`}>
            <NotificationsIcon />
          </Link>
          {/* <IconButton
            onClick={() => openUserDialog(product)}
            size="small"
            component="span"
          >
            
          </IconButton> */}
          <IconButton
            onClick={() => onDeleteClick(product)}
            size="small"
            component="span"
            style={{ display: "none" }}
          >
            <DeleteIcon />
          </IconButton>
        </React.Fragment>
      ),
      right: true,
    },
  ];
  const handleCheckChange = (event, product) => {
    product.status = !product.status;
    ProductService.changeStatus(product)
      .then((res) => {
        console.log(res);
        socket.emit("EVENT_PRODUCT", product);
        // dispatch(Actions.editProduct(res.response))
        setAlert({
          show: true,
          type: "success",
          message: res.message,
        });
      })
      .catch((error) => {
        console.log(error);
        setAlert({
          show: true,
          type: "error",
          message: "Can't change product status something went wrong.",
        });
      });
  };

  const openUserDialog = (product) => {
    setTitle(`${product.title} is ${Math.round(product.discount)}% off`);
    setUserDialog({
      open: true,
      product: product,
    });
  };

  const closeUserDialog = () => {
    setUserDialog({
      open: false,
      product: null,
    });
    setTitle("");
    deviceIds = [];
  };

  const sendNotifications = () => {
    if (deviceIds.length > 0) {
      setLoading(true);
      NotificationService.sendNotification({
        title: title,
        product: userDialog.product,
        entityTypeId: 9,
        deviceIds: JSON.stringify(
          deviceIds.filter(
            (id) => id !== null && id !== undefined && id.trim() !== ""
          )
        ),
      })
        .then((res) => {
          setAlertNotification({
            show: true,
            type: "success",
            message: res.message,
          });
          deviceIds = [];
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setAlertNotification({
            show: true,
            type: "error",
            message: "Can't send notification at the moment.try later.",
          });
          setLoading(false);
        });
    } else {
      setAlertNotification({
        show: true,
        type: "error",
        message: "Select atleast one user to send notification",
      });
    }
  };

  const onDeleteClick = (product) => {
    setCurrentProduct(product);
    setOpen(true);
  };

  const deleteItem = () => {
    ProductService.deleteOne(currentProduct)
      .then((res) => {
        dispatch(Actions.removeProduct(currentProduct));
        setOpen(!open);
        setAlert({
          show: true,
          type: "success",
          message: res.message,
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          type: "error",
          message: "Can't delete product.Something went wrong.",
        });
      });
  };

  const userColumns = [
    {
      name: "Image",
      selector: "image.path",
      cell: ({ picUrl }) => <Avatar src={`${proxyPath}${picUrl?.path}`} />,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
  ];

  const handleChange = (state) => {
    deviceIds = state.selectedRows.map((row) => row.deviceId);
  };
  const isIndeterminate = (indeterminate) => indeterminate;
  const selectableRowsComponentProps = { indeterminate: isIndeterminate };
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Fragment>
      <Card>
        <MAlert alert={alert} setAlert={setAlert} />
        <CardHeader
          title="Products"
          action={
            <React.Fragment>
              <a href={Urls.products.v3.xlsxExport} target="_blank">
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Download List
                </Button>
              </a>
              &nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => history.push("/products/new")}
              >
                Add New
              </Button>
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent>
          <DataTable
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <>
                <TextField
                  name="search"
                  id="search"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  label="Search Products"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showByStatus}
                      onChange={(event) => setShowByStatus(!showByStatus)}
                      name="status"
                    />
                  }
                  label="Show By Status"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showByDiscount}
                      onChange={(event) => setShowByDiscount(!showByDiscount)}
                      name="discount"
                    />
                  }
                  label="Discount"
                />
              </>
            }
            progressPending={productLoading}
            progressComponent={
              <div>
                <CircularProgress color="secondary" />
              </div>
            }
            persistTableHead={true}
            keyField="id"
            noHeader
            theme={theme.palette.type}
            title="Products"
            columns={columns}
            data={products}
            defaultSortField="title"
            sortIcon={<SortIcon />}
            highlightOnHover
            pagination
            paginationDefaultPage={productCurrentPage}
            paginationServer
            paginationTotalRows={total}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50]}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            onChangePage={(page) => {
              dispatch(Actions.changeProductCurrentPageAction(page));
            }}
          />
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete {currentProduct?.title} cateogory?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(!open)} color="secondary">
            Not Sure
          </Button>
          <Button onClick={() => deleteItem()} color="secondary" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={userDialog.open}
        onClose={() => closeUserDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send Notification To"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MAlert alert={alertNotification} setAlert={setAlertNotification} />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <DataTable
              defaultSortAsc={false}
              defaultSortField="totalAmount"
              theme={theme.palette.type}
              noHeader
              keyField="id"
              columns={userColumns}
              data={users}
              pagination
              selectableRows
              Clicked
              progressPending={userLoading}
              progressComponent={<CircularProgress color="secondary" />}
              onSelectedRowsChange={handleChange}
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={selectableRowsComponentProps}
              highlightOnHover
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeUserDialog()} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => sendNotifications()}
            color="secondary"
            autoFocus
            disabled={loading}
          >
            Send Notification
          </Button>
        </DialogActions>
        <LinearProgress style={{ display: loading ? "block" : "none" }} />
      </Dialog> */}
    </Fragment>
  );
};

export default ProductPagingList;
