import React, {useState} from 'react'
import { Grid } from '@material-ui/core'
import NewSubCategory from './NewSubCategory'
import SubCategoryList from './SubCategoryList'

const SubCategory = () => {
    const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
    return <Grid container spacing={4}>
    <Grid item xs={12} sm={12} md={4}>
       <NewSubCategory setItemObj={setItemObj} editItemObj={editItemObj}/>
   </Grid>
   <Grid item xs={12} sm={12} md={8}>
       <SubCategoryList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
   </Grid>
  
</Grid>
}

export default SubCategory
