import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  CircularProgress,
  Button,
  InputLabel,
  Grid,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import CsvDownload from "react-json-to-csv";
import { MartReportService } from "../../services/MartReportService";
import SortIcon from "@material-ui/icons/Sort";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({}));

const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};
const ProductSales = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(date);
  const startOfMonth = moment().startOf("month").format("MMMM DD YYYY");
  const currentOfMonth = moment().format("MMMM DD YYYY");
  const [reports, setReports] = useState([]);
  const [csv, setCSV] = useState([]);
  const [loading, setLoading] = useState(true);
  const [month, setMonth] = useState(currentDate);
  const [startDate, setStartDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    MartReportService.getMonthylProductReportByYear(month.id, month.year)
      .then((res) => {
        setLoading(false);
        setReports(res.response);
        let mCSV = res.response.map((r, index) => ({
          "Sr.#": index == res.response.length - 1 ? "" : index + 1,
          Name: r.title,
          Price: r.price,
          "No Of Items": r.noOfItems,
          Amount: r.totalPrice,
          Discount: r.totalDiscount,
          "Amount-Discount": r.netAmount,
        }));
        setCSV(mCSV);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [month]);

  useEffect(() => {
    setStartDate(moment(selectedDate).startOf("month").format("MMMM DD YYYY"));
    setLastDate(moment(selectedDate).endOf("month").format("MMMM DD YYYY"));
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  const columns = [
    {
      name: "Sr.#",
      cell: (report, index) =>
        index == reports.length - 1 ? "" : <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "title",
    },
    {
      name: "Price",
      selector: "price",
    },
    {
      name: "No of items",
      selector: "noOfItems",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(report.noOfItems)}
          </Typography>
        ) : (
          <span>{numberWithCommas(report.noOfItems)}</span>
        ),
    },
    {
      name: "Total Price",
      selector: "totalPrice",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(report.totalPrice)}
          </Typography>
        ) : (
          <span>{numberWithCommas(report.totalPrice)}</span>
        ),
    },
    {
      name: "Discount",
      selector: "totalDiscount",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(report.totalDiscount)}
          </Typography>
        ) : (
          <span>{numberWithCommas(report.totalDiscount)}</span>
        ),
    },
    {
      name: "Amount-Discount",
      selector: "netAmount",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(report.netAmount)}
          </Typography>
        ) : (
          <span>{numberWithCommas(report.netAmount)}</span>
        ),
    },
  ];

  const numberWithCommas = (x) => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  };

  return (
    <Card>
      <CardHeader
        title="Grocery Sale Reports "
        subheader={
          moment().format("MMMM") !== month.name
            ? `From ${startDate} To ${lastDate}`
            : `From ${startOfMonth} To ${currentOfMonth}`
        }
        action={
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <DatePicker
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Year and Month"
                helperText="Start from month selection"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        }
      />
      <CardContent>
        <DataTable
          defaultSortAsc={false}
          dense
          noHeader
          columns={columns}
          data={reports}
          subHeader
          subHeaderAlign="right"
          subHeaderComponent={
            <div style={{ display: "flex", alignItems: "center" }}>
              <CsvDownload data={csv}>Export CSV</CsvDownload>
            </div>
          }
          progressPending={loading}
          progressComponent={
            <div>
              <CircularProgress color="secondary" />
            </div>
          }
        />
      </CardContent>
    </Card>
  );
};

export default ProductSales;
