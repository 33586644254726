import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import DataTable from "react-data-table-component";
import CsvDownload from "react-json-to-csv";

const SalesReportsTable = ({ reports, loading, csv, message, ...rest }) => {
  const columns = [
    {
      name: "Sr.#",
      cell: (report, index) =>
        index == reports.length - 1 ? "" : <span>{index + 1}</span>,
    },
    {
      name: "Day",
      selector: "day",
    },
    {
      name: "Discount",
      selector: "totalDiscount",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(roundTo(report.totalDiscount))}
          </Typography>
        ) : (
          <span>{numberWithCommas(roundTo(report.totalDiscount))}</span>
        ),
    },
    {
      name: "Delivery Charges",
      selector: "deliveryCharges",
      cell: (report, index) =>
        index === reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(roundTo(report.deliveryCharges))}
          </Typography>
        ) : (
          <span>{numberWithCommas(roundTo(report.deliveryCharges))}</span>
        ),
    },
    {
      name: "Sales Amount",
      selector: "total",
      cell: (report, index) =>
        index == reports.length - 1 ? (
          <Typography component="strong">
            {numberWithCommas(roundTo(report.total))}
          </Typography>
        ) : (
          <span>{numberWithCommas(roundTo(report.total))}</span>
        ),
    },

    {
      name: "Cost",
      selector: "cost",
      cell: (report) => <span>0</span>,
    },
    {
      name: "Profit",
      selector: "profit",
      cell: (report) => <span>0</span>,
    },
    {
      name: "%",
      selector: "percent",
      cell: (report) => <span>0</span>,
      right: true,
    },
  ];

  const numberWithCommas = (x) => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  };

  const roundTo = (price) => {
    return Math.round(price * 100) / 100;
  };
  return (
    <DataTable
      dense
      noHeader
      columns={columns}
      data={reports}
      noDataComponent={<span>{message}</span>}
      subHeader
      subHeaderAlign="right"
      subHeaderComponent={
        <div style={{ display: "flex", alignItems: "center" }}>
          <CsvDownload data={csv}>Export CSV</CsvDownload>
        </div>
      }
      progressPending={loading}
      progressComponent={
        <div>
          <CircularProgress color="secondary" />
        </div>
      }
    />
  );
};

export default SalesReportsTable;
