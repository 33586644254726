import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Button,
	Badge,
	Avatar,
	Menu,
	MenuItem,
	Divider,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { Auth } from '../../auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../reducers/actions';
import { Store } from '../../reducers';
import NotificationPanel from '../dashboard/NotificationPanel';
import { NotificationService } from '../../services/NotificationService';
import { createSelector } from 'reselect';


const notificationsSelector = createSelector(state=> state.notifications , notifications=>notifications)
const socketSelector = createSelector(state=> state.socket , socket =>socket)
//const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(4)
	},
	title: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: (props) => props.drawerWidth,
		width: (props) => `calc(100% - ${props.drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	badgeStyle: {
		marginRight: theme.spacing(3)
	}
}));



const Header = (props) => {
	const history = useHistory()
	const location = useLocation()
	const audioRef= useRef(null)
	const classes = useStyles(props);
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	const [ notiAnchorEl, setNotiAnchorEl ] = React.useState(null);
	const dispatch = useDispatch();
	const socket = useSelector(socketSelector);
	const orders = useSelector(state => state.orders)
	const notifications = useSelector(notificationsSelector);
	const [ render, setRender ] = React.useState(false);
	const [play , setPlay] = React.useState(false)
	const [ dialog, setDialog ] = React.useState({
		open: false,
		data: null
	});

	useEffect(() => {
		if(Auth.isLogin()){
			socket.emit("join:admin:room" , Auth.User())
		}
		
	})


	

	const onClose = () => {
		if(audioRef!==null && audioRef.current!==null)
			audioRef.current.pause()
		setDialog({
			open : false,
			data : null
		})
	}
	function isPlaying(audelem) { return !audelem.paused; }

	

	useEffect(()=>{

		socket.on("order.received" , (data)=>{
			dispatch(Actions.insertNotification(data));
		})

		socket.on(`ORDER_RECEIVED_EVENT_${Auth.User().id}` , (data)=>{
			dispatch(Actions.insertNotification(data));
		})
		

		socket.on("NEW_ORDER", (data)=> {
			dispatch(Actions.insertTodayOrder(data))
			
		})
		
		socket.on(`NEW_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			dispatch(Actions.insertTodayOrder(data))
		})

		socket.on(`NEW_PHARMACY_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			dispatch(Actions.insertDrugTodayOrder(data))
		})

		socket.on(`NEW_PRESCRIPTION_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			dispatch(Actions.insertTodayPrescriptions(data))
		})

		socket.on(`NEW_PHARMACY_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			dispatch(Actions.insertDrugTodayOrder(data))
		})

		socket.on(`UPDATE_ORDER_${Auth.User().id}` , (data) => {
			console.log("update order event" , data)
			dispatch(Actions.editTodayOrder(data))
		})

		socket.on("FORWARD_ORDER", (data)=> {
			dispatch(Actions.insertTodayOrder(data))
		})

		socket.on(`FORWARD_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			
			dispatch(Actions.insertTodayOrder(data))
		})

		socket.on(`FORWARD_PHARMACY_ORDER_EVENT_${Auth.User().id}`, (data)=> {
			
			dispatch(Actions.insertDrugTodayOrder(data))
		})

		socket.on(`CANCEL_ORDER_EVENT_${Auth.User().id}` , (data)=> {
			cancelOrder(data)
		})

		socket.on(`CANCEL_PHARMACY_ORDER_EVENT_${Auth.User().id}` , (data)=> {
			cancelPharmacyEvent(data)
		})

		socket.on(`CANCEL_PHARMACY_PRESCRIPTION_EVENT_${Auth.User().id}` , (data)=> {
			cancelPharmacyPrescriptionEvent(data)
		})

		socket.on(`ORDER_CANCEL` , (data)=> {
			cancelOrder(data)
		})
	}, [])

	const cancelPharmacyPrescriptionEvent = (data) => {
		setDialog({
			open: false,
			data: null
		});
		dispatch(Actions.removeTodayPrescriptions(data))

	}



	const cancelPharmacyEvent =(data) => {
		setDialog({
			open: false,
			data: null
		});

		let notification = notifications.find(notification=> notification.notificationObject.entityId==data.id)
		console.log(notification, data , notifications)
		if(notification!==undefined){
			const {id , entityTypeId, entityId} = notification.notificationObject
			NotificationService.edit(id, entityTypeId ,entityId).then(res=> {
				console.log(res)
			})
		}

		

		if(audioRef !==null && audioRef.current!==null)
			audioRef.current.pause()

		dispatch(Actions.removeDrugTodayOrder(data))
		
		
		dispatch(Actions.removeNotificationByOrder(data))
		if(location.pathname.includes('/pharmacy/orders/detail/')){
			history.push('/')
		}
	}


	const cancelOrder =(data) => {
		setDialog({
			open: false,
			data: null
		});

		let notification = notifications.find(notification=> notification.notificationObject.entityId==data.id)
		console.log(notification, data , notifications)
		if(notification!==undefined){
			const {id , entityTypeId, entityId} = notification.notificationObject
			NotificationService.edit(id, entityTypeId ,entityId).then(res=> {
				console.log(res)
			})
		}

		

		if(audioRef !==null && audioRef.current!==null)
			audioRef.current.pause()

		dispatch(Actions.removeTodayOrder(data))
		
		
		dispatch(Actions.removeNotificationByOrder(data))
		if(location.pathname.includes('/orders/detail/')){
			history.push('/')
		}
	}


	const onAudioEnd = () => {
		if(audioRef !==null && audioRef.current!==null){
			let promise = audioRef.current.play()
			if (promise !== undefined) {
				promise.then(() => {}).catch(error => console.error);
			}
		}
		
	}

	useEffect(() => {
		socket.on(`NEW_ORDER_NOTIFICATION_EVENT_${Auth.User().id}`, (data) => {
			newOrder(data)
		});
		socket.on('order.new', (data) => {
			newOrder(data)
		})
			
	},[])

	const newOrder= (data) => {
		dispatch(Actions.insertNotification(data));
			setPlay(true)
				setDialog({
					open: true,
					data: data
				});
				if(audioRef !==null && audioRef.current!==null){
					let promise = audioRef.current.play()
					
					
					if (promise !== undefined) {
						promise.then(() => {}).catch(error => console.error);
					}
				}
			
			
	}
	
	

	const { open, openDrawer } = props;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const logout = () => {
		Auth.logout();
		localStorage.removeItem('store');
		dispatch(Actions.isLogin(false));
		history.push('/signin');
	};

	const proceedOrder = () => {
		
		const { entityTypeId, id, entityId } = dialog.data?.notificationObject;
		

		if(audioRef !==null && audioRef.current!==null){
			audioRef.current.pause()
	  
		dispatch(Actions.removeNotification(dialog.data))
		NotificationService.edit(id,entityTypeId, entityId).then(data=> {
			console.log(data)
		})
		setDialog({
			open: false,
			data: null
		});
			if(entityTypeId>=1 && entityTypeId<=5){
				history.push({
					pathname: `/orders/detail/${entityId}`,
					state: null
				})
			}else if(entityTypeId>=10 && entityTypeId<=14){
				history.push({
					pathname: `/pharmacy/orders/detail/${entityId}`,
					state: null
				})
			}else if(entityTypeId==16){
				history.push({
					pathname: `/pharmacy/prescriptions/${entityId}`,
					state: null
				})
			}
		}
		
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div id="non-printable">
			
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open
				})}
			>
				<Toolbar>
					<IconButton
						edge="start"
						onClick={openDrawer}
						className={classes.menuButton}
						color="inherit"
						aria-lable="Menu"
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Dashboard
					</Typography>
					<Badge
						badgeContent={notifications.length}
						color="secondary"
						className={classes.badgeStyle}
						onClick={(event) => setNotiAnchorEl(event.target)}
						aria-controls="notification-panel"
						aria-haspopup="true"
					>
						<MailIcon />
					</Badge>

					<Menu
						id="notification-panel"
						anchorEl={notiAnchorEl}
						keepMounted
						open={Boolean(notiAnchorEl)}
						onClose={(event) => setNotiAnchorEl(null)}
					>
						<NotificationPanel />
					</Menu>

					<Avatar
						onClick={handleClick}
						aria-controls="profile-menu"
						aria-haspopup="true"
						className={classes.small}
						alt="Image"
						src="../assets/img/brand/logo.png"
					/>
					<Menu
						id="profile-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								handleClose();
								history.push('/profile');
							}}
						>
							{Auth.User().storeName}
						</MenuItem>
						<Divider />
						<MenuItem onClick={logout}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>

			<Dialog
				disableBackdropClick
				open={dialog.open}
				onClose={() =>onClose()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'New Order'}</DialogTitle>
				<DialogContent>
				<audio src="../assets/sound/order_2.mp3" id="audio" preload="auto"  ref={audioRef} onEnded={onAudioEnd}></audio>
					<DialogContentText id="alert-dialog-description">{dialog.data?.message!==null ? dialog.data?.message : `${dialog.data?.actore.name} has placed a new order`}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
					variant="contained"
						onClick={() =>onClose()}
						color="seconprimarydary"
					>
						Not Sure
					</Button>
					<Button onClick={() => proceedOrder()} color="primary" variant="contained" autoFocus>
						Yes, Proceed
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Header
