import { yupResolver } from "@hookform/resolvers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import MAlert from "../../components/elements/MAlert";
import { NewNotificationService } from "../../services/NewNotificationService";

const NotificationSchema = yup.object().shape({
  title: yup
    .string()
    .trim("The notification title cannot include leading and trailing spaces ")
    .required(),
  body: yup.string().required(),
});

const UserOrderNotificationDialog = ({ module, open, onClose, order }) => {
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(NotificationSchema),
  });
  const onSubmit = async (values) => {
    const notification = {
      userId: order.UserId,
      // "deviceTokens": ["cv0Pk2rhtES2oRtITt0uyZ:APA91bE42VfRAjctYJ7uoeQR51_ngc2c16Q0lppfaH4cFE4OGzPgzlMQyzWYN-a4E8V_ehnQ8rlMO4X2u-11lQtfYuaAAFtv4NW_7P8lpluXHsDNAXuTK4yhFY5kYQdiNnelKyYwOug1","dlQMdssIQayWG6mdZ3g7rk:APA91bF9wBNvjE-zbSWGNsAYsaaPHj-it7YdsCxJaR6-QtwoaiYt9CSLjQCTwyoeIdOi2YebAzbhVJ7LrHqd77a0ZYVvyFdeKP8Yfvaz_msRAktRrmOF4B4yA-A1-vR9VORL3-nkoc1q"],
      payload: {
        data: {
          navigation: `${module}/order-detail/${order.id}`,
        },
        notification: {
          title: values.title,
          body: values.body,
          // imageUrl:
          //   "https://api.najeebmart.com/uploads/2020-10-12T06:58:50.389Z293.png",
        },
      },
    };

    try {
      const response = await NewNotificationService.send(notification);
      setAlert({
        show: true,
        message: "Notification sent successfully",
        type: "success",
      });
    } catch (err) {
      setAlert({
        show: true,
        message: "Error accord while sending notification.",
        type: "error",
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MAlert alert={alert} setAlert={setAlert} />
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: 10 }}
      >
        <DialogTitle id="alert-dialog-title">Send Notification</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              error={errors.title ? true : false}
              helperText={errors.title?.message}
              inputRef={register}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
            />
            <TextField
              error={errors.body ? true : false}
              helperText={errors.body?.message}
              inputRef={register}
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="body"
              label="Description"
              name="body"
              autoComplete="body"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="secondary" autoFocus>
            Send Notification
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserOrderNotificationDialog;
