import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';

const generateStats = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${Urls.stats.v3.stats}`, { headers: headers })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const StatsService = {
    generateStats
}