import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { Card, Checkbox, Switch, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Collapse, Typography, CircularProgress, CardContent, CardHeader } from '@material-ui/core'
import { proxyPath } from '../../services/ApiPath';
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert';
import {useTheme}  from '@material-ui/core/styles'
import SortIcon from '@material-ui/icons/Sort';
import { UserService } from '../../services/UserService'
import { Actions } from '../../reducers/actions';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddressList from './AddressList';
import { useHistory } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv'

const UsersList = () => {
    const history = useHistory();
    const theme=  useTheme()
    const [users, setUsers] = useState([])
    const [loading , setLoading] = useState(true)
    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

    useEffect(() => {
        UserService.findAll().then((data) => {
            setLoading(false)
           setUsers(data.response)

        }).catch(error => setLoading(false));
    }, [])

   
	const columns = [
        {
            name: 'Image',
            selector: 'image.path',
            cell: ({picUrl}) => <Avatar src={`${proxyPath}${picUrl?.path}`} />
        },
        {
            name: "Name",
            selector: 'name',
            sortable: true
        },
        {
            name: "Phone",
            selector: 'phone',
            sortable: true
		},
		{
            name: "Status",
            selector: 'status',
            cell: (user ) => <Switch
            checked={user.status}
            onChange={(e)=> handleCheckChange(e,user)}
            name="status"
            inputProps={{ 'aria-label': 'staus checkbox' }}
          />
            
        },{
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (user) => (
                <React.Fragment>
                    <IconButton onClick={() => {history.push({
                        pathname: `/users/orders/${user.id}`,
                        state: {
                            user :user
                        }
                    })}} size="small" component="span">
                        <ListAltIcon />
                    </IconButton>
                </React.Fragment>
            ),
            right: true
        }
	]

	const handleCheckChange = (e , user)=>{
        user.status = !user.status;
        UserService.edit(user)
			.then((res) => {
				setAlert({
                    show:true,
                    message:res.message,
                    type:"success"
                })
			})
			.catch((err) =>setAlert({
                show:true,
                message:"Can't change user status",
                type:"error"
            }) );
    }
    
    const tableData= {
        columns : columns,
        data : users
    }

    const ExpanableComponent = ({ data }) =>  {
        return <AddressList data={data} />
    };

    const exportContacts = () => {
        const contacts = users.map(user => ({PhoneNumber: user.phone}))
        

    }
	return <Card>
    <Collapse in={alert.show}>
                    <Alert
                    severity={`${alert.type}`}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert({
                                    show:false,
                                    message : null,
                                    type: null
                                })
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        {alert.message}
                        </Alert>
                    </Collapse>
                    <CardHeader title="Users"></CardHeader>
       <CardContent>
       <DataTableExtensions {...tableData} filterPlaceholder="Filter Users">
        
        <DataTable
            progressComponent={<CircularProgress color="secondary"/>}
            progressPending={loading}
            theme={theme.palette.type}
            noHeader
            title="Users"
            columns={columns}
            data={users}
            defaultSortField="title"
            sortIcon={<SortIcon />}
            pagination
            highlightOnHover
            expandableRows
            subHeader
                subHeaderAlign="right"
                subHeaderComponent={<CsvDownload name="registered_user_contacts.csv" data={users.map(user => ({PhoneNumber: user.phone.substring(2)}))}>Export Contacts</CsvDownload>}
            expandableRowsComponent={<ExpanableComponent />}
            
        />
        </DataTableExtensions>
       </CardContent>
    </Card>;
};

export default UsersList;
