import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CircularProgress, Grid, IconButton, Switch, Typography, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component'
import MAlert from '../../elements/MAlert'
import DataTableExtensions from 'react-data-table-component-extensions';
import SortIcon from '@material-ui/icons/Sort';
import EditIcon from '@material-ui/icons/Edit';

import { proxyPath } from '../../../services/ApiPath'
import { EmployeeService } from '../../../services/EmployeeService'
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));

const EmployeeList = (props) => {
    const classes = useStyles()
    const theme= useTheme()
    const [items , setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const onDelete= (item) => {
        EmployeeService.deleteById(item.id).then(res => {
            setItems(items.filter(it=> it.id!==item.id))
        }).catch(error=> {
            
        })
    }

   

    const onStatusChange = (event , item) => {
        item.status=!item.status
        EmployeeService.edit(item,true,item.id).then(res => {
            // if(res.success){
            //     onItemUpdate(res.response)
            // }
            setAlert({
                show:true,
                message:res.message,
                type : res.success ? 'success': 'error'
            })
        }).catch(error => {
            setAlert({
                show:true,
                message:error.message,
                type : 'error'
            })
        })
    }


    useEffect(()=> {
        const {item , isNew} = props.itemObj
        if(isNew){
            onInsertItem(item)
        }else
            onItemUpdate(item)
    }, [props.itemObj])

    const onItemUpdate = (item) => {
        setItems(items.map(cat => cat.id===item.id ? item : cat))
       
    }

    const onInsertItem = (item) => {
        setItems([item, ...items])
    
    }

    useEffect(() => {
        EmployeeService.findAll().then(res => {
            setLoading(false)
            setItems(res.response)
        }).catch(error => setLoading(false))
    } , [])

   



    return <Grid container spacing={4}>
        {
            items.map(item =>  <Grid item xs={12} sm ={6} md={4} lg={4}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                        <Avatar alt={item.path}  src={`${proxyPath}${item.path}`}  />
                        
                        }
                        title={item.name}
                        subheader={item.designation}
                    />
                    
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                        {item.about}
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button color="primary" size="small" variant="outlined" startIcon={<DeleteForeverIcon/>} onClick={()=> onDelete(item)}>Delete</Button>
                        <Button color="secondary" size="small" variant="outlined" startIcon={<EditIcon/>} onClick={()=> props.setEditItemObj({item:item, isEdit:true})}>Edit</Button>
                    
                    </CardActions>
            </Card>
            </Grid>)
        }
    </Grid>
}

export default EmployeeList
