import React from 'react';
import { TextField } from '@material-ui/core';

const NumberTextField = ({ onChange, value, ...rest }) => {
	return (
		<TextField
			value={value}
			onChange={(event) => {
				const re = /^[0-9\b]+$/;
				const { value } = event.target;
				if (value === '' || re.test(value)) onChange(event);
			}}
			{...rest}
		/>
	);
};

export default NumberTextField;
