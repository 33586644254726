import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findAll = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.contacts.v4.findAll}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findOneById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.contacts.v4.findOneById}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (contact) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${Urls.contacts.v4.edit}`, contact, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const save = (contact) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.contacts.v4.save}`, contact, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteOneById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Urls.contacts.v4.deleteOneById}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ContactsService = {
  findAll: findAll,
  edit: edit,
  deleteOneById,
  save,
  findOneById,
};
