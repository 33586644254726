import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect'
import { DrugOrderService } from '../../../services/DrugOrderService'
import DrugOrdersList from './DrugOrdersList'

const orderSelector = createSelector((state) => state.todayDrugOrders, (todayDrugOrders) => todayDrugOrders);

const TodayDrugOrder = () => {
    const orders = useSelector(orderSelector)
  
    return <DrugOrdersList orders={orders} loading={false}/>
}

export default TodayDrugOrder
