import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  TextField,
  Switch,
  CardContent,
  Button,
  Grid,
  Collapse,
  IconButton,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { CategoryService } from "../../services/CategoryService";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { ProductService } from "../../services/ProductService";
import MAlert from "../elements/MAlert";
import { SubCategoryService } from "../../services/SubCategoryService";
import { ProductCategoryService } from "../../services/ProductCategoryService";

const useStyle = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const NewProduct = () => {
  const theme = useTheme();
  const classes = useStyle();
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [productCategories, setProductCategories] = React.useState([]);
  const [categoryId, setCategoryId] = React.useState(0);
  const [subCategoryId, setSubCategoryId] = React.useState(0);
  const [productCategoryId, setProductCategoryId] = React.useState(0);
  const [productCategoryError, setProductCategoryError] = React.useState(null);
  const [productImages, setProductImages] = React.useState(null);
  const [productImagesError, setProductImagesError] = React.useState(null);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => {
        setProgress(0);
        setShowProgress(false);
      }, 1000);
    }
  };

  //console.log(category)
  const CategorySchema = yup.object().shape({
    title: yup
      .string()
      .trim("The category title cannot include leading and trailing spaces ")
      .required(),
    description: yup.string().required(),
    quantity: yup
      .number()
      .positive("quantity must be greater than zero")
      .required("quantity is required"),
    price: yup
      .number()
      .positive("price must be greater than zero")
      .required("quantity is required"),
    salePrice: yup
      .number()
      .positive("sale price must be greater than zero")
      .required("quantity is required"),
    qtyPerUnit: yup.string().required(),
    keyFeatures: yup.string(),
    status: yup.boolean(),
  });

  const { register, handleSubmit, watch, setValue, reset, formState, errors } =
    useForm({
      resolver: yupResolver(CategorySchema),
      defaultValues: { status: true, quantity: 1 },
    });

  useEffect(() => {
    CategoryService.findAll().then((res) => {
      setCategories(res.response);
    });
  }, []);

  useEffect(() => {
    if (categoryId != 0) {
      SubCategoryService.findByCategoryId(categoryId).then((res) => {
        setSubCategories(res.response);
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (subCategoryId != 0) {
      ProductCategoryService.findBySubCategoryId(subCategoryId).then((res) => {
        setProductCategories(res.response);
      });
    }
  }, [subCategoryId]);

  const resetForm = () => {
    setProductImages(null);
    setShowProgress(false);
    reset(
      {
        title: null,
        description: null,
        price: null,
        salePrice: null,
        qtyPerUnit: null,
        quantity: 1,
      },
      {
        errors: true, // errors will not be reset
        dirtyFields: true, // dirtyFields will not be reset
        isDirty: true, // dirty will not be reset
        isSubmitted: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  };

  const onSubmit = (data) => {
    if (productCategoryId === 0) {
      setProductCategoryError("Product Category is required");
      return;
    }

    if (productImages === null) {
      setProductImagesError("Product images is required");
      return;
    }
    var discount = 0.0;
    if (data.salePrice > 0) {
      discount = ((data.price - data.salePrice) / data.price) * 100;
    }
    data.productImages = productImages;
    data.ProductCategoryId = productCategoryId;
    data.CategoryId = categoryId;
    data.SubCategoryId = subCategoryId;
    data.discount = discount;
    data.status = true;

    setShowProgress(true);

    ProductService.save(data, onUploadProgress)
      .then((res) => {
        resetForm();
        setAlert({
          show: true,
          message: res.message,
          type: "success",
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: "Can't insert new product",
          type: "error",
        });
      });
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <MAlert alert={alert} setAlert={setAlert} />
        <CardHeader
          title="New Product"
          action={
            <FormControlLabel
              control={
                <Switch
                  checked
                  name="status"
                  inputRef={register}
                  inputProps={{ name: "status" }}
                />
              }
            />
          }
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                helperText={errors.title?.message}
                autoFocus
              />

              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <InputLabel id="category">Category</InputLabel>
                <Select
                  name="category"
                  value={categoryId}
                  onChange={(event) => setCategoryId(event.target.value)}
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="category"
                  label="categories"
                >
                  {categories.map((category) => (
                    <MenuItem value={category.id}>{category.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <InputLabel id="subcategory">Sub Category</InputLabel>
                <Select
                  name="subcategory"
                  value={subCategoryId}
                  onChange={(event) => setSubCategoryId(event.target.value)}
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="subcategory"
                  label="subcategory"
                >
                  {subCategories.map((category) => (
                    <MenuItem value={category.id}>{category.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <InputLabel id="productcategory">Product Category</InputLabel>
                <Select
                  name="productcategory"
                  value={productCategoryId}
                  onChange={(event) => {
                    setProductCategoryError(null);
                    setProductCategoryId(event.target.value);
                  }}
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="productcategory"
                  label="productcategory"
                >
                  {productCategories.map((category) => (
                    <MenuItem value={category.id}>{category.title}</MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {productCategoryError}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                multiline
                rows={4}
                error={errors.description ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="description"
                helperText={errors.description?.message}
              />

              <TextField
                multiline
                rows={4}
                variant="outlined"
                margin="normal"
                inputRef={register}
                fullWidth
                id="keyFeatures"
                label="Key Features"
                name="keyFeatures"
                autoComplete="keyFeatures"
              />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  onChange={(event) => {
                    let files = event.target.files;
                    if (files && files.length == 0) {
                      setProductImagesError("Product images is required");
                      return;
                    }
                    setProductImagesError(null);
                    setProductImages(files);
                  }}
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label>
                <FormHelperText style={{ color: "red" }}>
                  {productImagesError}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={errors.quantity ? true : false}
                helperText={errors.quantity?.message}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                label="Quantity"
                id="quantity"
                name="quantity"
                autoComplete="quantity"
              />
              <TextField
                error={errors.qtyPerUnit ? true : false}
                helperText={errors.qtyPerUnit?.message}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                label="Unit"
                id="qtyPerUnit"
                name="qtyPerUnit"
                autoComplete="qtyPerUnit"
              />
              <TextField
                error={errors.price ? true : false}
                helperText={errors.price?.message}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                label="Price"
                id="price"
                name="price"
                autoComplete="price"
              />
              <TextField
                error={errors.salePrice ? true : false}
                helperText={errors.salePrice?.message}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                label="Sale Price"
                id="salePrice"
                name="salePrice"
                autoComplete="salePrice"
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="reset"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => resetForm()}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>

              <Box
                width="100%"
                className={classes.box}
                mr={1}
                style={{ display: showProgress ? "block" : "none" }}
              >
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default NewProduct;
