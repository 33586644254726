import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

const MDialog = ({open ,payload , onYes ,onClose}) => {
    return (
        <Dialog
            open={open}
            onClose={()=> onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
                <DialogTitle id="alert-dialog-title">Remove Order Item</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure to remove {payload?.Product?.title} item from order?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=> onClose()} color="secondary">
                    Not Sure
                </Button>
                <Button onClick={() => onYes(payload)} color="secondary" autoFocus>
                    Yes, Remove
                </Button>
                </DialogActions>
            </Dialog>
    )
}

export default MDialog
