import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export const EditFeedbackDialog = ({ open, setOpen, feedback, onSave }) => {
  const [currentStatus, setCurrentStatus] = useState();
  const [responseText, setResponseText] = useState();
  const theme = useTheme();
  useEffect(() => {
    if (feedback) {
      setCurrentStatus(feedback.status);
      setResponseText(feedback.response);
    }
  }, [feedback]);
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Edit Feedback"}</DialogTitle>
      <DialogContent>
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginTop: theme.spacing(2) }}
        >
          <InputLabel id="status">Status</InputLabel>
          <Select
            name="status"
            value={currentStatus}
            onChange={(event) => {
              const value = event.target.value;
              setCurrentStatus(value);
            }}
            required
            labelId="demo-simple-select-outlined-label"
            id="status"
            label="status"
          >
            <MenuItem value={"New"}>New</MenuItem>
            <MenuItem value={"Viewed"}>Viewed</MenuItem>
            <MenuItem value={"Not-Contacted"}>Not Contacted</MenuItem>
            <MenuItem value={"Resolved"}>Resolved</MenuItem>
          </Select>
        </FormControl>
        <TextField
          multiline
          rows={4}
          value={responseText}
          onChange={(e) => setResponseText(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          id="response"
          label="Your comments"
          name="response"
          placeholder="Add Your response here if you contacted to the customer leave it blank in other case"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(!open)} color="secondary">
          Not Sure
        </Button>
        <Button
          onClick={() => {
            setOpen(!open);
            onSave(feedback, {
              status: currentStatus,
              response: responseText === "" ? null : responseText,
            });
          }}
          color="secondary"
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
