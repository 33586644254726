import React from "react";
import "./thermal.css";
import Moment from "react-moment";

const Invoice = (props) => {
  const { order, isCenter } = props;

  const getTotal = (orderItems) => {
    let total = 0;
    orderItems.map((item) => {
      total += calcItemPrice(item);
    });

    return total;
  };

  const calcItemDiscountPrice = (item) => {
    return (item.price * item.discount) / 100;
  };

  const calcItemPrice = (item) =>
    item.quantity * item.price - calcItemDiscountPrice(item) * item.quantity;

  const countOrderItems = (orderItems) => {
    let items = 0;
    orderItems.map((item) => {
      items += item.quantity;
    });
    return items;
  };

  const getSaveAmount = (orderItems) => {
    let total = 0;
    orderItems.map((item) => {
      total += calcItemDiscountPrice(item) * item.quantity;
    });

    return total;
  };

  const getReceipent = (order) => {
    let data = {
      name: order.name,
      phone: order.phone,
    };
    if (order.name === null || order.name === "") {
      data = {
        ...data,
        name: order.User.name,
      };
    }

    if (order.phone === null || order.phone === "") {
      data = {
        ...data,
        phone: order.User.phone,
      };
    }

    return data;
  };

  return (
    order && (
      <div className="card-body" id={props.id}>
        <div
          className={`invoice-container ${
            isCenter ? "center-invoice" : "margin-top"
          }`}
        >
          <div className="invoice">
            <div className="inovice-header">
              <div className="invoice-brand">
                <img
                  src="/assets/img/brand/invoicelogo.png"
                  className="brand"
                />
              </div>
              <div className="header-content">
                <p>{order?.Admin?.address}</p>
                <p className="stPhone">
                  <strong>Phone</strong> : {order?.Admin?.phone}
                </p>
              </div>
            </div>
            <div className="inovice-body">
              <div class="invoice-no">
                <p>
                  <strong>Invoice : #{order.id}</strong>
                </p>
                <p className="text-right">
                  <strong>Date :</strong>
                  <Moment format="DD/MM/YYYY hh:mm a">{new Date()}</Moment>
                </p>
              </div>
              <div className="divider" />
              <table>
                <thead>
                  <tr>
                    <th width="60%" className="text-left">
                      Item
                    </th>
                    <th width="5%">Qty</th>
                    <th width="15%" className="text-center">
                      Price
                    </th>
                    <th width="10%" className="text-center">
                      Disc
                    </th>
                    <th width="15%" className="text-right">
                      Amt
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  {order.OrderItems.map((item) => (
                    <tr>
                      <td width="60%" className="width-35">
                        <p className="text">
                          {item?.Product?.title} {item?.Product?.qtyPerUnit}
                        </p>
                      </td>
                      <td width="5%" className="text-center">
                        <p>{item.quantity}</p>
                      </td>
                      <td width="10%" className="text-center">
                        <p>{item.price}</p>
                      </td>
                      <td width="10%" className="text-center">
                        <p>
                          {item.discount > 0
                            ? `${Math.round(item.discount)}%`
                            : ""}
                        </p>
                      </td>
                      <td width="15%" className="text-right">
                        <p>{Math.round(calcItemPrice(item))}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <p className="text-14 text-left">SubTotal</p>
                    </th>
                    <td className="text-center">
                      <p className="text-14">
                        {countOrderItems(order.OrderItems)}
                      </p>
                    </td>
                    <td colSpan="4" className="text-right">
                      <p className="text-14">
                        Rs {Math.round(getTotal(order.OrderItems))}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <p className="b-text  text-14">Flat Disc : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right text-14">
                      <p className="b-text  text-14">Delivery : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">
                        {order.deliveryCharges > 0
                          ? order.deliveryCharges
                          : "Free"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <p className="b-text  text-14">Tax : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">0.0</p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="text-right">
                      <p className="b-text  text-14">Saved Amount : </p>
                    </td>
                    <td className="text-right">
                      <p className="text-14">
                        {Math.round(getSaveAmount(order.OrderItems))}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="2" className="text-right">
                      Total
                    </th>

                    <td colSpan="3" className="text-right">
                      <p className="b-text  text-14 text-right">
                        Rs{" "}
                        {Math.round(
                          getTotal(order.OrderItems) + order.deliveryCharges
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="5">
                      <div className="divider" />
                    </th>
                  </tr>

                  <tr>
                    <th colSpan="5">Delivery Detail</th>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p className="b-text">{getReceipent(order).name}</p>
                    </td>
                    <td colSpan="3">
                      <p className="b-text text-right">
                        {getReceipent(order).phone}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <p className="address">
                        House/Apartment : {order.apartment}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <p className="address">{order.address}</p>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="5">
                      <img
                        className="text-right"
                        style={{ width: "100px", float: "right" }}
                        src="/assets/img/brand/playstore.png"
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>

              {/* <div className="thead">
                            <ul>
                                <li>Item</li>
                                <li>Qty</li>
                                <li>Price</li>
                                <li>Amt</li>
                            </ul>
                        </div> */}
            </div>

            <div className="invoice-footer" />
          </div>
        </div>
      </div>
    )
  );
};

export default Invoice;
