import {
  Card,
  Collapse,
  IconButton,
  CardHeader,
  Button,
  CardContent,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormControlLabel } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { ContactsService } from "../../services/ContactsService";
import { useParams } from "react-router-dom";

const EditContact = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState();
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Store name is required"),
    phone: Yup.string().required("Phone number is required"),
    status: Yup.boolean(),
  });
  const initailValues = {
    name: "",
    phone: "",
    status: true,
  };
  const formik = useFormik({
    initialValues: initailValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      ContactsService.edit({
        ...values,
        id: contact.id,
      })
        .then((res) => {
          setLoading(false);
          setAlert({
            show: true,
            type: "success",
            message: " Contact updated successfully.",
          });
          formik.resetForm();
        })
        .catch((err) => {
          setLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: "Can't update contact. try again.",
          });
        });
    },
  });

  useEffect(() => {
    ContactsService.findOneById(id).then((res) => {
      setContact(res.response);
    });
  }, [id]);

  useEffect(() => {
    if (contact) {
      formik.setValues({
        ...formik.values,
        name: contact.name,
        phone: contact.phone,
        status: contact.status,
      });
    }
  }, [contact]);

  const resetForm = () => {
    formik.resetForm();
  };

  const { errors, touched } = formik;
  return (
    <Card>
      <Collapse in={alert.show}>
        <Alert
          severity={`${alert.type}`}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({
                  show: false,
                  message: null,
                  type: null,
                });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Collapse>
      <CardHeader title="Add New Contact"></CardHeader>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}>
            <form noValidate onSubmit={formik.handleSubmit}>
              <TextField
                error={errors.name && touched.name ? true : false}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                helperText={errors?.name}
                autoFocus
                {...formik.getFieldProps("name")}
                onBlur={formik.handleBlur("name")}
              />
              <TextField
                error={errors.phone && touched.phone ? true : false}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Phone"
                name="phone"
                helperText={errors?.phone}
                autoFocus
                {...formik.getFieldProps("phone")}
                onBlur={formik.handleBlur("phone")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.status}
                    name="status"
                    inputProps={{ name: "status" }}
                    onChange={(e) =>
                      formik.setFieldValue("status", !formik.values.status)
                    }
                  />
                }
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="reset"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => resetForm()}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EditContact;
