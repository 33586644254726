import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';

const save = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.post(Urls.productCategories.v3.save, category, { headers: headers })
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error);
			});
	});
};

const edit = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.productCategories.v3.edit, category, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const changeStatus = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.productCategories.v3.changeStatus, category, { headers: headers })
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error);
			});


	});

}

const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(Urls.productCategories.v3.findAll, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const deleteOne = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${Urls.productCategories.v3.delete}/${category.id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const restoreItem = (item) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${Urls.productCategories.v3.restoreItem}`, item,{ headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

const findAllDeleted = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.productCategories.v3.allDeleted}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const findBySubCategoryId = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.productCategories.v3.findBySubCategoryId}/${id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const ProductCategoryService = {
	findAll: findAll,
	save: save,
	deleteOne: deleteOne,
	edit: edit,
	changeStatus : changeStatus,
	restoreItem,
	findAllDeleted,
	findBySubCategoryId
};
