import { Card, CardActionArea, CardContent, CardHeader, TextField,Checkbox, FormControlLabel, CardActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, LinearProgress } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import {makeStyles , useTheme}  from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import LoadingButton from '../../elements/LoadingButton'
import MAlert from '../../elements/MAlert'
import { SignalCellularNull } from '@material-ui/icons'
import { DrugBrandService } from '../../../services/DrugBrandService'
const useStyle=  makeStyles((theme) => ({
    red : {
        color:'red'
    },
    box : {
        marginTop : theme.spacing(4)
    }
}))
const NewDrugBrand = ({editItemObj , setItemObj, ...rest}) => {
    const classes = useStyle()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const [uploading, setUploading] = useState({
        isDisplay: false,
        progress: 0
    })
    const [file, setFile] = useState(null)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const Schema = yup.object().shape({
        title : yup.string().required("Title is required"),
        status : yup.boolean(), 
        image:yup
        .mixed()
        .required("Image is required")
    })

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(Schema),
        defaultValues : {status : true}
        
      });

    useEffect(() => {
        let files = watch('image')
        if(files!==null && files.length > 0)
            setFile(URL.createObjectURL(files[0]))
        else setFile(null)
    } , [watch('image')])


    const resetForm =() => {
        setLoading(false)
        editItemObj.isEdit= false
        reset({
            title:null,
            status:true
        },{
            errors: false,
            dirtyFields: false, 
            isDirty: false, 
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          })
    }

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        setUploading({
            ...uploading,
            progress :percent
        })
        if(percent===100){
            setTimeout(()=> {
                setUploading({
                    ...uploading,
                    isDisplay:false,
                    progress :0
                })
            }, 1000)
        }
        
    }

    const onSubmit = (data)=> {
        data.status= status
        data.image= data.image[0]
        
        
        if(editItemObj.isEdit){
            setLoading(true)
            setUploading({
                ...uploading,
                isDisplay:true
            })
            let item = {
                ...editItemObj.item,
                status:status,
                image : data.image,
                title:data.title
            }
            DrugBrandService.edit(item, item.image===undefined , item.id , onUploadProgress).then(res=> {
                setLoading(false)
                resetForm()
                setAlert({
                    show:true,
                    message:res.message,
                    type:'success'
                })
                setItemObj({item:{...res.response, id: item.id}, isNew:false})
            }).catch(error => {
                setLoading(false)
                setUploading({
                    ...uploading,
                    percent:0,
                    isDisplay:false
                })
                setAlert({
                    show:true,
                    message:error.message,
                    type:'error'
                })
            })
        }else{
            if(file!==null){
                setLoading(true)
                setUploading({
                    ...uploading,
                    isDisplay:true
                })
                DrugBrandService.save(data, onUploadProgress).then(res=> {
                    setLoading(false)
                    resetForm()
                    setAlert({
                        show:true,
                        message:res.message,
                        type:'success'
                    })
                    setItemObj({item:res.response, isNew:true})
                }).catch(error => {
                    setLoading(false)
                    setUploading({
                        ...uploading,
                        percent:0,
                        isDisplay:false
                    })
                    setAlert({
                        show:true,
                        message:error.message,
                        type:'error'
                    })
                })
            }
        }
       
    }

    useEffect(() => {
        if(editItemObj.isEdit){
            setValue('title', editItemObj.item?.title, { shouldValidate: true })
            setStatus(editItemObj.item?.status)
        }
    }, [editItemObj])


    return <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
        <CardHeader title={editItemObj.isEdit? `Edit ${editItemObj.item?.title} Brand` : `New Drug Brand`}/>
        <CardActionArea>
            <CardContent>
           
            <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                helperText={errors.title?.message}
                autoFocus/>


            <TextField

                type="file"
                error={file===null ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="image"
                name="image"
                autoComplete="image"
                helperText={file===null ? `Drug category image is required.` : ``}/>
                            
            <FormControl>
                {file !==null ?  <img src={file} with="150" height="150"/> : ``}
            </FormControl>
            <br />
            <FormControlLabel
                control={<Checkbox checked={status} onChange={(event)=> setStatus(!status)} name="status" id="status" />}
                label="Status"
                />
            </CardContent>
        </CardActionArea>
        
        <CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => resetForm()}>
                Cancel
            </Button>
            <LoadingButton type="submit" loading={loading} size="small" variant="contained" color="primary">
                {editItemObj.isEdit ? `Edit` : `Save`}
            </LoadingButton>
        </CardActions>
            <LinearProgress style={{display : uploading.isDisplay ? 'block' :'none'}} variant="determinate" value={uploading.progress} />
        
    </Card>
    </form>
}

export default NewDrugBrand
