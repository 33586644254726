import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
	headers: {'x-access-token': Auth.token()}
  };
const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.featureDrugs.v3.findAll}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const findOneById = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.featureDrugs.v3.findOneById}/${id}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const save = (category ) => {
	return new Promise((resolve, reject) => {
		axios.post(Urls.featureDrugs.v3.save, category, { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
	});
};

const edit = (category ) => {
	return new Promise((resolve, reject) => {
		axios.put(Urls.featureDrugs.v3.edit, category, { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
	});
};

const addItem = (item ) => {
	return new Promise((resolve, reject) => {
		axios.post(Urls.featureDrugs.v3.addItem, item , { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
		});
	});
};

const removeItem = (item ) => {
	return new Promise((resolve, reject) => {
		axios.post(Urls.featureDrugs.v3.removeItem, item , { headers: headers })
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
		});
	});
};

export const FeatureDrugService = {
	findAll: findAll,
	save: save,
	edit,
	findOneById,
	addItem,
	removeItem
}