import React ,{useState, useEffect, Fragment} from 'react'
import { CategoryService } from '../../services/CategoryService'
import {useTheme}  from '@material-ui/core/styles'
import { Card, Checkbox, Switch, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Collapse, CardContent, CardHeader, Menu, MenuItem, Divider, FormControlLabel, CircularProgress } from '@material-ui/core'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import SortIcon from '@material-ui/icons/Sort';
import { proxyPath } from '../../services/ApiPath';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close'
import {useSelector , useDispatch, shallowEqual} from 'react-redux'
import { Actions } from '../../reducers/actions';
import NotifiationPanel from './../dashboard/NotificationPanel'
import { makeStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect'
import MAlert from '../elements/MAlert';
//import CategoryActions from './../../reducers/category'
const areEqual = (prevProps, nextProps) => true;
const socketSelector = createSelector(state=> state.socket , socket=> socket)

const useStyle = makeStyles(theme => ({
    root: {
        width: 400,
        overflow: 'auto',
        maxHeight: 600,
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
	}
}))

const CategoryList = (props) => {
    const classes= useStyle()
    const socket= useSelector(socketSelector)
    const [filter , setFilter] = React.useState({
        statusAll : true,
        status : false
    })


    const [categories, setCategories]= useState([])
    const [filterCategories, setFilterCategories]= useState([])
    
    
    const editItem = useSelector(state=> state.editItem)
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const dispatch = useDispatch()
    const [state , setState] = React.useState(false)
    const theme= useTheme()
    const [open , setOpen] = React.useState(false)
    const [currentCat ,setCurrentCat] = React.useState(null)
    const [categoriesLoading, setCategoriesLoading] = useState(true)
    
   
    const [alert , setAlert] = React.useState({
        show: false,
        message: null,
        type: null
    })

    useEffect(() => {
        setFilterCategories(filter.statusAll && !filter.status ? categories : categories.filter(cat=> cat.status==filter.status))
    },[filter])

    useEffect(() => {
        CategoryService.findAll().then((data) => {
            setCategories(data.response)
            setFilterCategories(data.response)
            setCategoriesLoading(false)
        }).catch(error => setCategoriesLoading(false));
    },[])

    useEffect(()=> {
        const {item , isNew} = props.itemObj
        if(isNew){
            onInsertItem(item)
        }else
            onItemUpdate(item)
    }, [props.itemObj])
    
    
    

    const columns = [
        {
            name: 'Image',
            selector: 'image.path',
            cell: ({image}) => <Avatar src={`${proxyPath}${image?.path}`} />
        },
        {
            name: "Title",
            selector: 'title',
            sortable: true
        },
        {
            name: "Description",
            selector: 'description',
            sortable: true
        },
        {
            name: "Status",
            selector: 'status',
            cell: (category ) => <Switch
            checked={category.status}
            onChange={(e)=> handleCheckChange(e,category)}
            name="status"
            inputProps={{ 'aria-label': 'staus checkbox' }}
          />
            
        },{
            name: 'Action',
            selector: 'Action',
            ignoreRowClick: true,
            cell: (category) => (
                <React.Fragment>
                    <IconButton onClick={() => props.setEditItemObj({item:category, isEdit:true})} size="small" component="span">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteClick(category)} size="small" component="span" style={{display:'none'}}>
                        <DeleteIcon />
                    </IconButton>
                </React.Fragment>
            ),
            right: true
        }
    ]
    const handleCheckChange = (event,category)=> {
        CategoryService.changeStatus(category).then(res=> {
            socket.emit('EVENT_CATEGORY', res.response)
            onItemUpdate(res.response)
            setAlert({
                show:true,
                message : res.message,
                type:'success'
            })
            
        }).catch(error=> {
            setAlert({
                show:true,
                message : "Can't change category status.Something went wrong",
                type:'error'
            })
        })
    }

    const onItemUpdate = (item) => {
        setCategories(categories.map(cat => cat.id===item.id ? item : cat))
        setFilterCategories(filterCategories.map(cat => cat.id===item.id ? item : cat))
    }

    const onInsertItem = (item) => {
        setCategories([item, ...categories])
        setFilterCategories([item,...filterCategories])
    }

    

   
    
    
    const onDeleteClick = (category) => {
        setCurrentCat(category)
        setOpen(true)
    };

    const deleteItem = ()=> {
        CategoryService.deleteOne(currentCat).then(res => {
            dispatch(Actions.removeCategory(currentCat))
            setOpen(!open)
            setAlert({
                show: true,
                message: res.message,
                type:"success"
            })
            
        }).catch(error=> {
            setAlert({
                show: true,
                message: "Can't delete category item.Something went wrong",
                type:"error"
            })
        })
    }

    const tableData= {
        columns : columns,
        data : filterCategories
    }
    return (
        <Fragment>
        <Card>
            <MAlert alert={alert} setAlert={setAlert}/>
                        <CardHeader title="Categories" action=
                        {
                        <React.Fragment>
                            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}
                            aria-controls="filter-menu"
                            aria-haspopup="true">
                            <SortIcon/>
                        </IconButton>
                       
                         <Menu  id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                         <Card>
                            <CardHeader title="Filter Categories" />
                            <CardContent className={classes.root}>
                            <FormControlLabel
                                control={<Checkbox checked={filter.statusAll} onChange={(event)=> setFilter({
                                    ...filter,
                                    statusAll:!filter.statusAll
                                })} name="statusAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filter.status} onChange={(event)=> setFilter({
                                    ...filter,
                                    status:!filter.status
                                })} name="status" />}
                                label="Show By Status"
                            />
                            </CardContent>
                            </Card>
                        </Menu>
                        </React.Fragment>
                        
                        }>
                        
                    </CardHeader>
            <CardContent>
            <DataTableExtensions {...tableData} filterPlaceholder="Filter Category">
            
			<DataTable
            noHeader
                keyField="id"
				theme={theme.palette.type}
				title="Categories"
				columns={columns}
                data={filterCategories}
                progressPending={categoriesLoading}
                progressComponent={<CircularProgress color="secondary"/>}
				defaultSortField="title"
				sortIcon={<SortIcon />}
				pagination
                highlightOnHover
			/>
            </DataTableExtensions>
            </CardContent>
        </Card>
        <Dialog
            open={open}
            onClose={()=> setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
                <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure to delete {currentCat?.title} cateogory?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=> setOpen(!open)} color="secondary">
                    Not Sure
                </Button>
                <Button onClick={()=> deleteItem()} color="secondary" autoFocus>
                    Yes, Delete
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default CategoryList 
