import { Card, CardActionArea, CardContent, CardHeader, TextField,Checkbox, FormControlLabel, CardActions, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, LinearProgress, FormLabel, RadioGroup, Radio } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import {makeStyles , useTheme}  from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import LoadingButton from '../../elements/LoadingButton'
import MAlert from '../../elements/MAlert'
import { LoginService } from '../../../services/LoginService'
import { EmployeeService } from '../../../services/EmployeeService'
const useStyle=  makeStyles((theme) => ({
    red : {
        color:'red'
    },
    box : {
        marginTop : theme.spacing(4)
    }
}))
const NewEmployee = ({editItemObj , setItemObj, ...rest}) => {
    const classes = useStyle()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(true)
    const [stores, setStores] = useState([])
    const [gender, setGender] = useState("Male")
    const [adminId, setAdminId] = useState(null)
    const [uploading, setUploading] = useState({
        isDisplay: false,
        progress: 0
    })
    const [file, setFile] = useState(null)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const Schema = yup.object().shape({
        name : yup.string().required("Employee name is required"),
        designation : yup.string().required("Employee name is required"),
        experience : yup.string().required("Employee name is required"),
        about : yup.string().required("About employee is required"),
        phone : yup.string().required("Employee phone is required"),
        email : yup.string().required("Employee email is required"),
        timing : yup.string().required("Employee timing is required"),
        status : yup.boolean(), 
        image:yup
        .mixed()
        .required("Image is required")
    })

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(Schema),
        defaultValues : {status : true}
        
      });

    useEffect(() => {
        let files = watch('image')
        if(files!==null && files.length > 0)
            setFile(URL.createObjectURL(files[0]))
        else setFile(null)
    } , [watch('image')])


    const resetForm =() => {
        setLoading(false)
        setStatus(true)
        editItemObj.isEdit= false
        setAdminId(null)
        reset({
            title:null,
            status:true
        },{
            errors: false,
            dirtyFields: false, 
            isDirty: false, 
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          })
    }

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor(loaded * 100 / total)
        setUploading({
            ...uploading,
            progress :percent
        })
        if(percent===100){
            setTimeout(()=> {
                setUploading({
                    ...uploading,
                    isDisplay:false,
                    progress :0
                })
            }, 1000)
        }
        
    }

    const onSubmit = (data)=> {
        data.status= status
        data.AdminId = adminId
        data.image= data.image[0]
        data.gender= gender
        
        
        if(editItemObj.isEdit){
            setLoading(true)
            setUploading({
                ...uploading,
                isDisplay:true
            })
            let item = {
                ...editItemObj.item,
                image : data.image!==undefined ? data.image[0] : undefined,
                ...data
            }
            EmployeeService.edit(item, item.image===undefined , item.id , onUploadProgress).then(res=> {
                setLoading(false)
                resetForm()
                setAlert({
                    show:true,
                    message:res.message,
                    type:'success'
                })
                setItemObj({item:res.response, isNew:false})
            }).catch(error => {
                setLoading(false)
                setUploading({
                    ...uploading,
                    percent:0,
                    isDisplay:false
                })
                setAlert({
                    show:true,
                    message:error.message,
                    type:'error'
                })
            })
        }else{
            if(file!==null){
                setLoading(true)
                setUploading({
                    ...uploading,
                    isDisplay:true
                })

                
                EmployeeService.save(data, onUploadProgress).then(res=> {
                    setLoading(false)
                    resetForm()
                    setAlert({
                        show:true,
                        message:res.message,
                        type:'success'
                    })
                    setItemObj({item:res.response, isNew:true})
                }).catch(error => {
                    setLoading(false)
                    setUploading({
                        ...uploading,
                        percent:0,
                        isDisplay:false
                    })
                    setAlert({
                        show:true,
                        message:error.message,
                        type:'error'
                    })
                })
            }
        }
       
    }

    useEffect(() => {
        if(editItemObj.isEdit){
            setValue('name', editItemObj.item?.name, { shouldValidate: true })
            setValue('designation', editItemObj.item?.designation, { shouldValidate: true })
            setValue('experience', editItemObj.item?.experience, { shouldValidate: true })
            setValue('about',editItemObj.item?.about)
            setValue('phone', editItemObj.item?.phone, { shouldValidate: true })
            setValue('email', editItemObj.item?.email, { shouldValidate: true })
            setValue('timing', editItemObj.item?.timing, { shouldValidate: true })
           
            setAdminId(editItemObj.item?.AdminId || null)
            setStatus(editItemObj.item?.status)
            setGender(editItemObj.item?.gender)
        }
    }, [editItemObj])

    useEffect(() => {
        LoginService.findAllStores().then(res => setStores(res.response))
    },[])
    return <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Card>
        <MAlert alert={alert} setAlert={setAlert}/>
        <CardHeader title={editItemObj.isEdit? `Edit ${editItemObj.item?.title} Employee` : `New Employee`}/>
        <CardActionArea>
            <CardContent>
            <FormControl fullWidth variant="outlined">
                <InputLabel id="store" >Select Mart</InputLabel>
                    <Select error={adminId===null} name="store" value={adminId} onChange={(event) => setAdminId(event.target.value)}

                        required
                        labelId="demo-simple-select-outlined-label"
                        id="store"
                        name="store"
                        label="store"
                    >
                        {stores.map(s=> <MenuItem value={s.id}>{s.storeName}</MenuItem>)}
                    </Select>
            <FormHelperText className={classes.red}>{adminId===null ? `Mart or Pharmacy required` : ``}</FormHelperText>
            </FormControl>
            <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="name"
                label="Employee Name"
                name="name"
                autoComplete="name"
                helperText={errors.name?.message}
                autoFocus/>
                <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="designation"
                label="Designation"
                name="designation"
                autoComplete="designation"
                helperText={errors.designation?.message}
                autoFocus/>
                <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="experience"
                label="Experience"
                name="experience"
                autoComplete="experience"
                helperText={errors.experience?.message}
                autoFocus/>
                <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                helperText={errors.phone?.message}
                autoFocus/>

                <TextField
                type="email"
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                helperText={errors.email?.message}
                autoFocus/>

             <TextField
                error={errors.title ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="timing"
                label="Timing (from - to)"
                name="timing"
                autoComplete="timing"
                helperText={errors.timing?.message}
                autoFocus/>
                <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                    inputRef={register}
                    fullWidth
                    id="about"
                    label="About"
                    name="about"
                    autoComplete="about"/>

            <TextField

                type="file"
                error={file===null ? true : false}
                variant="outlined"
                margin="normal"
                inputRef={register}
                required
                fullWidth
                id="image"
                name="image"
                autoComplete="image"
                helperText={file===null ? `Employee image is required.` : ``}/>
                            
            <FormControl>
                {file !==null ?  <img src={file} with="150" height="150"/> : ``}
            </FormControl>
           
            <br />
                 <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup aria-label="gender" name="gender" value={gender} onChange={(event) => setGender(event.target.value)}>
                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                    
                </RadioGroup>
            </FormControl>
            <br />
            <FormControlLabel
                control={<Checkbox checked={status} onChange={(event)=> setStatus(!status)} name="status" id="status" />}
                label="Status"
                />
              
            </CardContent>
        </CardActionArea>
        
        <CardActions>
            <Button size="small" variant="contained" color="primary" onClick={() => resetForm()}>
                Cancel
            </Button>
            <LoadingButton type="submit" loading={loading} size="small" variant="contained" color="primary">
                {editItemObj.isEdit ? `Edit` : `Save`}
            </LoadingButton>
        </CardActions>
            <LinearProgress style={{display : uploading.isDisplay ? 'block' :'none'}} variant="determinate" value={uploading.progress} />
        
    </Card>
    </form>
}

export default NewEmployee
