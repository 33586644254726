import { Grid } from '@material-ui/core'
import React , {useState} from 'react'
import FeatureDrugList from './FeatureDrugList'
import NewFeatureDrug from './NewFeatureDrug'

const FeatureDrugs = () => {
    const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
    return <Grid container spacing={4}>
    <Grid item xs={12} sm={12} md={4}>
        <NewFeatureDrug setItemObj={setItemObj} editItemObj={editItemObj}/>
    </Grid>
    <Grid item xs={12} sm={12} md={8}>
        <FeatureDrugList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
    </Grid>
</Grid>
}

export default FeatureDrugs
