import {
  Card,
  CardContent,
  CardHeader,
  useTheme,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { makeStyles } from "@material-ui/core/styles";
import { PharmacyReportService } from "../../../services/PharmacyReportService";
import LabelButton from "../../elements/LabelButton";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: "flex",
    width: `100%`,
    justifyContent: "center",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  marginTop: {
    marginTop: "20px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

let yearReportSeries = [];
let monthlyReportSeries = [];
let yearReportCategories = {};
let monthlyReportCategories = {};
const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};
const AllPharmaciesYearSalesGraph = () => {
  const [selectedDate, setSelectedDate] = React.useState(date);
  const classes = useStyles();
  let lineChartRef = useRef(null);
  const [month, setMonth] = useState(currentDate);
  const theme = useTheme();
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },
    subtitle: {
      text: undefined,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: [theme.palette.primary.main],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 4,
      colors: ["#FFA41B"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    stroke: {
      width: 7,
      curve: "smooth",
      lineCap: "round",
    },
    title: {
      text: `Monthly Revenue for ${new Date().getFullYear()}`,
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    noData: {
      text: "No reports available",
      style: {
        color: theme.palette.secondary.main,
        fontSize: "20px",
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: "Price In Rs",
      },
      labels: {
        formatter: function (value) {
          return "Rs " + value;
        },
        style: {
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
  });
  useEffect(() => {
    PharmacyReportService.getPharmaciesAllMonthTotalSalesByYear(
      month.id,
      month.year
    ).then((res) => {
      setLoading(false);
      let { yearReport, monthReport } = res.response;

      yearReportSeries = [
        {
          name: "Monthly Amount ",
          data: yearReport.map((d) => d.total),
        },
      ];
      monthlyReportSeries = [
        {
          name: "Daily Amount ",
          data: monthReport.map((d) => d.total),
        },
      ];
      yearReportCategories = yearReport.map((d) => d.month);
      monthlyReportCategories = monthReport.map((d) => d.createdAt);
      setSeries(yearReportSeries);
      let mOptions = {
        ...options,
        xaxis: {
          categories: yearReportCategories,
        },
      };
      setOptions(mOptions);
    });
  }, [month]);

  useEffect(() => {
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  const setYearReport = () => {
    setSeries(yearReportSeries);
    setOptions({
      ...options,
      title: {
        ...options.title,
        text: `Monthly Revenue for ${new Date().getFullYear()}`,
      },
      xaxis: {
        categories: yearReportCategories,
      },
      fill: {
        ...options.fill,
        gradient: {
          ...options.fill.gradient,
          gradientToColors: [theme.palette.primary.main],
        },
      },
    });
  };
  const setMonthlyReport = () => {
    setSeries(monthlyReportSeries);
    setOptions({
      ...options,
      title: {
        ...options.title,
        text: `Daily Revenue for ${moment(new Date()).format("MMMM YYYY")}`,
      },
      xaxis: {
        type: "datetime",
        categories: monthlyReportCategories,
      },
      fill: {
        ...options.fill,
        gradient: {
          ...options.fill.gradient,
          gradientToColors: [theme.palette.success.main],
        },
      },
    });
  };
  return (
    <Card>
      <CardHeader
        action={
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <DatePicker
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Year and Month"
                helperText="Start from month selection"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        }
      />
      <CardContent>
        <Chart
          options={options}
          series={series}
          type="line"
          height={350}
          width="100%"
          ref={(ref) => (lineChartRef = ref)}
        />
        <div className={classes.buttonDiv}>
          <LabelButton
            onClick={setYearReport}
            color={theme.palette.primary.main}
          >
            Year Report
          </LabelButton>
          <LabelButton
            onClick={setMonthlyReport}
            color={theme.palette.success.main}
          >
            Monthly Report
          </LabelButton>
        </div>
      </CardContent>
    </Card>
  );
};

export default AllPharmaciesYearSalesGraph;
