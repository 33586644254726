/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Switch,
  useTheme,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import SortIcon from "@material-ui/icons/Sort";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { DrugService } from "../../../services/DrugService";
import DataTable from "react-data-table-component";
import MAlert from "../../elements/MAlert";
import { TextField } from "@material-ui/core";
import { CloudUpload, TramOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../reducers/actions";
import { Urls } from "../../../services/ApiPath";
import { Link } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import * as xlsx from "xlsx";

const useStyle = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const DrugList = () => {
  const [isImporting, setIsImporting] = useState(false);
  const classes = useStyle();
  const [fileInputKey, setFileInputKey] = useState(0);
  const { drugsCurrentPage } = useSelector((state) => state);
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showByStatus, setShowByStatus] = useState(true);
  const [showByDiscount, setShowByDiscount] = useState(false);

  const loadDrugs = (searchQuery, page, status, discount) => {
    setLoading(true);
    DrugService.findAllByPage(searchQuery, page, 50, status, discount)
      .then((res) => {
        setLoading(false);
        setItems(res.response);
        setTotal(res.total - 1);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDrugs(searchQuery, drugsCurrentPage, showByStatus, showByDiscount);
  }, [drugsCurrentPage, showByStatus, showByDiscount]);

  // useEffect(() => {
  //   if (searchQuery.length >= 3)
  //     loadDrugs(searchQuery, drugsCurrentPage, showByStatus, showByDiscount);
  //   if (searchQuery === "")
  //     loadDrugs(undefined, drugsCurrentPage, showByStatus, showByDiscount);
  // }, [searchQuery]);

  const columns = [
    {
      name: "#ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Formula",
      selector: "formula",
      sortable: true,
    },
    {
      name: "Type",
      selector: "DrugType.title",
      sortable: true,
    },
    {
      name: "Measurement",
      selector: "measurement",
      sortable: true,
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
    },
    {
      name: "Discount",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      cell: (item) => (
        <Switch
          checked={item.status}
          onChange={(e) => onStatusChange(e, item)}
          name="status"
          inputProps={{ "aria-label": "staus checkbox" }}
        />
      ),
    },
    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (item) => (
        <React.Fragment>
          <IconButton
            onClick={() =>
              history.push({
                pathname: `drugs/edit/${item.id}`,
              })
            }
            size="small"
            component="span"
          >
            <EditIcon />
          </IconButton>
          <Link to={`/notifications?productId=${item.id}&module=pharmacy`}>
            <NotificationsIcon />
          </Link>
        </React.Fragment>
      ),
      right: true,
    },
  ];

  //   const muiColumns = [
  //     {
  //       name: "id",
  //       label: "#ID",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "name",
  //       label: "Name",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "formula",
  //       label: "Formula",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "type",
  //       label: "DrugType.title",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "measurement",
  //       label: "Measurement",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "price",
  //       label: "Price",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },
  //     {
  //       name: "discount",
  //       label: "Discount",
  //       options: {
  //         filter: true,
  //         sort: true,
  //       },
  //     },

  //     {
  //       name: "status",
  //       label: "Status",

  //       options: {
  //         filter: false,
  //         sort: false,
  //         customBodyRender: (value, tableMeta, updateValue) => {
  //           const item = items[tableMeta.rowIndex];
  //           return (
  //             <Switch
  //               value={value}
  //               checked={value}
  //               onChange={(e) => onStatusChange(e, item)}
  //             />
  //           );
  //         },
  //       },
  //     },
  //   ];

  const onStatusChange = (e, item) => {
    item.status = !item.status;
    DrugService.editStatus(item)
      .then((res) => {
        setAlert({
          show: true,
          message: res.message,
          type: res.success ? "success" : "error",
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.message,
          type: "error",
        });
      });
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none",
          },
        },
      },
    });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const query = event.target.value;
      if (query !== undefined && query !== "") {
        loadDrugs(searchQuery, drugsCurrentPage, showByStatus, showByDiscount);
      } else {
        loadDrugs(undefined, drugsCurrentPage, showByStatus, showByDiscount);
      }
    }
  };

  const onFileHandleChange = async (e) => {
    e.preventDefault();
    let files = e.target.files;

    if (files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const transformJson = json.map(
          ({ SaleType1, Price1, SaleType2, Price2, ...rest }) => {
            const drugSales = [];

            if (SaleType1 && Price1) {
              drugSales.push({
                title: SaleType1,
                price: Price1,
                discount: 0,
              });
            }

            if (SaleType2 && Price2) {
              drugSales.push({
                title: SaleType2,
                price: Price2,
                discount: 0,
              });
            }

            return {
              DrugSales: drugSales,
              ...rest,
            };
          }
        );

        try {
          setIsImporting(true);
          const response = await DrugService.createBulk(transformJson);
          setFileInputKey(undefined);
          setIsImporting(false);
          setAlert({
            show: true,
            message: response.message,
            type: "success",
          });
        } catch (error) {
          setAlert({
            show: true,
            message: error.message,
            type: "error",
          });
          setIsImporting(false);
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  return (
    <Fragment>
      <Card>
        <MAlert alert={alert} setAlert={setAlert} />
        <CardHeader
          title="Drugs"
          action={
            <React.Fragment>
              <a href={Urls.drugs.v3.drugSalesXlsxExport} target="_blank">
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Download List
                </Button>
              </a>
              &nbsp;&nbsp;&nbsp;
              <input
                accept=".xlsx"
                className={classes.input}
                id="contained-button-file"
                key={fileInputKey} // Use key to force re-rendering
                onChange={onFileHandleChange}
                type="file"
              />
              <br />
              <label htmlFor="contained-button-file">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUpload />}
                  disabled={isImporting}
                >
                  Upload New Medicine
                </Button>
              </label>
              &nbsp;&nbsp;&nbsp;
              <Button
                color="primary"
                size="small"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => history.push("/pharmacy/drugs/new")}
              >
                Add New
              </Button>
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent>
          <DataTable
            noHeader
            subHeader
            subHeaderAlign="left"
            subHeaderComponent={
              <Fragment>
                <TextField
                  name="search"
                  id="search"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  onKeyDown={handleKeyDown}
                  label="Search Drugs"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showByStatus}
                      onChange={(event) => setShowByStatus(!showByStatus)}
                      name="status"
                    />
                  }
                  label="Show By Status"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showByDiscount}
                      onChange={(event) => setShowByDiscount(!showByDiscount)}
                      name="discount"
                    />
                  }
                  label="Discount"
                />
              </Fragment>
            }
            persistTableHead={true}
            keyField="id"
            theme={theme.palette.type}
            title="Categories"
            columns={columns}
            data={items}
            progressPending={loading}
            progressComponent={<CircularProgress color="secondary" />}
            defaultSortField="title"
            sortIcon={<SortIcon />}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={total}
            paginationPerPage={50}
            paginationRowsPerPageOptions={[50]}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            paginationDefaultPage={drugsCurrentPage}
            onChangePage={(cpage) => {
              dispatch(Actions.changeDrugCurrentPageAction(cpage));
            }}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default DrugList;
