import React, { useEffect } from 'react';
import { UserService } from '../../services/UserService';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/Sort';
import { useTheme } from '@material-ui/core/styles';

const AddressList = ({ data }) => {
	const theme = useTheme();
	const [ user, setUser ] = React.useState(null);
	const [ message, setMessage ] = React.useState(null);
	useEffect(() => {
		if (user === null)
			UserService.profile(data.id).then((res) => {
				setUser(res.response);
			});
	});

	const columns = [
		{
			name: 'Address',
			selector: 'address',
			sortable: true
		},
		{
			name: 'Phone',
			selector: 'phone',
			sortable: true
		},
		{
			name: 'Name',
			selector: 'name',
			sortable: true
		},
		{
			name: 'Area',
			selector: 'area',
			sortable: true
		},
		{
			name: 'City',
			selector: 'city',
			sortable: true
		}
	];

	return (
		<div>
			{user && (
				<DataTable
					dense
					noHeader
					theme={theme.palette.type}
					title="Addresses"
					columns={columns}
					data={user.Addresses}
					defaultSortField="address"
					sortIcon={<SortIcon />}
				/>
			)}
		</div>
	);
};

export default AddressList;
