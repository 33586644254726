import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";
import CloseIcon from '@material-ui/icons/Close'
import {
    IconButton,
	Typography,
	Grid,
	CssBaseline,
	Paper,
	Avatar,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
    Collapse
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { LoginService } from '../../services/LoginService';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../reducers/actions';
import { Auth } from '../../auth/Auth';

const SignupSchema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required()
  });

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: 'url(../assets/img/bg.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

const SignIn = () => {
	const history = useHistory()
	const location = useLocation()
	const classes = useStyles();
	const socket= useSelector(state=> state.socket)
	const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false)
    const [error, setError] = React.useState("")
    const { register, handleSubmit, watch , errors } = useForm({
        resolver: yupResolver(SignupSchema)
    });

	const onSubmit = (data) => {
		LoginService.adminLogin(data).then(({success , accessToken, response , message ,...rest}) => {
            if(success){
				dispatch(Actions.isLogin(true))
				socket.emit("join:admin:room" , Auth.User())
				let state = location.state
				if(state!==undefined){
					history.push(state.from.path)
				}else
					history.push('/');
					
				window.location.reload();
            }else{
                setOpen(true)
                setError(message)
            }
        }).catch(error=> {
            alert(error)
        })
    };
    

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign In
					</Typography>
                    <Collapse in={open}>
                        <Alert
                        severity="error"
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            >
                            {error}
                            </Alert>
                        </Collapse>
					<form noValidate onSubmit={handleSubmit(onSubmit)} className={classes.form} >
						<TextField
							error={errors.username ? true : false}
							helperText={errors.username?.message}
							variant="outlined"
                            margin="normal"
                            inputRef={register}
							required
                            fullWidth
                            defaultValue=""
							id="username"
							label="Username"
							name="username"
                            autoComplete="username"
                            
							autoFocus
						/>
                        
						<TextField
							type="password"
                             error={errors.password ? true : false}
							variant="outlined"
                            margin="normal"
                            inputRef={register}
							required
                            fullWidth
                            defaultValue=""
							id="password"
							label="Password"
							name="password"
                            autoComplete="current-password"
                            helperText={errors.password?.message}
							
						/>
						<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Sign In
						</Button>
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

export default SignIn;
