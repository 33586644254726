import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const sendReportNotification = (notification) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${Urls.reportNotification.v4.reportNotifications}`, notification, {
        headers: {
          ...headers,
        },
        // responseType: "blob",
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const ReportsNotificationService = {
  sendReportNotification,
};
