import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import ProductItem from './ProductItem';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, TextField,CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { ProductService } from '../../services/ProductService'
const useStyles = makeStyles((theme) => ({
	card: {
		minHeight: 500,
		height: 500,
		overflow: 'auto'
	},
	textField: {
		width: 300
	}
}));

const ProductListItems = ({ onOrderItemAdd }) => {
	const classes = useStyles();
	const [loading , setLoading] = useState(false)
	const [products, setProducts] =useState([]) 
	const [ filterProduct, setFilterProduct ] = React.useState(null);

	const onChange = (event, value, reason) => {
		setFilterProduct(value);
	};



	const  onLoadProducts = (event) => {
		if(products.length==0){
			setLoading(true)
			ProductService.findAll().then((data) => {
				setLoading(false)
				setProducts(data.response)
			}).catch(error=> setLoading(false));
		}
	}

	return (
		<div id="non-printable">
			<Card className={classes.card}>
				<CardHeader
					title="Products"
					action={
						<Autocomplete
							loading={loading}
							onOpen={(event) =>onLoadProducts()}
							className={classes.textField}
							id="highlights-demo"
							style={{ width: 300 }}
							options={products}
							getOptionLabel={(option) => option.title + ' '+ option.qtyPerUnit+' ' + option.ProductCategory.title}
							onChange={onChange}
							renderInput={(params) => (
								<TextField {...params} 
								label="Search Products" 
								variant="outlined" 
								margin="normal" 
								InputProps={{
									...params.InputProps,
									endAdornment: (
									  <React.Fragment>
										{loading ? (
										  <CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									  </React.Fragment>
									)
								  }}/>
							)}
							renderOption={(option, { inputValue }) => {
								const matches = match(option.title + ' '+ option.qtyPerUnit+' ' + option.ProductCategory.title, inputValue);
								const parts = parse(option.title + ' '+ option.qtyPerUnit+' ' + option.ProductCategory.title, matches);
								

								return  (
									<div>
										{parts.map((part, index) => (
											<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
												{part.text}
											</span>
										))}
									</div>
								)
							}}
						/>
					}
				/>
				<CardContent>
					{filterProduct && <ProductItem product={filterProduct} onOrderItemAdd={onOrderItemAdd} />}
				</CardContent>
			</Card>
		</div>
	);
};

export default React.memo(ProductListItems);
