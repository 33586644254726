import { InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import moment from 'moment'

export const  months = [
    {
        name:"January",
        id:1
    },
    {
        name:"February",
        id:2
    },
    {
        name:"March",
        id:3
    },
    {
        name:"April",
        id:4
    },
    {
        name:"May",
        id:5
    },
    {
        name:"June",
        id:6
    },
    {
        name:"July",
        id:7
    },
    {
        name:"August",
        id:8
    },
    {
        name:"September",
        id:9
    },
    {
        name:"October",
        id:10
    },
    {
        name:"November",
        id:11
    },
    {
        name:"December",
        id:12
    },
]

const currentMonth = months.find( m => m.name===moment().format('MMMM'))

const MonthSelector = ({onChange  }) => {

    const [month , setMonth] = useState(currentMonth)
    

    return (
        <div style={{ display: 'flex', alignItems: 'center' ,flexDirection :"column"}}>
             <InputLabel id="demo-simple-select-label">Select Month</InputLabel>
                            <Select
                            fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={month?.id}
                                onChange={(e) =>{
                                    let selectedMonth = months.find(m=> m.id===e.target.value)
                                    setMonth(selectedMonth)
                                    onChange(selectedMonth)
                                }}
                                >
                                    {months.map(m => <MenuItem value={m.id}>{m.name}</MenuItem>)}
                            </Select>
        </div>
    )
}

export default MonthSelector
