

import React, { useEffect, useState } from 'react';
import { ListItem, ListItemAvatar, ListItemText, Typography, Avatar, ListItemSecondaryAction, Button, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { proxyPath } from '../../../services/ApiPath';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import placeHolderImage from './../../../nj_placeholder.png'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: '36ch',
		backgroundColor: theme.palette.background.paper
	},
	inline: {
		display: 'inline'
    },
    span : {
        marginLeft : theme.spacing(2),
        marginRight : theme.spacing(2)
    },
    button : {
        marginLeft : theme.spacing(2)
    },

    buttonToggle : {
        width :'100px'
    },

   

    price: {
        marginTop :'5px',
        background : theme.palette.secondary.main,
        borderRadius : 5,
        color: '#fff',
        padding : '4px 10px 4px 10px'
    }
}));

const DrugItem = ({ drug , onOrderItemAdd , ...rest} ) => {
    const classes = useStyles();
    const [quantity , setQuantity ] = useState(1)
    const [unit , setUnit] = useState("Strip")
    const [drugUnits , setDrugUnits] = useState([])
    const [selectedDrugUnit , setSelectedDrugUnit] = useState(null)
    const [price, setPrice ] = useState(0)
    const [totalPrice , setTotalPrice] = useState(0)
    const [totalQuantity , setTotalQuantity] = useState(0)
 
    useEffect(() => {
        // let units = []
        // if(drug.DrugUnits.length==2){
        //     if(drug.DrugUnits[0].title!=="Strip")
        //         units=drug.DrugUnits.reverse()
        //     else
        //     units = drug.DrugUnits
        // }else{
        //     units = drug.DrugUnits
        // }
         let selectedUnit = drug.DrugSales[0]
        setSelectedDrugUnit(selectedUnit)
        setUnit(selectedUnit.title)
        setPrice(selectedUnit.price)
        setDrugUnits(drug.DrugSales)

    }, [drug])

    useEffect(() => {
        //let p = 0.0
        let saleUnit = drug.DrugSales.find(s=> s.title===unit)
        if(saleUnit!==null && saleUnit!==undefined){
            setSelectedDrugUnit(saleUnit)
            setPrice(saleUnit.price)
        }
        // if(unit==="Pack"){
        //     p = drug.price * drug.stripSize * drug.packSize
        // }else{
        //     p = drug.price * drug.stripSize
        // }
       

    } , [unit,drug])

    useEffect(() => {
        if(selectedDrugUnit!==null)
            setTotalPrice(quantity * selectedDrugUnit.price)
    } , [quantity, selectedDrugUnit,unit  , drug])




    

    
    

    const handleUnit = (event, newUnit) => {
        setSelectedDrugUnit(drugUnits.find(u => u.title===newUnit))
        setUnit(newUnit)
      };

    

    const calcItemDiscountPrice = (item) => {
        return item.price * item.discount / 100;
    };

    const addOrderItem = () =>{
        onOrderItemAdd(quantity, selectedDrugUnit ,drug)
    }

    const roundTo= (price) => {
        return Math.round(price * 100) / 100
    }
    return (
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
                <Badge color="secondary" badgeContent={`${Math.round(drug.discount)}%`}  invisible={drug.discount > 0 ? false : true}anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                <img width="50" height="60" src={drug.images!==null && drug.images.length> 0  ? `${proxyPath}${drug.images[0]?.path}` : placeHolderImage  }/>
                </Badge>
			</ListItemAvatar>
			<ListItemText
				primary={`${drug.name} ${drug.measurement}`}
				secondary={
					<React.Fragment>
						<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
                         {drug.type}
						</Typography>
                        <br />
                        <span className={classes.price}>Rs {roundTo(price)}</span>
                        &nbsp;&nbsp;
                        {drug.discount> 0  ? <del> Rs {roundTo(drug.price)} </del>: ''}
                        
                        
						
					</React.Fragment>
				}
			/>
            <ListItemSecondaryAction>
                <div className={classes.buttonToggle}>
                <ToggleButtonGroup
                value={unit}
                exclusive
                onChange={handleUnit}
                aria-label="text alignment"
                >
                {
                    drugUnits.map(drugUnit=> <ToggleButton value={drugUnit.title} aria-label="left aligned">
                    <span>{drugUnit.title}</span>
                    </ToggleButton>)
                }
            
                
                </ToggleButtonGroup>
                </div>
                <Button color="primary" variant="outlined" size="small" disabled={quantity==1} onClick={() => setQuantity(quantity-1)}>-</Button>
                <Typography component="span" className={classes.span}>{quantity}</Typography>
                <Button color="primary" variant="outlined" size="small" onClick={() => setQuantity(quantity+1)}>+</Button>
                <Button color="primary" variant="contained" className={classes.button} onClick={() => addOrderItem()}>Add Item</Button>
               <br /><br />
            <span>Total Price : {totalPrice}</span>
            </ListItemSecondaryAction>
		</ListItem>
	);
}

export default DrugItem
