import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../reducers/actions';
import OrdersList from '../orders/OrdersList';
import { OrdersService } from '../../services/OrdersService';

const UserOrder = (props) => {
	const params = useParams();
	const location = useLocation();
	const [ userOrders, setUserOrders ] = React.useState([]);
	const [ user, setUser ] = React.useState(null);
	const [ isLoaded, setLoaded ] = React.useState(false);
	const [loading , setLoading] =useState(true)

	useEffect(
		() => {
			setUser(location?.state?.user)
			OrdersService.findOrderByUserIdAndAdminId(params.id).then(res => {
				console.log(res.response)
				setUserOrders(res.response)
				setLoading(false)
			}).catch(error =>setLoading(false))
		},
		[ location, params ]
	);

	return <OrdersList orders={userOrders} title={`${user?.name} Orders`} loading={loading} />
};

export default UserOrder;
