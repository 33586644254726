import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
	headers: {'x-access-token': Auth.token()}
  };
const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.categories.v3.findAll}`,{headers:headers})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const restoreItem = (item) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${Urls.categories.v3.restoreItem}`, item,{ headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

const findAllDeleted = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.categories.v3.allDeleted}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const generateFormData = (category , id , cb) => {
	const data = new FormData();
	id>-1 && 	data.append('id', id);
	data.append('status' , category.status)
	data.append('categoryImage', category.categoryImage);
	data.append('title', category.title);
	data.append('description', category.description);
	cb(data)
}

const changeStatus = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.put(Urls.categories.v3.changeStatus, category, { headers: headers })
			.then((response) => resolve(response.data))
			.catch((error) => {
				reject(error);
			});


	});

}

const save = (category , onUploadProgress) => {
	return new Promise((resolve, reject) => {
		generateFormData(category , -1 , (formData) => {
			axios
				.post(Urls.categories.v3.save, formData, { headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		})
	});
};



const edit = (category , id , onUploadProgress) => {
	return new Promise((resolve, reject) => {
		generateFormData(category, id, (formData) => {
			axios
				.put(Urls.categories.v3.edit, formData, {headers: headers , onUploadProgress})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	});
};

const deleteOne = (category) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${Urls.categories.v3.delete}/${category.id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const CategoryService = {
	findAll: findAll,
	save: save,
	deleteOne: deleteOne,
	edit: edit,
	changeStatus : changeStatus,
	findAllDeleted,
	restoreItem
};
