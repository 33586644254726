import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const edit = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.deliveryCharges.v4.edit, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findOne = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.deliveryCharges.v4.findOne}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DeliveryChargesService = {
  findOne,
  edit,
};
