import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardContent, Grid, TextField, Button, IconButton, Icon, useTheme, Checkbox, Typography, Stepper, Step, StepLabel, Paper, Avatar, CircularProgress } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { NotificationService } from '../../services/NotificationService'
import MAlert from '../elements/MAlert'
import DataTable from 'react-data-table-component'
import { proxyPath } from '../../services/ApiPath'
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import { OrdersService } from '../../services/OrdersService'
import { UserService } from '../../services/UserService'
import { makeStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative'
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2)
		// [theme.breakpoints.up(700 + theme.spacing(2) * 2)]: {
		// 	width: 700
		// }
	},
	paper: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2)
		// [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
		// 	marginTop: theme.spacing(6),
		// 	marginBottom: theme.spacing(6),
		// 	padding: theme.spacing(3)
		// }
	},
	stepper: {
		padding: theme.spacing(3, 0, 5)
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1)
	}
}));

const steps = [ 'Generate Notification', 'Select Users' ];
const GeneralNotification = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([])
    const [loading , setLoading] = useState(true)
    const theme= useTheme()
    const [data , setData] = useState(null)
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const [alertNotification , setAlertNotification] = useState({
        show: false,
        message: null,
        type: null
    })

    
    const NotificationSchema = yup.object().shape({
        title : yup.string().trim("The notification title cannot include leading and trailing spaces ").required(),
        description : yup.string().required(),
        image:yup
        .mixed()
        .required("Category image is required")
        
    })

    useEffect(() => {
        UserService.findAll().then(res=> {
            setLoading(false)
            setUsers(res.response.filter(u => u.deviceId!==null))
        }).catch(error => setLoading(false))
    } , [])

    const { register, handleSubmit, watch ,setValue , reset,formState , errors } = useForm({
        resolver: yupResolver(NotificationSchema),
        
      });

    const onSubmit = (data) => {
        setData({
            ...data,
            image : data.image[0]
        }) 
        handleNext()
        
    }

    // useEffect(() => {
    //     NotificationService.listGeneralNotification().then(res => {
    //         setNotifications(res.response)
    //     })
    // }, [])

   
   

    const userColumns = [
		{
            name: 'Image',
            selector: 'image.path',
            cell: ({picUrl}) => <Avatar src={`${proxyPath}${picUrl?.path}`} />
        },
        {
            name: "Name",
            selector: 'name',
            sortable: true
        },
        {
            name: "Phone",
            selector: 'phone',
            sortable: true
		},
    ]

    const resend = (notification) => {
        NotificationService.reSend(notification).then(res =>{
            setAlertNotification({
                show:true,
                message : res.message,
                type:'success'
            })
        }).catch(error => {
            setAlertNotification({
                show:true,
                message : "Error accourd while sending notification.",
                type:'error'
            })
        })
    }

    const handleChange = (state) => {

        setData({
            ...data,
            deviceIds : JSON.stringify(state.selectedRows.map(u => u.deviceId))
        })

       
    };
    const isIndeterminate = (indeterminate) => indeterminate;
    const selectableRowsComponentProps = { indeterminate: isIndeterminate };
    const [ activeStep, setActiveStep ] = useState(0);

    const getStepContent = (step) => {
		switch (step) {
			case 0:
				return <React.Fragment>
                        <TextField
                            error={errors.title ? true : false}
                            helperText={errors.title?.message}
                            inputRef={register}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Title"
                            name="title"
                            autoComplete="title"
                            autoFocus/>
                        <TextField
                            error={errors.description ? true : false}
                            helperText={errors.description?.message}
                            inputRef={register}
                            multiline
                            rows={4}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            autoComplete="description" />
                        <TextField
                            error={errors.image ? true : false}
                            helperText={errors.image?.message}
                            inputRef={register}
                            type="file"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="image"
                            name="image"
                            autoComplete="image"
                            
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <Button type="reset" fullWidth variant="contained" color="primary"  margin="normal">
                                    Clear
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <Button type="submit" fullWidth variant="contained" color="primary"  margin="normal">
                                    Send Notification
                                </Button>
                            </Grid> */}
                        </Grid>
   
             </React.Fragment>;
			case 1:
                return<DataTable
                noDataComponent={<span>No users found</span>}
                defaultSortAsc={false}
                defaultSortField="totalAmount"
                progressComponent={<CircularProgress color="secondary"/>}
                progressPending={loading}
            theme={theme.palette.type}
            noHeader
            keyField="id"
            columns={userColumns}
            data={users}
            pagination
            selectableRows
            Clicked
            onSelectedRowsChange={handleChange}
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={selectableRowsComponentProps}
            highlightOnHover
            
        />
        }
    }

    const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
    };
    
    const sendNotification = () => {
        console.log(data)
        NotificationService.generalNotification(data).then(res => {
            setAlert({
                show:true,
                message : res.message,
                type:'success'
            })
        }).catch(error => {
            setAlert({
                show:true,
                message : "Error accourd while sending notification.",
                type:'error'
            })
        })
    }

    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.paper} id="non-printable">
                <MAlert alert={alert} setAlert={setAlert}/>
			        <Typography component="h1" variant="h4" align="center">
							Send Notifications
			        </Typography>
			        <Stepper activeStep={activeStep} className={classes.stepper}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
                        <React.Fragment>
                        <form noValidate onSubmit={handleSubmit(onSubmit)} >
                            {getStepContent(activeStep)}
                            <div className={classes.buttons}>
										{activeStep !== 0 && (
											<Button onClick={handleBack} className={classes.button}>
												Back
											</Button>
										)} 
										{
                                            activeStep!==1 ? <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Next
                                        </Button> : <Button
                                             onClick={() => sendNotification()}
                                             disabled={data.hasOwnProperty("deviceIds") && data.deviceIds.length>0 ? false : true}
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Send
                                        </Button>
                                        }
										
									</div>
								
                        </form>
							
                        </React.Fragment>
            </Paper>
            </Grid>
           
           
        </Grid>
    )
}

export default GeneralNotification
