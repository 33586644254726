import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FeedbackService } from "../../services/feedback.service";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import SortIcon from "@material-ui/icons/Sort";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { EditFeedbackDialog } from "./dialogs/edit-feedback.dialog";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  new: {
    backgroundColor: theme.palette.success.main,
    padding: "5px",
    borderRadius: "5px",
  },
  viewed: {
    backgroundColor: theme.palette.warning.main,
    padding: "5px",
    borderRadius: "5px",
  },
  notContacted: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px",
    borderRadius: "5px",
  },

  resolved: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px",
    borderRadius: "5px",
  },
}));

export const FeedbackList = () => {
  const [exportLoading, setExportLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = useState();
  const [rating, setRating] = useState();
  const [status, setStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  //const { productCurrentPage } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [currentEditFeedback, setCurrentEditFeedback] = useState();

  const theme = useTheme();
  const classes = useStyles();

  const getStatusClass = (status) => {
    if (status === "New") {
      return classes.new;
    } else if (status === "Viewed") {
      return classes.viewed;
    } else if (status === "Not-Contacted") {
      return classes.notContacted;
    } else if (status === "Resolved") {
      return classes.resolved;
    }
  };

  const loadFeedbacks = async (page, status, rating, dateRange) => {
    try {
      setLoading(true);

      const res = await FeedbackService.findAllByPage(
        page,
        50,
        status,
        rating,
        dateRange
      );
      setLoading(false);
      setFeedbacks(res.response);
      setTotal(res.total);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const toggle = () => setOpen(!open);

  useEffect(() => {
    loadFeedbacks(currentPage, status, rating, dateRange);
  }, [currentPage, status, rating, dateRange]);

  const handleExport = async () => {
    try {
      setExportLoading(true);

      const response = await FeedbackService.exportExcel(
        1,
        50,
        status,
        rating,
        dateRange
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "feedback.xlsx"); // The file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log(url);
      setExportLoading(false);
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };
  const columns = [
    {
      name: "#ID",
      selector: "id",
    },

    {
      name: "Feedback",
      selector: "text",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Ratings",
      selector: "rating",
    },
    {
      name: "Status",
      selector: "status",
      cell: ({ status }) => (
        <Typography
          component="span"
          variant="span"
          className={getStatusClass(status)}
        >
          {status}
        </Typography>
      ),
    },
    {
      name: "Response",
      selector: "response",
    },
    {
      name: "Posted At",
      selector: "createdAt",
      cell: ({ createdAt }) => (
        <span>{moment(createdAt).format("MMMM Do, YYYY")}</span>
      ),
    },
    {
      name: "Action",
      selector: "Action",
      ignoreRowClick: true,
      cell: (feedback) => (
        <React.Fragment>
          <Link
            onClick={() => {
              setCurrentEditFeedback(feedback);
              setEditOpen(true);
            }}
          >
            <EditIcon />
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const onEditSaveHandle = useCallback(async (feedback, editValue) => {
    const newEditFeedback = {
      ...feedback,
      ...editValue,
    };

    try {
      setLoading(true);
      const res = await FeedbackService.edit(newEditFeedback);
      const updateFeedback = res.response;

      setFeedbacks((prev) =>
        prev.map((f) => (f.id === updateFeedback.id ? updateFeedback : f))
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={3}>
          <Card>
            <CardHeader title="Filter"></CardHeader>
            <CardContent>
              <DateRangePicker
                open={open}
                toggle={toggle}
                onChange={(range) => setDateRange(range)}
              />
              <div style={{ width: 20 }} />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <InputLabel id="status">Filter By Status</InputLabel>
                <Select
                  name="status"
                  value={status}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value === "All") {
                      setStatus(undefined);
                    } else {
                      setStatus(value);
                    }
                  }}
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="status"
                  label="status"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"New"}>New</MenuItem>
                  <MenuItem value={"Viewed"}>Viewed</MenuItem>
                  <MenuItem value={"Not-Contacted"}>Not Contacted</MenuItem>
                  <MenuItem value={"Resolved"}>Resolved</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: 20 }} />
              <FormControl
                fullWidth
                variant="outlined"
                style={{ marginTop: theme.spacing(2) }}
              >
                <InputLabel id="status">Filter By Ratings</InputLabel>
                <Select
                  name="ratings"
                  value={rating}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value === "All") {
                      setRating(undefined);
                    } else {
                      setRating(parseInt(value));
                    }
                  }}
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="ratings"
                  label="ratings"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="8">8</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                {dateRange ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ width: 150, height: 40, marginTop: 20 }}
                    onClick={() => setDateRange(undefined)}
                  >
                    Clear Filter Date
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ width: 150, height: 40, marginTop: 20 }}
                    onClick={toggle}
                  >
                    Filter by dates
                  </Button>
                )}
              </FormControl>

              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ width: 150, height: 40, marginTop: 20 }}
                  onClick={handleExport}
                >
                  Export To Excel
                </Button>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <Card>
            <CardHeader title="Feedbacks"></CardHeader>
            <CardContent>
              <DataTable
                subHeader
                subHeaderAlign="left"
                progressPending={loading}
                progressComponent={
                  <div>
                    <CircularProgress color="secondary" />
                  </div>
                }
                persistTableHead={true}
                keyField="id"
                noHeader
                title="Products"
                columns={columns}
                data={feedbacks}
                defaultSortField="rating"
                sortIcon={<SortIcon />}
                highlightOnHover
                pagination
                paginationDefaultPage={currentPage}
                paginationServer
                paginationTotalRows={total}
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50]}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <EditFeedbackDialog
        open={editOpen}
        setOpen={setEditOpen}
        feedback={currentEditFeedback}
        onSave={onEditSaveHandle}
      />
    </Fragment>
  );
};
