import React, { useEffect, useState } from 'react';
import {
	Card,
	CardHeader,
	Collapse,
	IconButton,
	CardContent,
	CardActionArea,
	CardMedia,
	Grid,
	Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { DrugBannerService } from '../../../services/DrugBannerService'
import { proxyPath } from '../../../services/ApiPath';
const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 345
	},
	media: {
		height: 140
	}
}));
const DrugBannersList = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const [smallBanners , setSmallBanners]= useState([])
	const [largeBanners , setLargeBanners] = useState([])

	const [ show, setShow ] = React.useState(false);
	const [ message, setMessage ] = React.useState(null);
	const [ alertType, setAlertType ] = React.useState(null);

	useEffect(()=> {
		DrugBannerService.findAll().then((data) => {
			setSmallBanners(data.response.filter(b => b.type==="Small"))
			setLargeBanners(data.response.filter(b => b.type==="Large"))
		})
	} , [])

	useEffect(() => {
		const {item , isNew} = props.itemObj
		if(item!==null){
			if(item.type==="Small")
				setSmallBanners([item , ...smallBanners])
			else
				setLargeBanners([item , ...largeBanners])
		}
	},[props.itemObj])

	const onDelete = (banner) => {
		DrugBannerService.deleteOne(banner)
			.then((res) => {
				if(banner.type==="Small")
					setSmallBanners(smallBanners.filter(b => b.id!==banner.id))
				else
					setLargeBanners(largeBanners.filter(b => b.id!==banner.id))
				setShow(res.success);
				setAlertType('success');
				setMessage(res.message);
			})
			.catch((error) => {
				setMessage("Can't delete banner image.");
				setShow(true);
				setAlertType('error');
			});
	};
	return (
		<Card>
			<Collapse in={show}>
				<Alert
					severity={`${alertType}`}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setShow(!show);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{message}
				</Alert>
			</Collapse>
			<CardHeader title="Banners" />
			<CardContent>
				<Grid container spacing={4}>
					{largeBanners.map((banner) => (
						<Grid item xs={12} sm={4}>
							<Card className={classes.root}>
								<CardHeader
                                    title={banner?.type}
									action={
										<IconButton aria-label="delete" onClick={() => onDelete(banner)}>
											<DeleteIcon />
										</IconButton>
									}
								/>
								<CardActionArea>
									<CardMedia
										className={classes.media}
										image={`${proxyPath}${banner?.image?.path}`}
										title="Banner Image"
									/>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>
				<Grid container spacing={4}>
					{smallBanners.map((banner) => (
						<Grid item xs={12} sm={4}>
							<Card className={classes.root}>
								<CardHeader
                                    title={banner?.type}
									action={
										<IconButton aria-label="delete" onClick={() => onDelete(banner)}>
											<DeleteIcon />
										</IconButton>
									}
								/>
								<CardActionArea>
									<CardMedia
										className={classes.media}
										image={`${proxyPath}${banner?.image?.path}`}
										title="Banner Image"
									/>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>
				
			</CardContent>
		</Card>
	);
};

export default DrugBannersList;
