import { Grid } from '@material-ui/core'
import React , {useState} from 'react'
import DrugCategoryList from './DrugCategoryList'
import NewDrugCategory from './NewDrugCategory'

const DrugCategory = () => {
    const [itemObj, setItemObj] = useState({item:null,isNew: false})
	const [editItemObj , setEditItemObj]  =useState({item: null, isEdit: false})
    return <Grid container spacing={4}>
    <Grid item xs={12} sm={12} md={4}>
        <NewDrugCategory setItemObj={setItemObj} editItemObj={editItemObj}/>
    </Grid>
    <Grid item xs={12} sm={12} md={8}>
        <DrugCategoryList itemObj={itemObj} setEditItemObj={setEditItemObj}/>
    </Grid>
</Grid>
}

export default DrugCategory
