import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FeatureDrugService } from '../../../services/FeatureDrugService'
import MAlert from '../../elements/MAlert'
import MDialog from '../../elements/MDialog'
import Drug from '../drugs/Drug'
import DrugItemSelector from '../drugs/DrugItemSelector'


const FeatureDrugDetail = () => {
    const params = useParams()
    const location= useLocation()
    const [items , setItems] = useState([])
    const [dialog , setDialog] = React.useState({open: false, payload: null})
    const [alert , setAlert] = useState({
        show: false,
        message: null,
        type: null
    })

    const onRemove =(drug) =>{
        setDialog({
            open:true,
            payload: drug
        })
    }

    const onClose= () =>{
        setDialog({
            open:false,
            payload: null
        })
    }

    const onConfirm = (payload) =>{
        
            FeatureDrugService.removeItem({DrugId : payload.id, FeatureDrugId: params.id}).then(res => {
            setItems(items.filter(item=> item.id!==payload.id))
            onClose()
            setAlert({
                show:true,
                message: res.message,
                type:'success'
            })
        }).catch(error => {
            setAlert({
                show:true,
                message: error.message ,
                type:'error'
            })
        })

    
    }


    const onAddItem = (drug) => {
        let hasDrug = items.find(d => d.id ===drug.id)
        if(hasDrug===null || hasDrug===undefined){
            FeatureDrugService.addItem({DrugId : drug.id , FeatureDrugId: params.id}).then(res =>{
                setItems([...items , drug])
                setAlert({
                    show:true,
                    message: res.message,
                    type:'success'
                })
            }).catch(error => {
                setAlert({
                    show:true,
                    message: error.message ,
                    type:'error'
                })
            })
        }else{
            setAlert({
                show:true,
                message: "This category has already "+ drug.name + " " + drug.DrugType.title+ " "+ drug.measurement,
                type:'warning'
            })
        }
    }

    useEffect(()=> {
        FeatureDrugService.findOneById(params.id).then(res=> {
            setItems(res.response.Drugs)
        })
    }, [params])
    return <>
        <DrugItemSelector onAddItem= {onAddItem}/>
        <br />
        <Card>
            <MAlert alert={alert} setAlert={setAlert}/>
            <CardHeader title={`${params.title} Drugs`}/>
            <CardContent>
                <Grid container spacing={4}>
                {items.map(item=> <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Drug drug={item} onRemove={onRemove}/>
                </Grid>)}
                </Grid>
               
            </CardContent>
        </Card>
        <MDialog open={dialog.open} payload={dialog.payload} onYes={onConfirm} onClose={onClose}/>
    </>
}

export default FeatureDrugDetail

