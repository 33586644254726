import React from 'react'

const Page404 = () => {
    return (
        <div>
            Page Not Found
        </div>
    )
}

export default Page404
