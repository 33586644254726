const listTodayOrders = (items) => {
  return {
    type: "LIST_TODAY_ORDERS",
    items: items,
  };
};

const insertTodayOrder = (item) => {
  return {
    type: "INSERT_TODAY_ORDER",
    item: item,
  };
};

const editTodayOrder = (item) => {
  return {
    type: "EDIT_TODAY_ORDER",
    item: item,
  };
};

const removeTodayOrder = (item) => {
  return {
    type: "REMOVE_TODAY_ORDER",
    item: item,
  };
};

const listNotifications = (items) => {
  return {
    type: "LIST_NOTIFICATIONS",
    items: items,
  };
};

const insertNotification = (item) => {
  return {
    type: "INSERT_NOTIFICATION",
    item: item,
  };
};

const removeNotificationByOrder = (item) => {
  return {
    type: "REMOVE_NOTIFICATION_BY_ORDER",
    item: item,
  };
};

const removeNotification = (item) => {
  return {
    type: "REMOVE_NOTIFICATION",
    item: item,
  };
};

const isLogin = (isLogin) => {
  return {
    type: "LOGIN",
    isLogin: isLogin,
  };
};

const socket = (socket) => {
  return {
    type: "SOCKET",
    socket: socket,
  };
};

const printOrderInvoice = (item) => {
  return {
    type: "PRINT_ORDER_INVOICE",
    item: item,
  };
};

const notifyState = (item) => {
  return {
    type: "NOTIFY_STATE",
    item: item,
  };
};

const listProducts = (items) => {
  return {
    type: "LIST_PRODUCTS",
    items: items,
  };
};

const listDrugTodayOrders = (items) => {
  return {
    type: "LIST_DRUG_TODAY_ORDERS",
    items: items,
  };
};

const insertDrugTodayOrder = (item) => {
  return {
    type: "INSERT_DRUG_TODAY_ORDER",
    item: item,
  };
};

const editDrugTodayOrder = (item) => {
  return {
    type: "EDIT_DRUG_TODAY_ORDER",
    item: item,
  };
};

const removeDrugTodayOrder = (item) => {
  return {
    type: "REMOVE_DRUG_TODAY_ORDER",
    item: item,
  };
};

const listTodayPrescriptions = (items) => {
  return {
    type: "LIST_TODAY_PRESCRIPTION",
    items: items,
  };
};

const insertTodayPrescriptions = (item) => {
  return {
    type: "INSERT_TODAY_PRESCRIPTION",
    item: item,
  };
};

const editTodayPrescriptions = (item) => {
  return {
    type: "EDIT_TODAY_PRESCRIPTION",
    item: item,
  };
};

const removeTodayPrescriptions = (item) => {
  return {
    type: "REMOVE_TODAY_PRESCRIPTION",
    item: item,
  };
};

const changeProductCurrentPageAction = (currentPage) => {
  return {
    type: "CHANGE_PRODUCT_CURRENT_PAGE",
    payload: currentPage,
  };
};

const changeDrugCurrentPageAction = (currentPage) => {
  return {
    type: "CHANGE_DRUG_CURRENT_PAGE",
    payload: currentPage,
  };
};

export const Actions = {
  listTodayOrders,
  editTodayOrder,
  insertTodayOrder,
  removeTodayOrder,
  listNotifications,
  insertNotification,
  removeNotification,
  removeNotificationByOrder,
  isLogin,
  socket,
  printOrderInvoice,
  notifyState,
  listProducts,
  listDrugTodayOrders,
  insertDrugTodayOrder,
  editDrugTodayOrder,
  removeDrugTodayOrder,
  listTodayPrescriptions,
  insertTodayPrescriptions,
  editTodayPrescriptions,
  removeTodayPrescriptions,
  changeProductCurrentPageAction,
  changeDrugCurrentPageAction,
};
