import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';

const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.prescriptions.v3.findAll}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const findByAdminToday = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.prescriptions.v3.findByAdminToday}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};


const findById = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.prescriptions.v3.findById}/${id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const edit = (data) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${Urls.prescriptions.v3.edit}`, data, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};


export const PrescriptionService = {
	edit,
	findAll,
	findByAdminToday,
	findById
    
}