import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AllReportsService } from "../../services/all-reports.service";
import MAlert from "../elements/MAlert";
import { proxyPath } from "../../services/ApiPath";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";
import DataTable from "react-data-table-component";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { CloudDownloadOutlined } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import "./pagination.css";
import ReportNotificationDialog from "./component/report-notification-dialog";

const useStyle = makeStyles((theme) => ({
  root: {
    width: 400,
    overflow: "auto",
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const formatDate = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : "";
};

const PharmacyUserOrdersByMonthsReports = () => {
  const theme = useTheme();
  const [filterText, setFilterText] = React.useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  // const startDate = "2023-04-01";
  // const endDate = "2023-10-31";
  const [selectedStartDate, handleStartDateChange] = useState(null);
  const [selectedEndDate, handleEndDateChange] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [reports, setReports] = useState([]);
  const [filterReports, setFilterReports] = useState([]);
  const [alert, setAlert] = React.useState({
    show: false,
    message: null,
    type: null,
  });

  // useEffect(() => {
  //   loadReports();
  // }, []);

  const getColumns = () => {
    if (reports.length > 0) {
      const keys = Object.keys(reports[0]);
      return keys.map((k) => ({ name: k, selector: k, width: "200px" }));
    }

    return [];
  };

  const reportColumn = useMemo(() => getColumns(), [reports]);

  const generateReports = async () => {
    try {
      const startDate = formatDate(selectedStartDate);
      const endDate = formatDate(selectedEndDate);
      setFileUrl(undefined);
      setLoading(true);

      const data = await AllReportsService.getPharmacyUserOrdersByMonthReports(
        startDate,
        endDate
      );
      if (data.response) {
        const filePath = proxyPath + "" + data.response.filePath;
        setReports(data.response.reports);
        setFileUrl(filePath);
      }
    } catch (err) {
      setAlert({
        show: true,
        message: "Can't genenrate pharmacy users orders reports",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    const filteredReports = reports.filter(
      (item) =>
        item["User Phone"].toLowerCase().includes(filterText.toLowerCase()) ||
        item["User Name"].toLowerCase().includes(filterText.toLowerCase())
    );

    setFilterReports(filteredReports);
  }, [filterText]);

  useEffect(() => {
    setFilterReports(reports);
  }, [reports]);

  const filterComponent = React.useMemo(() => {
    return (
      <TextField
        style={{}}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
        label="Search User"
        placeholder="Search by phone or user name"
        autoFocus
      />
    );
  }, [filterText]);

  return (
    <Card>
      <CardHeader
        title={
          <div>
            <h6>Pharmacy User Order Reports</h6>
          </div>
        }
        action={<Fragment></Fragment>}
      ></CardHeader>
      <CardContent>
        <DataTable
          subHeader
          subHeaderComponent={
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date"
                  label="End Date"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
              <Button
                disabled={loading}
                onClick={generateReports}
                style={{ marginLeft: 20, marginTop: 10 }}
                color="primary"
                size="small"
                variant="contained"
              >
                Generate Report
              </Button>
              <Button
                disabled={loading}
                onClick={() => setDialogOpen(true)}
                style={{ marginLeft: 20, marginTop: 10 }}
                color="primary"
                size="small"
                variant="contained"
              >
                {selectedUserIds.length > 0
                  ? "Send Notification To Selected Users"
                  : "Send Notification"}
              </Button>
              {fileUrl && (
                <a
                  style={{ marginLeft: 20, marginTop: 10 }}
                  href={fileUrl}
                  download="downloaded-file.xlsx"
                >
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    startIcon={<CloudDownloadOutlined />}
                  >
                    Download Reports
                  </Button>
                </a>
              )}
              {filterComponent}
            </MuiPickersUtilsProvider>
          }
          subHeaderAlign="left"
          progressPending={loading}
          theme={theme.palette.type}
          title=""
          noHeader
          columns={reportColumn}
          data={filterReports}
          pagination
          paginationPerPage={50}
          persistTableHead
          striped
          highlightOnHover
          responsive
          dense
          fixedHeader
          paginationRowsPerPageOptions={[
            50, 100, 150, 200, 250, 300, 400, 500, 1000,
          ]}
          selectableRows
          onSelectedRowsChange={(selectedRowState) => {
            setSelectedUserIds(
              selectedRowState.selectedRows.map((u) => u["User Id"])
            );
          }}
        />
        <ReportNotificationDialog
          isDialogOpen={isDialogOpen}
          onCloseDialog={onCloseDialog}
          userIds={selectedUserIds}
        />
      </CardContent>
    </Card>
  );
};

export default PharmacyUserOrdersByMonthsReports;
