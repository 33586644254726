const Versions = {
  v1: "api/v1.0/admin",
  v2: "api/v2.0/admin",
  v3: "api/v3.0/admin",
  v4: "api/v4.0/admin",
  v5: "api/v5.0/admin",
};

export const proxyPath = process.env.REACT_APP_API_URL; //'http://localhost:8080/';//
const currentVersion = `${proxyPath}${Versions.v5}`;
//export const proxyPath ="http://najeebmart.com:8080/"

export const Urls = {
  Auth: {
    v1: {
      login: `${proxyPath}${Versions.v1}/signin`,
      findById: `${proxyPath}${Versions.v1}/store/info`,
      findAll: `${proxyPath}${Versions.v1}/stores`,
      edit: `${proxyPath}${Versions.v1}/store/edit`,
      changePassword: `${proxyPath}${Versions.v1}/store/edit/password`,
    },
    v2: {
      login: `${proxyPath}${Versions.v2}/signin`,
      findById: `${proxyPath}${Versions.v2}/store/info`,
      findAll: `${proxyPath}${Versions.v2}/stores`,
      edit: `${proxyPath}${Versions.v2}/store/edit`,
      changePassword: `${proxyPath}${Versions.v2}/store/edit/password`,
    },
    v3: {
      login: `${currentVersion}/signin`,
      findById: `${currentVersion}/store/info`,
      findAll: `${currentVersion}/stores`,
      allStores: `${currentVersion}/stores/all`,
      edit: `${currentVersion}/store/edit`,
      changePassword: `${currentVersion}/store/edit/password`,
    },
  },
  stats: {
    v1: {
      stats: `${proxyPath}${Versions.v1}/stats`,
    },
    v2: {
      stats: `${proxyPath}${Versions.v2}/stats`,
    },
    v3: {
      stats: `${currentVersion}/stats`,
    },
  },
  notification: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/notifications`,
      findOne: `${proxyPath}${Versions.v1}/notifications`,
      edit: `${proxyPath}${Versions.v1}/notifications`,
      general: `${proxyPath}${Versions.v1}/notifications/general`,
      product: `${proxyPath}${Versions.v1}/notifications/product`,
      resend: `${proxyPath}${Versions.v1}/notifications/general/resend`,
      topCustomer: `${proxyPath}${Versions.v1}/notifications/top/customer`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/notifications`,
      findOne: `${proxyPath}${Versions.v2}/notifications`,
      edit: `${proxyPath}${Versions.v2}/notifications`,
      general: `${proxyPath}${Versions.v2}/notifications/general`,
      product: `${proxyPath}${Versions.v2}/notifications/product`,
      resend: `${proxyPath}${Versions.v2}/notifications/general/resend`,
      topCustomer: `${proxyPath}${Versions.v2}/notifications/top/customer`,
    },
    v3: {
      findAll: `${currentVersion}/notifications`,
      findOne: `${currentVersion}/notifications`,
      edit: `${currentVersion}/notifications`,
      general: `${currentVersion}/notifications/general`,
      product: `${currentVersion}/notifications/product`,
      update: `${currentVersion}/notifications/update`,
      resend: `${currentVersion}/notifications/general/resend`,
      topCustomer: `${currentVersion}/notifications/top/customer`,
    },
  },
  newNotification: {
    send: `${currentVersion}/new-notifications/send`,
  },
  users: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/users`,
      edit: `${proxyPath}${Versions.v1}/users`,
      profile: `${proxyPath}${Versions.v1}/users/profile`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/users`,
      edit: `${proxyPath}${Versions.v2}/users`,
      profile: `${proxyPath}${Versions.v2}/users/profile`,
    },
    v3: {
      findAll: `${currentVersion}/users`,
      edit: `${currentVersion}/users`,
      profile: `${currentVersion}/users/profile`,
    },
  },
  contacts: {
    v4: {
      findAll: `${currentVersion}/contacts`,
      edit: `${currentVersion}/contacts`,
      findOneById: `${currentVersion}/contacts/`,
      deleteOneById: `${currentVersion}/contacts/`,
      save: `${currentVersion}/contacts`,
    },
  },
  orders: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/orders`,
      edit: `${proxyPath}${Versions.v1}/orders`,
      removeItem: `${proxyPath}${Versions.v1}/orders/item/remove`,
      addItem: `${proxyPath}${Versions.v1}/orders/item/add`,
      findOrderByIdAndAdminId: `${proxyPath}${Versions.v1}/orders`,
      findOrderByUserIdAndAdminId: `${proxyPath}${Versions.v1}/orders/user`,
      today: `${proxyPath}${Versions.v1}/orders/daily/today`,
      filter: `${proxyPath}${Versions.v1}/orders/filter`,
      forward: `${proxyPath}${Versions.v1}/orders/forward`,
      topCustomers: `${proxyPath}${Versions.v1}/orders/top/customers`,
      orderReports: `${proxyPath}${Versions.v1}/orders/charts/reports`,
      dayWiseReports: `${proxyPath}${Versions.v1}/orders/monthly/reports`,
      filterTopCustomer: `${proxyPath}${Versions.v1}/orders/top/customers/filter`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/orders`,
      edit: `${proxyPath}${Versions.v2}/orders`,
      removeItem: `${proxyPath}${Versions.v2}/orders/item/remove`,
      addItem: `${proxyPath}${Versions.v2}/orders/item/add`,
      findOrderByIdAndAdminId: `${proxyPath}${Versions.v2}/orders`,
      findOrderByUserIdAndAdminId: `${proxyPath}${Versions.v2}/orders/user`,
      today: `${proxyPath}${Versions.v2}/orders/daily/today`,
      filter: `${proxyPath}${Versions.v2}/orders/filter`,
      forward: `${proxyPath}${Versions.v2}/orders/forward`,
      topCustomers: `${proxyPath}${Versions.v2}/orders/top/customers`,
      orderReports: `${proxyPath}${Versions.v2}/orders/charts/reports`,
      dayWiseReports: `${proxyPath}${Versions.v2}/orders/monthly/reports`,
      filterTopCustomer: `${proxyPath}${Versions.v2}/orders/top/customers/filter`,
    },
    v3: {
      findAll: `${currentVersion}/orders`,
      edit: `${currentVersion}/orders`,
      removeItem: `${currentVersion}/orders/item/remove`,
      addItem: `${currentVersion}/orders/item/add`,
      findOrderByIdAndAdminId: `${currentVersion}/orders`,
      findOrderByUserIdAndAdminId: `${currentVersion}/orders/user`,
      today: `${currentVersion}/orders/daily/today`,
      filter: `${currentVersion}/orders/filter`,
      forward: `${currentVersion}/orders/forward`,
      topCustomers: `${currentVersion}/orders/top/customers`,
      // orderReports : `${currentVersion}/orders/charts/reports`,
      // dayWiseReports : `${currentVersion}/orders/monthly/reports`,
      filterTopCustomer: `${currentVersion}/orders/top/customers/filter`,
      findAllByMonths: `${currentVersion}/orders`,
    },
  },
  martReports: {
    v3: {
      usersAndOrdersByYear: `${currentVersion}/reports/year/ordersusers`,
      monthlySalesInCategoryByYear: `${currentVersion}/reports/category`,
      monthProductSalesCountByYear: `${currentVersion}/reports/products`,
      allMonthTotalSalesByYear: `${currentVersion}/reports/year`,
      monthlyReportByYear: `${currentVersion}/reports/monthly`,
      monthylProductReportByYear: `${currentVersion}/reports/monthly/products`,

      martsUsersAndOrdersByYear: `${currentVersion}/reports/marts/year/ordersusers`,
      martsMonthlySalesInCategoryByYear: `${currentVersion}/reports/marts/category`,
      martsMonthProductSalesCountByYear: `${currentVersion}/reports/marts/products`,
      martsAllMonthTotalSalesByYear: `${currentVersion}/reports/marts/year`,
      martsMonthlyReportByYear: `${currentVersion}/reports/marts/monthly`,
      martsMonthylProductReportByYear: `${currentVersion}/reports/marts/monthly/products`,
    },
  },
  products: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/products`,
      delete: `${proxyPath}${Versions.v1}/products`,
      save: `${proxyPath}${Versions.v1}/products`,
      findById: `${proxyPath}${Versions.v1}/products`,
      edit: `${proxyPath}${Versions.v1}/products`,
      removeImageById: `${proxyPath}${Versions.v1}/products/images`,
      changeStatus: `${proxyPath}${Versions.v1}/products/status`,
      allDeleted: `${proxyPath}${Versions.v1}/products/deleted/items`,
      restoreItem: `${proxyPath}${Versions.v1}/products/deleted/items`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/products`,
      delete: `${proxyPath}${Versions.v2}/products`,
      save: `${proxyPath}${Versions.v2}/products`,
      findById: `${proxyPath}${Versions.v2}/products`,
      edit: `${proxyPath}${Versions.v2}/products`,
      removeImageById: `${proxyPath}${Versions.v2}/products/images`,
      changeStatus: `${proxyPath}${Versions.v2}/products/status`,
      allDeleted: `${proxyPath}${Versions.v2}/products/deleted/items`,
      restoreItem: `${proxyPath}${Versions.v2}/products/deleted/items`,
    },
    v3: {
      findAll: `${currentVersion}/products`,
      delete: `${currentVersion}/products`,
      save: `${currentVersion}/products`,
      xlsx: `${currentVersion}/products/xlsx`,
      xlsxExport: `${currentVersion}/products/xlsx/export`,
      findById: `${currentVersion}/products`,
      edit: `${currentVersion}/products`,
      removeImageById: `${currentVersion}/products/images`,
      changeStatus: `${currentVersion}/products/status`,
      allDeleted: `${currentVersion}/products/deleted/items`,
      restoreItem: `${currentVersion}/products/deleted/items`,
      //sales : `${currentVersion}/products/sales`,
    },
  },
  banners: {
    v1: {
      save: `${proxyPath}${Versions.v1}/banners`,
      delete: `${proxyPath}${Versions.v1}/banners`,
      findAll: `${proxyPath}${Versions.v1}/banners`,
    },
    v2: {
      save: `${proxyPath}${Versions.v2}/banners`,
      delete: `${proxyPath}${Versions.v2}/banners`,
      findAll: `${proxyPath}${Versions.v2}/banners`,
    },
    v3: {
      save: `${currentVersion}/banners`,
      delete: `${currentVersion}/banners`,
      findAll: `${currentVersion}/banners`,
    },
  },
  categories: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/category`,
      allDeleted: `${proxyPath}${Versions.v1}/category/deleted`,
      restoreItem: `${proxyPath}${Versions.v1}/category/deleted`,
      save: `${proxyPath}${Versions.v1}/category`,
      edit: `${proxyPath}${Versions.v1}/category`,
      delete: `${proxyPath}${Versions.v1}/category`,
      changeStatus: `${proxyPath}${Versions.v1}/category/status`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/category`,
      allDeleted: `${proxyPath}${Versions.v2}/category/deleted`,
      restoreItem: `${proxyPath}${Versions.v2}/category/deleted`,
      save: `${proxyPath}${Versions.v2}/category`,
      edit: `${proxyPath}${Versions.v2}/category`,
      delete: `${proxyPath}${Versions.v2}/category`,
      changeStatus: `${proxyPath}${Versions.v2}/category/status`,
    },
    v3: {
      findAll: `${currentVersion}/category`,
      allDeleted: `${currentVersion}/category/deleted`,
      restoreItem: `${currentVersion}/category/deleted`,
      save: `${currentVersion}/category`,
      edit: `${currentVersion}/category`,
      delete: `${currentVersion}/category`,
      changeStatus: `${currentVersion}/category/status`,
    },
  },
  productCategories: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/product/categories`,
      save: `${proxyPath}${Versions.v1}/product/categories`,
      edit: `${proxyPath}${Versions.v1}/product/categories`,
      delete: `${proxyPath}${Versions.v1}/product/categories`,
      changeStatus: `${proxyPath}${Versions.v1}/product/categories/status`,
      allDeleted: `${proxyPath}${Versions.v1}/product/categories/deleted`,
      restoreItem: `${proxyPath}${Versions.v1}/product/categories/deleted`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/product/categories`,
      save: `${proxyPath}${Versions.v2}/product/categories`,
      edit: `${proxyPath}${Versions.v2}/product/categories`,
      delete: `${proxyPath}${Versions.v2}/product/categories`,
      changeStatus: `${proxyPath}${Versions.v2}/product/categories/status`,
      allDeleted: `${proxyPath}${Versions.v2}/product/categories/deleted`,
      restoreItem: `${proxyPath}${Versions.v2}/product/categories/deleted`,
    },
    v3: {
      findBySubCategoryId: `${currentVersion}/product/categories`,
      findAll: `${currentVersion}/product/categories`,
      save: `${currentVersion}/product/categories`,
      edit: `${currentVersion}/product/categories`,
      delete: `${currentVersion}/product/categories`,
      changeStatus: `${currentVersion}/product/categories/status`,
      allDeleted: `${currentVersion}/product/categories/deleted`,
      restoreItem: `${currentVersion}/product/categories/deleted`,
    },
  },
  subCategories: {
    v1: {
      findAll: `${proxyPath}${Versions.v1}/sub/categories`,
      save: `${proxyPath}${Versions.v1}/sub/categories`,
      edit: `${proxyPath}${Versions.v1}/sub/categories`,
      delete: `${proxyPath}${Versions.v1}/sub/categories`,
      findById: `${proxyPath}${Versions.v1}/sub/categories`,
      changeStatus: `${proxyPath}${Versions.v1}/sub/categories/status`,
      allDeleted: `${proxyPath}${Versions.v1}/sub/categories/deleted/items`,
      restoreItem: `${proxyPath}${Versions.v1}/sub/categories/deleted/items`,
    },
    v2: {
      findAll: `${proxyPath}${Versions.v2}/sub/categories`,
      save: `${proxyPath}${Versions.v2}/sub/categories`,
      edit: `${proxyPath}${Versions.v2}/sub/categories`,
      delete: `${proxyPath}${Versions.v2}/sub/categories`,
      findById: `${proxyPath}${Versions.v2}/sub/categories`,
      changeStatus: `${proxyPath}${Versions.v2}/sub/categories/status`,
      allDeleted: `${proxyPath}${Versions.v2}/sub/categories/deleted/items`,
      restoreItem: `${proxyPath}${Versions.v2}/sub/categories/deleted/items`,
    },
    v3: {
      findAll: `${currentVersion}/sub/categories`,
      save: `${currentVersion}/sub/categories`,
      edit: `${currentVersion}/sub/categories`,
      delete: `${currentVersion}/sub/categories`,
      findById: `${currentVersion}/sub/categories`,
      changeStatus: `${currentVersion}/sub/categories/status`,
      allDeleted: `${currentVersion}/sub/categories/deleted/items`,
      restoreItem: `${currentVersion}/sub/categories/deleted/items`,
    },
  },
  drugCategories: {
    v3: {
      save: `${currentVersion}/pharmacy/category`,
      edit: `${currentVersion}/pharmacy/category`,
      findAll: `${currentVersion}/pharmacy/category`,
    },
  },
  drugSubCategories: {
    v3: {
      save: `${currentVersion}/pharmacy/subcategory`,
      edit: `${currentVersion}/pharmacy/subcategory`,
      findAll: `${currentVersion}/pharmacy/subcategory`,
      findOneById: `${currentVersion}/pharmacy/subcategory`,
      addItem: `${currentVersion}/pharmacy/subcategory/drug/add`,
      removeItem: `${currentVersion}/pharmacy/subcategory/drug/remove`,
    },
  },
  drugTypes: {
    v3: {
      save: `${currentVersion}/pharmacy/type`,
      edit: `${currentVersion}/pharmacy/type`,
      findAll: `${currentVersion}/pharmacy/type`,
    },
  },
  drugBrands: {
    v3: {
      save: `${currentVersion}/pharmacy/brand`,
      edit: `${currentVersion}/pharmacy/brand`,
      findAll: `${currentVersion}/pharmacy/brand`,
    },
  },
  drugBanner: {
    v3: {
      save: `${currentVersion}/pharmacy/banner`,
      edit: `${currentVersion}/pharmacy/banner`,
      findAll: `${currentVersion}/pharmacy/banner`,
      delete: `${currentVersion}/pharmacy/banner`,
    },
  },
  ageGroups: {
    v3: {
      save: `${currentVersion}/pharmacy/agegroup`,
      edit: `${currentVersion}/pharmacy/agegroup`,
      findAll: `${currentVersion}/pharmacy/agegroup`,
    },
  },
  drugs: {
    v3: {
      save: `${currentVersion}/pharmacy/drug`,
      edit: `${currentVersion}/pharmacy/drug`,
      drugSalesXlsx: `${currentVersion}/pharmacy/drug/xlsx/drugs-sales`,
      drugSalesXlsxExport: `${currentVersion}/pharmacy/drug/xlsx/export`,
      editStatus: `${currentVersion}/pharmacy/drug/status`,
      removeImage: `${currentVersion}/pharmacy/drug/remove/image`,
      removeBrand: `${currentVersion}/pharmacy/drug/remove/brand`,
      removeUnit: `${currentVersion}/pharmacy/drug/remove/unit`,
      removeAgeGroup: `${currentVersion}/pharmacy/drug/remove/agegroup`,
      removeCategory: `${currentVersion}/pharmacy/drug/remove/category`,
      removeSpecification: `${currentVersion}/pharmacy/drug/remove/specification`,
      removeDrugSale: `${currentVersion}/pharmacy/drug/remove/sale`,
      findAll: `${currentVersion}/pharmacy/drug`,
      findById: `${currentVersion}/pharmacy/drug`,
      createBulk: `${currentVersion}/pharmacy/drug/bulk`,
    },
  },
  drugUnits: {
    v3: {
      save: `${currentVersion}/pharmacy/unit`,
      edit: `${currentVersion}/pharmacy/unit`,
      findAll: `${currentVersion}/pharmacy/unit`,
    },
  },

  drugOrders: {
    v3: {
      all: `${currentVersion}/pharmacy/orders`,
      findByMonth: `${currentVersion}/pharmacy/orders`,
      forward: `${currentVersion}/pharmacy/orders/forward`,
      today: `${currentVersion}/pharmacy/orders/today`,
      findById: `${currentVersion}/pharmacy/orders/detail`,
      removeItem: `${currentVersion}/pharmacy/orders/item/remove`,
      addItem: `${currentVersion}/pharmacy/orders/item/add`,
      edit: `${currentVersion}/pharmacy/orders`,
      save: `${currentVersion}/pharmacy/orders`,
      findByPrescriptionId: `${currentVersion}/pharmacy/orders/prescription`,
    },
  },

  prescriptions: {
    v3: {
      edit: `${currentVersion}/pharmacy/prescriptions/status`,
      findById: `${currentVersion}/pharmacy/prescriptions`,
      findAll: `${currentVersion}/pharmacy/prescriptions`,
      findByAdminToday: `${currentVersion}/pharmacy/prescriptions/today`,
    },
  },
  employee: {
    v3: {
      deleteById: `${currentVersion}/pharmacy/employees`,
      save: `${currentVersion}/pharmacy/employees`,
      edit: `${currentVersion}/pharmacy/employees`,
      findAll: `${currentVersion}/pharmacy/employees`,
      findById: `${currentVersion}/pharmacy/employees`,
    },
  },
  featureDrugs: {
    v3: {
      save: `${currentVersion}/pharmacy/featuredrugs`,
      edit: `${currentVersion}/pharmacy/featuredrugs`,
      findAll: `${currentVersion}/pharmacy/featuredrugs`,
      findOneById: `${currentVersion}/pharmacy/featuredrugs`,
      addItem: `${currentVersion}/pharmacy/featuredrugs/drug/add`,
      removeItem: `${currentVersion}/pharmacy/featuredrugs/drug/remove`,
    },
  },
  pharmacyReports: {
    v3: {
      monthlySalesInCategoryByYear: `${currentVersion}/pharmacy/reports/category`,
      monthDrugSalesCountByYear: `${currentVersion}/pharmacy/reports/drugs`,
      allMonthTotalSalesByYear: `${currentVersion}/pharmacy/reports/year`,
      monthlyReportByYear: `${currentVersion}/pharmacy/reports/monthly`,
      monthylDrugReportByYear: `${currentVersion}/pharmacy/reports/monthly/drugs`,

      pharmaciesMonthlySalesInCategoryByYear: `${currentVersion}/pharmacy/reports/all/category`,
      pharmaciesMonthDrugSalesCountByYear: `${currentVersion}/pharmacy/reports/all/drugs`,
      pharmaciesAllMonthTotalSalesByYear: `${currentVersion}/pharmacy/reports/all/year`,
      pharmaciesMonthlyReportByYear: `${currentVersion}/pharmacy/reports/all/monthly`,
      pharmaciesMonthylDrugReportByYear: `${currentVersion}/pharmacy/reports/all/monthly/drugs`,
    },
  },
  qrDiscount: {
    v4: {
      edit: `${currentVersion}/qrdiscount`,
      findOne: `${currentVersion}/qrdiscount`,
    },
  },
  deliveryCharges: {
    v4: {
      edit: `${currentVersion}/delivery-charges`,
      findOne: `${currentVersion}/delivery-charges`,
    },
  },

  allReports: {
    v4: {
      martUserOrdersByMonthReports: `${currentVersion}/all/reports/mart-users-orders-by-months`,
      pharmacyUserOrdersByMonthReports: `${currentVersion}/all/reports/pharmacy-users-orders-by-months`,
    },
  },
  reportNotification: {
    v4: {
      reportNotifications: `${currentVersion}/reports/notifications`,
    },
  },
  feedbacks: {
    v4: {
      all: `${currentVersion}/feedbacks`,
      edit: `${currentVersion}/feedbacks`,
      export: `${currentVersion}/feedbacks/export`,
    },
  },
};
