import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	FormControlLabel,
	Radio,
	CircularProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import { OrdersService } from '../../services/OrdersService';
import { Actions } from '../../reducers/actions';
import { LoginService } from '../../services/LoginService';

const ForwardOrder = ({ order, open, setForwardOrderDialog, setAlert, ...rest }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const [stores, setStores] = useState([])
	const [loading ,setLoading] = useState(true)
	const [ selectedStore, setSelectedStore ] = React.useState(null);
	const storeColumns = [
		{
			name: 'Select',
			selector: 'id',
			cell: (store) => (
				<FormControlLabel
					value={store.id}
					name="store"
					control={<Radio onChange={(event) => setSelectedStore(store)} />}
				/>
			)
		},
		{
			grow: 5,
			fullWidth: true,
			name: 'Store Name',
			selector: 'storeName',
			sortable: true
		},
		{
			name: '',
			selector: '',
			sortable: true
		}
	];
	const onForwardOrder = () => {
		if (selectedStore === null) return;
		let forwardOrder = {
			order: order,
			store: selectedStore
		};

		setForwardOrderDialog({
			open: false,
			order: null
		});

		OrdersService.forwardOrder(forwardOrder)
			.then(({ success, response, message }) => {
				//dispatch(Actions.removeOrder(forwardOrder.order));
				dispatch(Actions.removeTodayOrder(forwardOrder.order));
				setAlert({
					show: true,
					message: message,
					type: 'success'
				});
			})
			.catch((error) => {
				setAlert({
					show: true,
					message: "Can't forward order to the store",
					type: 'error'
				});
			});
	};

	useEffect(() => {
		LoginService.findAll().then((data) => {
			setStores(data.response)
			setLoading(false)
		}).catch(error => setLoading(false));

	} , [])
	return (
		<Dialog
			open={open}
			onClose={() =>
				setForwardOrderDialog({
					open: false,
					order: null
				})}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{'Forward Order'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<DataTable
						progressPending={loading}
						progressComponent={<CircularProgress color="secondary"/>}
						theme={theme.palette.type}
						title="Stores"
						columns={storeColumns}
						data={stores}
						defaultSortField="storeName"
						sortIcon={<SortIcon />}
						highlightOnHover
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() =>
						setForwardOrderDialog({
							open: false,
							order: null
						})}
					color="secondary"
				>
					Not Sure
				</Button>
				<Button onClick={() => onForwardOrder()} color="secondary" autoFocus>
					Yes, Forward
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ForwardOrder;
