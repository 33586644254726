import React, { useEffect } from "react";
import clsx from "clsx";
import { Switch, withRouter, Route } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import SignIn from "./components/login/SignIn";
import Page404 from "./components/404/Page404";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import Category from "./components/category/Category";
import NewCategory from "./components/category/NewCategory";
import NewSubCategory from "./components/subcategory/NewSubCategory";
import SubCategory from "./components/subcategory/SubCategory";
import ProductCategory from "./components/productcategory/ProductCategory";
import Banners from "./components/banner/Banners";
import NewBanners from "./components/banner/NewBanners";
import { useDispatch } from "react-redux";
import { Actions } from "./reducers/actions";
import { OrdersService } from "./services/OrdersService";
import NewProductCategory from "./components/productcategory/NewProductCategory";
import NewProduct from "./components/products/NewProduct";
import EditProduct from "./components/products/EditProduct";
import UsersList from "./components/users/UsersList";
import Orders from "./components/orders/Orders";
import UserOrder from "./components/users/UserOrder";
import TopCustomer from "./components/reports/TopCustomer";
import Dashboard from "./components/dashboard/Dashboard";
import { Auth } from "./auth/Auth";
import NotificationOrder from "./components/orders/NotificationOrder";
import Profile from "./components/profile/Profile";
import OrderDetailStepper from "./components/orders/OrderDetailStepper";
import TestInvoice from "./components/orders/TestInvoice";
import SalesReports from "./components/reports/SalesReports";
import GeneralNotification from "./components/notifications/GeneralNotification";
import OpenRoutes from "./routes/OpenRoutes";
import ProductSales from "./components/reports/ProductSales";
import DrugCategory from "./components/pharmacy/drugcategory/DrugCategory";
import DrugBrands from "./components/pharmacy/drugbrands/DrugBrands";
import DrugSubCategory from "./components/pharmacy/drugsubcategory/DrugSubCategory";
import DrugTypes from "./components/pharmacy/drugtypes/DrugTypes";
import DrugBanners from "./components/pharmacy/drugbanner.js/DrugBanners";
import NewDrug from "./components/pharmacy/drugs/NewDrug";
import DrugList from "./components/pharmacy/drugs/DrugList";
import EditDrug from "./components/pharmacy/drugs/EditDrug";
import DrugOrderDetail from "./components/pharmacy/order/DrugOrderDetail";
import PrescriptionList from "./components/pharmacy/prescription/PrescriptionList";
import PrescriptionDetail from "./components/pharmacy/prescription/PrescriptionDetail";
import AllDrugOrders from "./components/pharmacy/order/AllDrugOrders";
import { DrugOrderService } from "./services/DrugOrderService";
import { PrescriptionService } from "./services/PrescriptionService";
import Employees from "./components/pharmacy/employees/Employees";
import FeatureDrugs from "./components/pharmacy/featuredrugs/FeatureDrugs";
import FeatureDrugDetail from "./components/pharmacy/featuredrugs/FeatureDrugDetail";
import DrugSubCategoryDetail from "./components/pharmacy/drugsubcategory/DrugSubCategoryDetail";
import PharmacyMonthlySaleReports from "./components/pharmacy/reports/PharmacyMonthlySaleReports";
import DrugMonthlySaleReports from "./components/pharmacy/reports/DrugMonthlySaleReports";
import PharmacyGraphsReports from "./components/pharmacy/reports/PharmacyGraphsReports";
import MartGraphs from "./components/reports/MartGraphs";
import Contacts from "./components/contacts/Contacts";
import NewContact from "./components/contacts/NewContact";
import EditContact from "./components/contacts/EditContact";
// import { io } from "socket.io-client";

// const END_POINT = process.env.REACT_APP_API_URL;
// let socket = io(END_POINT);

// io.connect({
// 	transports: [ 'websocket', 'polling' ]
//   })

import { io } from "socket.io-client";
import AppConfig from "./components/AppConfig/AppConfig";
import AllMartsGraphs from "./components/reports/AllMartsGraphs";
import AllMartsSalesReports from "./components/reports/AllMartsSalesReports";
import AllMartsProductSales from "./components/reports/AllMartsProductSales";
import AllPharmacyGraphsReports from "./components/pharmacy/reports/AllPharmacyGraphsReports";
import AllPharmacyMonthlySaleReports from "./components/pharmacy/reports/AllPharmacyMonthlySaleReports";
import AllPharmaciesDrugMonthlySaleReports from "./components/pharmacy/reports/AllPharmaciesDrugMonthlySaleReports";
import ProductPagingList from "./components/products/ProductPagingList";
import ProductUploads from "./components/products/product-uploads";
import DrugSalesUploads from "./components/pharmacy/drugs/drug-sales-upload";
import {
  PharmacyUserOrdersByMonthsReports,
  MartUserOrdersByMonthsReports,
} from "./components/all-reports";

import { FeedbackList } from "./components/feedbacks";
import NotificationPage from "./components/notifications/NotificationPage";

export const socket = io(process.env.REACT_APP_API_URL);
io.connect({
  transports: ["websocket", "polling"],
});

const areEqual = (prevProps, nextProps) => {
  return prevProps.myVar === nextProps.myvar;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
}));

const App = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  dispatch(Actions.socket(socket));

  // useEffect(() => {
  // 	console.log("Connecting to socket useEffect")
  // 	socket.connect();
  // 	socket.on('connect', () => {
  // 	  console.debug('SOCKET: connected to socket server');
  // 	});
  // 	socket.on('error', (err) => {
  // 	  console.debug('SOCKET: errors ', err);
  // 	});
  // 	socket.on('connect_error', (err) => {
  // 	  console.debug('SOCKET: connect_error ---> ', err);
  // 	});

  // 	socket.on("EVENT_PRODUCT" ,(data) => {
  // 	  console.log(data)
  // 	})
  //   }, [socket]);

  useEffect(() => {
    if (Auth.isLogin()) {
      DrugOrderService.findByAdminToday().then((data) => {
        dispatch(Actions.listDrugTodayOrders(data.response || []));
      });
    }
  }, [Auth.isLogin()]);

  useEffect(() => {
    if (Auth.isLogin()) {
      PrescriptionService.findByAdminToday().then((data) => {
        dispatch(Actions.listTodayPrescriptions(data.response || []));
      });
    }
  }, [Auth.isLogin()]);

  useEffect(() => {
    if (Auth.isLogin())
      OrdersService.findAllByToday().then((data) => {
        dispatch(Actions.listTodayOrders(data.response));
      });
  }, [Auth.isLogin()]);

  // useEffect(() => {
  //   if (Auth.isLogin())
  //     NotificationService.findAll().then((data) => {
  //       dispatch(Actions.listNotifications(data.response));
  //     });
  // }, [Auth.isLogin()]);

  useEffect(() => {
    if (Auth.isLogin()) socket.emit("join:admin:room", Auth.User());
  }, [Auth.isLogin()]);

  const proceedInvoice = () => {};

  return (
    <Paper elevation={0} square className={classes.root}>
      <Switch>
        <Route
          path="/notifications"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={NotificationPage} />
            </Switch>
          )}
        />
        <OpenRoutes
          restricted={true}
          component={GeneralNotification}
          path="/notification/general"
          exact
        />
        <Route path="/payment/receipt/:order" component={TestInvoice} />
        <PrivateRoutes exact path={"/profile"} component={Profile} />
        <Route
          path="/category"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={Category} />
              <PrivateRoutes path={`${path}/new`} component={NewCategory} />
            </Switch>
          )}
        />
        <Route
          path="/subcategory"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={SubCategory} />
              <PrivateRoutes path={`${path}/new`} component={NewSubCategory} />
            </Switch>
          )}
        />

        <Route
          path="/productcategory"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={ProductCategory} />
              <PrivateRoutes
                path={`${path}/new`}
                component={NewProductCategory}
              />
            </Switch>
          )}
        />

        <Route
          path="/banners"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={Banners} />
              <PrivateRoutes path={`${path}/new`} component={NewBanners} />
            </Switch>
          )}
        />

        <Route
          path="/products"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={ProductPagingList} />
              <PrivateRoutes path={`${path}/new`} component={NewProduct} />
              <PrivateRoutes
                path={`${path}/upload`}
                component={ProductUploads}
              />
              <PrivateRoutes
                path={`${path}/edit/:id`}
                component={EditProduct}
              />
            </Switch>
          )}
        />

        <Route
          path="/users"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={UsersList} />
              <PrivateRoutes
                exact
                path={`${path}/orders/:id`}
                component={UserOrder}
              />
            </Switch>
          )}
        />

        <Route
          path="/orders"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes exact path={path} component={Orders} />
              <PrivateRoutes
                path={`${path}/detail/:id`}
                component={OrderDetailStepper}
              />
              <PrivateRoutes
                path={`${path}/notification/detail/:id/:entityTypeId/:entityId`}
                component={NotificationOrder}
              />
            </Switch>
          )}
        />
        <Route
          path="/contacts"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes
                path={`${path}/edit/:id`}
                component={EditContact}
              />
              <PrivateRoutes path={`${path}/new`} component={NewContact} />
              <PrivateRoutes exact path={path} component={Contacts} />
            </Switch>
          )}
        />
        <Route
          path="/feedbacks"
          render={({ match: { path } }) => (
            <Switch>
              {/*} <PrivateRoutes
                path={`${path}/edit/:id`}
                component={EditContact}
              />
              <PrivateRoutes path={`${path}/new`} component={NewContact} /> */}
              <PrivateRoutes exact path={path} component={FeedbackList} />
            </Switch>
          )}
        />
        <Route
          path="/reports"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes
                exact
                path={`${path}/graphs`}
                component={MartGraphs}
              />
              <PrivateRoutes
                exact
                path={`${path}/sales`}
                component={SalesReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/customer/top`}
                component={TopCustomer}
              />
              <PrivateRoutes
                exact
                path={`${path}/products/sales`}
                component={ProductSales}
              />
              <PrivateRoutes
                exact
                path={`${path}/marts/graphs`}
                component={AllMartsGraphs}
              />
              <PrivateRoutes
                exact
                path={`${path}/marts/sales`}
                component={AllMartsSalesReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/marts/products/sales`}
                component={AllMartsProductSales}
              />
            </Switch>
          )}
        />

        <Route
          path="/pharmacy"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes
                exact
                path={`${path}/employees`}
                component={Employees}
              />
              <PrivateRoutes
                exact
                path={`${path}/orders`}
                component={AllDrugOrders}
              />
              <PrivateRoutes
                exact
                path={`${path}/prescriptions`}
                component={PrescriptionList}
              />
              <PrivateRoutes
                exact
                path={`${path}/uploads`}
                component={DrugSalesUploads}
              />
              <PrivateRoutes
                exact
                path={`${path}/banners`}
                component={DrugBanners}
              />
              <PrivateRoutes
                exact
                path={`${path}/category`}
                component={DrugCategory}
              />
              <PrivateRoutes
                exact
                path={`${path}/subcategory`}
                component={DrugSubCategory}
              />
              <PrivateRoutes
                exact
                path={`${path}/featuredrugs`}
                component={FeatureDrugs}
              />
              <PrivateRoutes
                exact
                path={`${path}/types`}
                component={DrugTypes}
              />
              <PrivateRoutes
                exact
                path={`${path}/brands`}
                component={DrugBrands}
              />
              <PrivateRoutes
                exact
                path={`${path}/drugs`}
                component={DrugList}
              />
              <PrivateRoutes
                exact
                path={`${path}/drugs/new`}
                component={NewDrug}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/graphs`}
                component={PharmacyGraphsReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/monthly`}
                component={PharmacyMonthlySaleReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/monthly/drugs`}
                component={DrugMonthlySaleReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/prescriptions/:id`}
                component={PrescriptionDetail}
              />
              <PrivateRoutes
                exact
                path={`${path}/subcategory/:title/:id`}
                component={DrugSubCategoryDetail}
              />
              <PrivateRoutes
                exact
                path={`${path}/featuredrugs/:title/:id`}
                component={FeatureDrugDetail}
              />
              <PrivateRoutes
                exact
                path={`${path}/drugs/edit/:id`}
                component={EditDrug}
              />
              <PrivateRoutes
                path={`${path}/orders/detail/:id`}
                component={DrugOrderDetail}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/all/graphs`}
                component={AllPharmacyGraphsReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/all/monthly`}
                component={AllPharmacyMonthlySaleReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/reports/all/monthly/drugs`}
                component={AllPharmaciesDrugMonthlySaleReports}
              />
            </Switch>
          )}
        />

        <Route
          path="/all/reports"
          render={({ match: { path } }) => (
            <Switch>
              <PrivateRoutes
                exact
                path={`${path}/mart-users-orders-by-months`}
                component={MartUserOrdersByMonthsReports}
              />
              <PrivateRoutes
                exact
                path={`${path}/pharmacy-users-orders-by-months`}
                component={PharmacyUserOrdersByMonthsReports}
              />
            </Switch>
          )}
        />

        <PrivateRoutes component={AppConfig} path="/app-config" exact />

        <PublicRoutes
          restricted={true}
          component={SignIn}
          path="/signin"
          exact
        />
        <PrivateRoutes component={Dashboard} path="/" exact />

        <PublicRoutes restricted={false} component={Page404} path="*" />
      </Switch>
    </Paper>
  );
};

export default React.memo(withRouter(App), areEqual);
