import axios from 'axios';
import { headers } from './TokenHeader';
import { Urls } from './ApiPath';

const findAll = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.users.v3.findAll}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const profile = (id) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${Urls.users.v3.profile}/${id}`, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const edit = (user) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${Urls.users.v3.edit}`, user, { headers: headers })
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const UserService = {
	findAll: findAll,
	edit: edit,
	profile
};
