import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@material-ui/core";
import { DeliveryChargesService } from "../../services/DeliveryChargesService";
import MAlert from "../elements/MAlert";
import * as Yup from "yup";
import { useFormik } from "formik";

const DeliveryCharges = () => {
  const initialValues = { id: 1, minAmount: 0, charges: 0 };
  const validationSchema = Yup.object().shape({
    minAmount: Yup.number()
      .min(0, "Minimum amount must be greater or equal to 0")
      .required("Minimum amount is required"),
    charges: Yup.number()
      .min(0, "Charge amount must be greater or equal to 0")
      .required("Charge amount is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      DeliveryChargesService.edit(values)
        .then((res) => {
          setAlert({
            show: true,
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          setAlert({
            show: true,
            message: "Can't saved delivery charges try again.",
            type: "error",
          });
        });
    },
  });

  const {
    values,
    getFieldProps,
    handleReset,
    handleSubmit,
    setValues,
    errors,
    touched,
  } = formik;

  const [alert, setAlert] = useState({
    show: false,
    message: null,
    type: null,
  });

  useEffect(() => {
    DeliveryChargesService.findOne()
      .then((res) => {
        setValues({
          ...res.response,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Card>
      <MAlert alert={alert} setAlert={setAlert} />
      <CardHeader title="Delivery Charges" />
      <CardContent>
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Minimum delivery amount"
            id="minAmount"
            name="minAmount"
            autoComplete="minAmount"
            {...getFieldProps("minAmount")}
            error={errors.minAmount && touched.minAmount ? true : false}
            helperText={errors.minAmount}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Charge amount"
            id="charges"
            name="charges"
            autoComplete="charges"
            {...getFieldProps("charges")}
            error={errors.charges && touched.charges ? true : false}
            helperText={errors.charges}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Save
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default DeliveryCharges;
