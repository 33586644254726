import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import YearSalesGraph from "./YearSalesGraph";
import DrugSalesGraph from "./DrugSalesGraph";
import DrugCategorySaleGraph from "./DrugCategorySaleGraph";
import AllPharmaciesYearSalesGraph from "./AllPharmaciesYearSalesGraph";
import AllPharmaciesDrugCategorySaleGraph from "./AllPharmaciesDrugCategorySaleGraph";
import AllPharmaciesDrugSalesGraph from "./AllPharmaciesDrugSalesGraph";

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: "flex",
    width: `100%`,
    justifyContent: "center",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  marginTop: {
    marginTop: "20px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));
const AllPharmacyGraphsReports = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllPharmaciesYearSalesGraph />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllPharmaciesDrugCategorySaleGraph />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllPharmaciesDrugSalesGraph />
      </Grid>
    </Grid>
  );
};

export default AllPharmacyGraphsReports;
