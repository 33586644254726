import React from 'react'
import { Grid, Card, CardHeader, CardActionArea, CardContent, CardActions, Box, Avatar, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    root : {
        backgroundColor : props=> props.color
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: "#fff",
    },
    white : {
        color: '#FFF'
    },
    title : {
        fontSize : '14px'
    }
  }));
const StateCard = ({count , title,content ,icon, ...rest}) => {
    const classes = useStyles(rest);
    return (
        <Card className={classes.root}>
        <CardHeader title={title} className={classes.white} avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {icon}
          </Avatar>
        } />
        <CardContent>
        <   Typography variant="h5" className={classes.white}>{count} {content}</Typography>
        </CardContent>
        <Divider/>
        <CardActions>
            <span className={classes.white}>{moment().format('DD MMMM YYYY')}</span>
        </CardActions>
    </Card>
    )
}

export default StateCard
