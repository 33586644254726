import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";
import moment from "moment";

const findAllByPage = (page, perPage, status, rating, dateRange) => {
  let url = `${Urls.feedbacks.v4.all}?page=${page}&per_page=${perPage}`;
  if (status) {
    url += `&status=${status}`;
  }
  if (rating) {
    url += `&rating=${rating}`;
  }

  if (dateRange) {
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    url += `&startdate=${startDate}&enddate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${Urls.feedbacks.v4.edit}/${data.id}`, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const exportExcel = (page, perPage, status, rating, dateRange) => {
  let url = `${Urls.feedbacks.v4.export}?page=${page}&per_page=${perPage}`;
  if (status) {
    url += `&status=${status}`;
  }
  if (rating) {
    url += `&rating=${rating}`;
  }

  if (dateRange) {
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    url += `&startdate=${startDate}&enddate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: headers, responseType: "blob" })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FeedbackService = {
  findAllByPage: findAllByPage,
  edit: edit,
  exportExcel: exportExcel,
};
