import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AllMartsYearSalesGraph from "./AllMartsYearSalesGraph";
import AllMartsSalesGraph from "./AllMartsSalesGraph";
import AllMartCategorySaleGraph from "./AllMartCategorySaleGraph";
import AllMartsUsersOrdersGraph from "./AllMartsUsersOrdersGraph";

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: "flex",
    width: `100%`,
    justifyContent: "center",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  marginTop: {
    marginTop: "20px",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));
const AllMartsGraphs = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllMartsYearSalesGraph />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllMartCategorySaleGraph />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllMartsSalesGraph />
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
        <AllMartsUsersOrdersGraph />
      </Grid>
    </Grid>
  );
};

export default AllMartsGraphs;
