import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import MartYearSalesGraph from './MartYearSalesGraph';
import MartSalesGraph from './MartSalesGraph';
import MartCategorySaleGraph from './MartCategorySaleGraph';
import UsersOrdersGraph from './UsersOrdersGraph';

const useStyles = makeStyles(theme => ({
    buttonDiv : {
      display : 'flex',
      width:`100%`,
      justifyContent: 'center'
    },
    centerDiv : {
      display : 'flex',
      justifyContent: 'center',
      alignItems:'center',
      flexDirection : 'column'
    },
    marginTop :{
      marginTop : '20px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    padding: {
      padding: theme.spacing(2)
    }
  }))
const MartGraphs = () => {
    const classes= useStyles()
    return <Grid container>
        <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
            <MartYearSalesGraph />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
            <MartCategorySaleGraph />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
           <MartSalesGraph />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.marginTop}>
           <UsersOrdersGraph />
        </Grid>

       
    </Grid>
}

export default MartGraphs
