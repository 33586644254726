import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugOrders.v3.findById}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeOrderItemById = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugOrders.v3.removeItem, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const addOrderItem = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugOrders.v3.addItem, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (order) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugOrders.v3.edit, order, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createOrder = (order) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Urls.drugOrders.v3.save, order, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findByPrescriptionId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugOrders.v3.findByPrescriptionId}/${id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findByAdmin = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugOrders.v3.all}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findByMonthAndAdminId = (monthId, year) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugOrders.v3.findByMonth}/${monthId}/${year}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findByAdminToday = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugOrders.v3.today}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const forwardOrder = (order) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugOrders.v3.forward, order, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DrugOrderService = {
  forwardOrder,
  findById,
  removeOrderItemById,
  addOrderItem,
  edit,
  createOrder,
  findByPrescriptionId,
  findByAdmin,
  findByAdminToday,
  findByMonthAndAdminId,
};
