import React, { useEffect, useCallback, useState } from "react";
import OrdersList from "./OrdersList";
import { OrdersService } from "../../services/OrdersService";
import moment from "moment";

const date = new Date();
const currentDate = {
  name: moment(date).format("MMMM"),
  id: date.getMonth() + 1,
  year: date.getFullYear(),
};
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState(date);
  const [month, setMonth] = useState(currentDate);

  useEffect(() => {
    setLoading(true);
    setMonth({
      name: moment(selectedDate).format("MMMM"),
      id: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
    });
  }, [selectedDate]);

  useEffect(() => {
    OrdersService.findAll(month.id, month.year)
      .then((data) => {
        setOrders(data.response);
        setLoading(false);
      })
      .catch((error) => setLoading(false));
  }, [month]);

  return (
    <React.Fragment>
      <OrdersList
        orders={orders}
        title={"Orders"}
        loading={loading}
        isShowMonthlyFilter={true}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
    </React.Fragment>
  );
};

export default React.memo(Orders);
