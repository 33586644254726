import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";
import { Auth } from "../auth/Auth";
const API_KEY = process.env.REACT_APP_API_KEY;
const options = {
  headers: { "x-access-token": Auth.token() },
};
const findAll = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugs.v3.findAll}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findAllByPage = (search, page, perPage, status, discount) => {
  let url =
    search && search !== ""
      ? `${Urls.drugs.v3.findAll}?search=${search}&page=${page}&per_page=${perPage}&status=${status}`
      : `${Urls.drugs.v3.findAll}?page=${page}&per_page=${perPage}&status=${status}`;
  if (discount) url += `&discount=${discount}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const findById = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.drugs.v3.findById}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const save = (data, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    generateFormData(data, false, -1, (formData) => {
      console.log(formData);
      axios
        .post(Urls.drugs.v3.save, formData, {
          headers: headers,
          onUploadProgress,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const updateDrugSalesXlsx = (files, onUploadProgress) => {
  let formData = new FormData();
  if (files.length > 0) {
    for (const key of Object.keys(files)) {
      formData.append("xlsx", files[key]);
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.drugSalesXlsx, formData, {
        headers: headers,
        onUploadProgress,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const edit = (data, id, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    generateFormData(data, false, id, (formData) => {
      axios
        .put(Urls.drugs.v3.edit, formData, {
          headers: headers,
          onUploadProgress,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const editStatus = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.editStatus, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeImage = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.removeImage, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeBrand = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.removeBrand, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeUnit = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.removeUnit, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeSpecification = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Urls.drugs.v3.removeSpecification}/${id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeDrugSale = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Urls.drugs.v3.removeDrugSale}/${id}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeAgeGroup = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.removeAgeGroup, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const removeCategory = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(Urls.drugs.v3.removeCategory, data, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createBulk = (data, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    axios
      .post(Urls.drugs.v3.createBulk, data, {
        headers: headers,
        onUploadProgress,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const generateFormData = (data, isStatus, id, cb) => {
  const formData = new FormData();
  id > -1 && formData.append("id", id);
  formData.append("status", data.status);
  formData.append("DrugTypeId", data.DrugTypeId);
  formData.append("manufactureDate", data.manufactureDate);
  formData.append("expiryDate", data.expiryDate);
  formData.append("brands", JSON.stringify(data.brands));
  formData.append("subCategories", JSON.stringify(data.subCategories));
  formData.append("ageGroups", JSON.stringify(data.ageGroups));
  formData.append("isPrescription", data.isPrescription);
  formData.append("measurement", data.measurement);
  formData.append("price", data.price);
  formData.append("discount", data.discount);
  formData.append("gender", data.gender);
  formData.append("name", data.name);
  formData.append("formula", data.formula);
  formData.append("DrugSales", JSON.stringify(data.DrugSales));
  formData.append(
    "DrugSpecifications",
    JSON.stringify(data.DrugSpecifications || [])
  );
  formData.append("description", data.description);
  formData.append("stripSize", data.stripSize);
  formData.append("packSize", data.packSize);
  formData.append("code", data.code);
  formData.append("qtyPerUnit", data.qtyPerUnit);
  if (!isStatus) {
    if ((data.images || []).length > 0) {
      for (const key of Object.keys(data.images)) {
        formData.append("images", data.images[key]);
      }
    }
  }
  cb(formData);
};
export const DrugService = {
  findAll: findAll,
  save: save,
  edit,
  findById,
  editStatus,
  removeImage,
  removeBrand,
  removeAgeGroup,
  removeCategory,
  removeUnit,
  removeSpecification,
  removeDrugSale,
  findAllByPage,
  updateDrugSalesXlsx,
  createBulk,
};
