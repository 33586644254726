import { Auth } from "../auth/Auth";
import { Actions } from "./actions";

const initialState = {
  todayDrugOrders: [],
  todayPrescriptions: [],
  todayOrders: [],
  notifications: [],
  products: [],
  socket: null,
  isLogin: Auth.isLogin(),
  order: null,
  notifyState: false,
  productCurrentPage: 1,
  drugsCurrentPage: 1,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_PRODUCT_CURRENT_PAGE":
      return {
        ...state,
        productCurrentPage: action.payload,
      };
    case "CHANGE_DRUG_CURRENT_PAGE":
      return {
        ...state,
        drugsCurrentPage: action.payload,
      };
    case "LIST_TODAY_ORDERS":
      return {
        ...state,
        todayOrders: action.items,
      };
    case "EDIT_TODAY_ORDER":
      return {
        ...state,
        todayOrders: state.todayOrders.map((item) =>
          item.id === action.item.id ? action.item : item
        ),
      };
    case "INSERT_TODAY_ORDER":
      let findOrder = state.todayOrders.find(
        (order) => order.id == action.item.id
      );
      if (findOrder === undefined)
        return {
          ...state,
          todayOrders: [action.item, ...state.todayOrders],
        };
      else return state;
    case "REMOVE_TODAY_ORDER":
      return {
        ...state,
        todayOrders: state.todayOrders.filter(
          (item) => item.id !== action.item.id
        ),
      };

    case "LIST_TODAY_PRESCRIPTION":
      return {
        ...state,
        todayPrescriptions: action.items,
      };
    case "EDIT_TODAY_PRESCRIPTION":
      return {
        ...state,
        todayPrescriptions: state.todayPrescriptions.map((item) =>
          item.id === action.item.id ? action.item : item
        ),
      };
    case "INSERT_TODAY_PRESCRIPTION":
      let pfindOrder = state.todayPrescriptions.find(
        (order) => order.id == action.item.id
      );
      if (pfindOrder === undefined)
        return {
          ...state,
          todayPrescriptions: [action.item, ...state.todayPrescriptions],
        };
      else return state;
    case "REMOVE_TODAY_PRESCRIPTION":
      return {
        ...state,
        todayPrescriptions: state.todayPrescriptions.filter(
          (item) => item.id !== action.item.id
        ),
      };

    case "LIST_DRUG_TODAY_ORDERS":
      return {
        ...state,
        todayDrugOrders: action.items,
      };
    case "EDIT_DRUG_TODAY_ORDER":
      return {
        ...state,
        todayDrugOrders: state.todayDrugOrders.map((item) =>
          item.id === action.item.id ? action.item : item
        ),
      };
    case "INSERT_DRUG_TODAY_ORDER":
      let findDrugOrder = state.todayDrugOrders.find(
        (order) => order.id == action.item.id
      );
      if (findDrugOrder === undefined)
        return {
          ...state,
          todayDrugOrders: [action.item, ...state.todayDrugOrders],
        };
      else return state;
    case "REMOVE_DRUG_TODAY_ORDER":
      return {
        ...state,
        todayDrugOrders: state.todayDrugOrders.filter(
          (item) => item.id !== action.item.id
        ),
      };

    case "LIST_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.items,
      };
    case "REMOVE_NOTIFICATION_BY_ORDER":
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => item.notificationObject.entityId !== action.item.id
        ),
      };
    case "REMOVE_NOTIFICATION":
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) =>
            item.notificationObject.id !== action.item.notificationObject.id
        ),
      };
    case "INSERT_NOTIFICATION":
      let find = state.notifications.find(
        (not) => not.notificationObject.id == action.item.notificationObject.id
      );
      if (find === undefined)
        return {
          ...state,
          notifications: [action.item, ...state.notifications],
        };
      else return state;
    case "LOGIN":
      return {
        ...state,
        isLogin: action.isLogin,
      };
    case "SOCKET":
      return {
        ...state,
        socket: action.socket,
      };
    case "PRINT_ORDER_INVOICE":
      return {
        ...state,
        order: action.item,
      };
    case "NOTIFY_STATE":
      return {
        ...state,
        notifyState: action.item,
      };

    case "LIST_PRODUCTS":
      return {
        ...state,
        products: action.items,
      };
    default:
      return state;
  }
};

export default rootReducer;
