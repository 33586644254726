import axios from "axios";
import { headers } from "./TokenHeader";
import { Urls } from "./ApiPath";

const findAll = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${Urls.banners.v3.findAll}`, { headers: headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const save = (bannerImage, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("bannerImage", bannerImage);
    axios
      .post(Urls.banners.v3.save, formData, {
        headers: headers,
        onUploadProgress,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteOne = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${Urls.banners.v3.delete}/${data.id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const BannerService = {
  findAll: findAll,
  save: save,
  deleteOne: deleteOne,
};
